// @flow

import axios from "../dependencies/_axios";

export function usersCheckEmailApi(email: string) {
	return axios({
		url: '/users/check-email',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			email
		}
	})
}

export function usersSaveDataApi(data: Object) {
	return axios({
		url: '/users',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data
	})
}

export function usersCheckEmailApiSupport(email: string) {
	return axios({
		url: '/support-dashboard/users/check-email',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			email
		}
	})
}

export function usersCheckGetEmailApiSupport(params) {
	return axios({
		url: '/support-dashboard/users/check-email',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: params
	})
}
