import { List, Map, fromJS } from 'immutable'

const initFilterData = fromJS({
	page: '',
	selected_ids: [],
	sort: '',
	name: '',
	per_page:10,
	status:'',
	search:'',
	custom_plan:true
})

export function customPlansHasErrored(state = false, action) {
	switch (action.type) {
		case 'CUSTOM_PLANS_HAS_ERRORED':
			return action.hasErrored
		default:
			return state
	}
}

export function customPlansIsLoading(state = false, action) {
	switch (action.type) {
		case 'CUSTOM_PLANS_IS_LOADING':
			return action.isLoading
		default:
			return state
	}
}

export function customPlans(state = List([]), action) {
	switch(action.type) {
		case 'CUSTOM_PLANS_FETCH_DATA_SUCCESS':
			return fromJS(action.companies)
		default:
			return state
	}
}

export function customPlansIds(state = List([]), action) {
  switch(action.type) {
    case 'CUSTOM_PLANS_FETCH_IDS_SUCCESS':
    	return List(action.ids)
    default:
    	return state
  }
}

export function customPlansPagination(state = Map({}), action) {
	switch(action.type) {
		case 'CUSTOM_PLANS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}


export function customPlansFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'CUSTOM_PLANS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'CUSTOM_PLANS_ADD_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('selected_ids', List(action.selected_ids))
		case 'CUSTOM_PLANS_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'CUSTOM_PLANS_ADD_FILTER_NAME_SUCCESS':
			return state.set('name', action.fullname)
		case 'CUSTOM_PLANS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'CUSTOM_PLANS_ADD_FILTER_STATUS_SUCCESS':
			return state.set('status',action.status)
		case 'CUSTOM_PLANS_ADD_FILTER_SEARCH_SUCCESS':
			return state.set('search', action.search)
		case 'CUSTOM_PLANS_ADD_FILTER_CUSTOM_PLAN_SUCCESS':
			return state.set('custom_plan', action.custom_plan)
		case 'CUSTOM_PLANS_ADD_FILTER_CHANNEL_SUCCESS':
			return state.set('channel',action.status)
		case 'CUSTOM_PLANS_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				selected_ids: [],
				sort: '',
				name: '',
				per_page:10,
				status:'',
				search:'',
				custom_plan:true,
				channel:'',
			})
		default:
			return state
	}
}

export function totalAccountRequiringAction(state = 0, action) {
	switch (action.type) {
		case 'TOTAL_ACCOUNT_REQUIRING_ACTION':
			return action.total
		case 'UPDATE_TOTAL_ACCOUNT_REQUIRING_ACTION':
			let currentValue = state
			let newValue = currentValue-action.total
			return newValue
		default:
			return state
	}
}