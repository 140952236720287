import React, { Component } from 'react'

class RenderInput  extends Component {
	render() {
		const { input, id, type, disabled, label, placeholder, desc, meta: { touched, error }, step, min} = this.props
		let numberStep=1
		if(step != undefined){
			numberStep = step
		}
		
		return(
				<div className={`input-container ${touched && error && 'has-error'}`}>
					<div><label>{label}</label></div>
					<div>
						<input type={type} {...input}
							placeholder={placeholder}
							id={id}
							disabled={disabled}
							pattern="[0-9]*"
							step={numberStep}
							min={min}
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
									event.preventDefault();
								}
							}}></input>
						{touched && error && <div><span className="error">{error}</span></div>}
					</div>
				</div>
		)
	}
}


export default RenderInput
