// import axios from 'axios'
import axios from "../dependencies/_axios";
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import { Link } from  'react-router-dom'

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function userGetAuth() {
	return (dispatch) => {
		return axios({
			url: `${initializers.api_host}/support-dashboard/initial-data`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest','Authorization': `Bearer ${localStorage.getItem('token')}`}
		})
		.then((response) => {
			let user = response.data.user

			dispatch(userGetAuthSuccess(user))
			dispatch(setIsAuthorize(true))
			dispatch(setSessionsToken(response.data.token))
			// dispatch(saveUserPrivilege(user.role.privilege))
			dispatch(setUserPrivilege(user.role.privilege))
			dispatch(informationFetchDataSuccess(response.data.information))
			dispatch(timezonesFetchDataSuccess(response.data.timezones))
			dispatch(accountManagerFetchDataSuccess(response.data.account_manager))
			dispatch(setTotalAccountRequiringAction(response.data.information.plan_request))
			
			// dispatch(Notifications.error({
			// 	title: 'Success',
			// 	message: <span>You have <b>{response.data.information.plan_request} new request</b> <Link to={`/support/custom-plan`} className="btn-notif">View Accounts Requiring Actions</Link></span>,
			// 	position: 'bl'
			// }))
		})
		.catch((error) => {
			dispatch(userGetAuthSuccess({}))
			
			dispatch(setIsAuthorize(false))
			
			dispatch(setSessionsToken(null))
			// dispatch(saveUserPrivilege([]))
			dispatch(setUserPrivilege([]))
		})
	}
}

export function userGetAuthSuccess(user) {
	return {
		type: 'USERS_GET_AUTH_SUCCESS',
		user
	}
}

export function setSessionsToken(token) {
	return {
		type: 'SESSIONS_ADD_TOKEN_SUCCESS',
		token
	}
}

export function setIsAuthorize(isAuthorize) {
	return {
		type: 'IS_LOGIN_SUCCESS',
		isAuthorize
	}
}


export function setToken(token) {
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(saveToken(token))
		})
	}
}


export function saveToken(token) {
	return Promise.resolve().then(function () {
		localStorage.setItem('token', token)
	});
}

export function saveUserPrivilege(privilege) {
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(saveUserPrivilegeSuccess(privilege))
		})
	}
}

export function saveUserPrivilegeSuccess(privilege) {
	return Promise.resolve().then(function () {
		localStorage.setItem('userPrivilege', privilege)
	});
}

export function setUserPrivilege(privilege) {
	return {
		type: 'SESSIONS_ADD_PRIVILEGE_SUCCESS',
		privilege
	}
}

export function informationFetchDataSuccess(information) {
	return {
		type: 'INFORMATION_FETCH_DATA_SUCCESS',
		information
	}
}

export function timezonesFetchDataSuccess(timezones) {
	return {
		type: 'TIMEZONES_FETCH_DATA_SUCCESS',
		timezones
	}
}

export function accountManagerFetchDataSuccess(account_manager) {
	return {
		type: 'ACCOUNT_MANAGERS_FETCH_DATA_SUCCESS',
		account_manager
	}
}

export function setTotalAccountRequiringAction(total) {
	return {
		type: 'TOTAL_ACCOUNT_REQUIRING_ACTION',
		total
	}
}