// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map } from 'immutable'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import io from 'socket.io-client'
import moment from 'moment'

import {
	usersAddFilterIsActive,
	usersAddFilterCompanyId,
	usersAddFilterUserLevel,
	usersAddFilterLoginFrom,
	usersAddFilterLoginTo,
	getDropdownUserLevel,
	usersFetchData,
	usersAddFilterPageNumber
} from '../../actions/users'

import {
	globalAddSweetAlertData,
} from '../../actions/global'

import {
	getDropdownCompanies,
	companiesGetDetail,
	downloadReportUsages
} from '../../actions/companies'

type Props = {
	usersAddFilterIsActive: Function,
	usersAddFilterCompanyId: Function,
	usersAddFilterUserLevel: Function,
	usersAddFilterLoginFrom: Function,
	usersAddFilterLoginTo: Function,
	getDropdownUserLevel: Function,
	usersFetchData: Function,
	getDropdownCompanies: Function,
	companiesGetDetail: Function,
	usersFilterData: Map<string, any>,
	usersAddFilterPageNumber: Function,
}

class ModalDownloadAllAccounts extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			value: [],
			valueCompanies: [],
			fromDate: '',
			toDate: '',
			isActive:1,
			selectedCompany:'',
			maxEndDate: moment(),
			buttonDownload: "Download"
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleSelectChange',
			'handleSelectCompany',
			'handleSelectUserLevel',
			'handleSelectStatus',
			'handleFromDateChange',
			'handleToDateChange',
			'handleSubmitFilter',
			'connectSocket',
			'disconnectSocket',
			'handleSocket',
		])
	}

	componentDidMount() {
		this.props.getDropdownUserLevel()
		this.props.getDropdownCompanies({label_id: true})
	}

	handleCloseModalClick() {
		this.props.usersAddFilterLoginFrom('')
		this.props.usersAddFilterLoginTo('')
		this.setState({buttonDownload: "Download"})
		this.disconnectSocket()
		$('#modalDownloadAllAccounts').modal('hide')
	}

	handleSelectChange (value) {
		this.setState({ value });
	}

	handleFromDateChange(value) {
		let date = value.format('YYYY-MM-DD')
		this.setState({
			fromDate: value,
			toDate: ''
		});
		this.props.usersAddFilterLoginFrom(date)
		let max = moment(value).add(11,'month')
		if(max > moment()) {
			max = moment()
		}
		this.setState({maxEndDate: max})
		this.props.usersAddFilterLoginTo('')
  }

  handleToDateChange(value) {
		let date = value.format('YYYY-MM-DD')
		this.setState({
			toDate: value
		});
		this.props.usersAddFilterLoginTo(date)
  }


	handleSelectCompany(e) {
		const {
			usersAddFilterCompanyId
		} = this.props
		let companyId=e
		this.setState({valueCompnay:companyId, selectedCompany: companyId})
		usersAddFilterCompanyId(companyId)
	}

	handleSelectUserLevel(value) {
		this.setState({ value });
		this.props.usersAddFilterUserLevel(value)
	}

	handleSelectStatus(e, value) {
		const {
			usersAddFilterIsActive
		} = this.props
		this.setState({ isActive: value });
		usersAddFilterIsActive(value)
	}

	handleSubmitFilter(e){
		e.preventDefault()
		const {
			company,
			downloadReportUsages
		} = this.props
		const { fromDate, toDate } = this.state
		if(fromDate && toDate && this.state.buttonDownload == "Download")
		{
			this.setState({buttonDownload: (<i className="fa fa-circle-o-notch fa-spin "></i>)})
			let channel = this.props.companiesFilterData.get('channel')
			downloadReportUsages(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'), moment().format("YYYYMMDDHHIISS"), channel)
			.then(res => {
				if(res) this.connectSocket(res)
				else this.setState({buttonDownload: "Download"})
			})
		}		
	}

	handleSocket(message, id) {
		const { fromDate, toDate } = this.state
		console.log(message)
		if(message.report_id == id && message.url_file)
		{
			const link = document.createElement('a')
			link.href  = message.url_file
			link.setAttribute("download", `All Accounts Overview_${fromDate.format('DDMMYYYY')}_${toDate.format('DDMMYYYY')}_${moment().format('DDMMYYYY')}.xlsx`)
			document.body.appendChild(link)
			link.click()
			link.remove()

			this.disconnectSocket()
			this.handleCloseModalClick()
		}
	}

	connectSocket(id) {
		if (process.env.NODE_ENV === 'development') {
			io('https://node-js-az.astrnt.co').on(`channel:report-account-overview-beta`, (message) => {
				this.handleSocket(message,id)
			})
		} else if (process.env.NODE_ENV === 'beta') {
			io('https://node-js-beta.astrnt.co').on(`channel:report-account-overview-beta`, (message) => {
				this.handleSocket(message,id)
			})
		} else if (process.env.NODE_ENV === 'dev-beta') {
			io('https://nodejs-dev-beta.astrnt.co').on(`channel:report-account-overview-beta`, (message) => {
				this.handleSocket(message,id)
			})
		} else {
			io('https://node-js-az.astrnt.co').on(`channel:report-account-overview-live`, (message) => {
				this.handleSocket(message,id)
			})
		}
	}

	disconnectSocket() {
		if (process.env.NODE_ENV === 'development') {
			io('http://localhost:3001').disconnect()
		} else if (process.env.NODE_ENV === 'beta') {
			io('https://node-js-beta.astrnt.co').disconnect()
		} else if (process.env.NODE_ENV === 'dev-beta') {
			io('https://nodejs-dev-beta.astrnt.co').disconnect()
		} else {
			io('https://node-js-az.astrnt.co').disconnect()
		}
	}

  	render() {
		const {
			dropdownUserLevels,
			companiesDropdown,
			usersFilterData
		} = this.props

  		const { value, valueCompanies, selectedCompany } = this.state;
  		const options = dropdownUserLevels.toJS()
		  
    	return (
      		<div className="modal custom-modal" id="modalDownloadAllAccounts" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        		<div className="modal-dialog wrapper-modal-download-all">
          			<div className="modal-content animated bounceInRight">
            			<div className="modal-header">
            				<label>Download All Accounts Overview</label>
            			</div>
						<div className="modal-body">
							<div className="input-container">
								<label>Start Date</label>
								<div className="datepick-container-new horizontal-item--between">
									<DatePicker
										dateFormat="DD/MM/YYYY"
										selected={usersFilterData.get('from') == '' ? null : moment(usersFilterData.get('from'))}
										onChange={this.handleFromDateChange}
										placeholderText="DD/MM/YYYY"
										maxDate={usersFilterData.get('to') ==''? moment() : moment(usersFilterData.get('to'))}
										showDisabledMonthNavigation/>
									<i className="icon-i-calendar" />
								</div>
							</div>
							<div className="input-container">
								<label>End Date</label>
								<div className="datepick-container-new horizontal-item--between">
									<DatePicker
										dateFormat="DD/MM/YYYY"
										selected={usersFilterData.get('to') == '' ? null : moment(usersFilterData.get('to'))}
										onChange={this.handleToDateChange}
										placeholderText="DD/MM/YYYY" 
										minDate={moment(usersFilterData.get('from'))}
										maxDate={this.state.maxEndDate}
										showDisabledMonthNavigation/>
									<i className="icon-i-calendar" />
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button className="button button-cancel m-r-16" onClick={this.handleCloseModalClick}>Cancel</button>
							<button className="button button-download" onClick={(e)=>this.handleSubmitFilter(e)}>
								{ this.state.buttonDownload }
							</button>
						</div>
          			</div>
        		</div>
      		</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		dropdownUserLevels: state.dropdownUserLevels,
		usersFilterData: state.usersFilterData,
		companiesDropdown: state.companiesDropdown,
		company: state.company,
		companiesFilterData: state.companiesFilterData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		usersFetchData: (user_filter) => dispatch(usersFetchData(user_filter)),
		usersAddFilterIsActive: (is_active) => dispatch(usersAddFilterIsActive(is_active)),
		usersAddFilterCompanyId: (company_id) => dispatch(usersAddFilterCompanyId(company_id)),
		usersAddFilterUserLevel: (user_level) => dispatch(usersAddFilterUserLevel(user_level)),
		usersAddFilterLoginFrom: (from) => dispatch(usersAddFilterLoginFrom(from)),
		usersAddFilterLoginTo: (to) => dispatch(usersAddFilterLoginTo(to)),
		getDropdownUserLevel: () => dispatch(getDropdownUserLevel()),
		getDropdownCompanies: (params) => dispatch(getDropdownCompanies(params)),
		companiesGetDetail: (companyId) => dispatch(companiesGetDetail(companyId)),
		usersAddFilterPageNumber: (page) => dispatch(usersAddFilterPageNumber(page)),
		downloadReportUsages: (fromDate, toDate, identifier, channel) => dispatch(downloadReportUsages(fromDate, toDate, identifier, channel))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDownloadAllAccounts)
