import { Map, List, fromJS } from 'immutable'

const initFilterData = fromJS({
	page: '',
	sort: '',
	name: '',
	per_page:10
})

export function featureListCompany(state = Map({}), action) {
	switch(action.type) {
		case 'FEATURE_LIST_COMPANY_FETCH_DATA_SUCCESS':
			return Map(action.featureListCompany)
		default:
			return state
	}
}

export function featureListJob(state = Map({}), action) {
	switch(action.type) {
		case 'FEATURE_LIST_JOB_FETCH_DATA_SUCCESS':
			return Map(action.featureListJob)
		default:
			return state
	}
}


export function dropdownFeatures(state = List([]), action) {
	switch(action.type) {
		case 'DROPDOWN_FEATURES_FETCH_DATA_SUCCESS':
			return fromJS(action.features)
		default:
			return state
	}
}


export function featuresFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'FEATURES_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'FEATURES_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'FEATURES_ADD_FILTER_NAME_SUCCESS':
			return state.set('name', action.name)
		case 'FEATURES_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'FEATURES_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				sort: '',
				name: '',
				per_page:10
			})
		default:
			return state
	}
}

export function featureLists(state = List([]), action) {
	switch(action.type) {
		case 'FEATURES_FETCH_DATA_SUCCESS':
			return fromJS(action.features)
		default:
			return state
	}
}

export function featuresPagination(state = Map({}), action) {
	switch(action.type) {
		case 'FEATURES_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}