import { List, Map, fromJS } from 'immutable'

const initAnalyticsFilterData = fromJS({
	job_id: 0,
	page: '',
	invite_id: '',
	invite_ids: []
})

export function analyticsHasErrored(state = false, action) {
	switch (action.type) {
		case 'ANALYTICS_HAS_ERRORED':
			return action.hasErrored
		default:
			return state
	}
}

export function analyticsIsLoading(state = false, action) {
	switch (action.type) {
		case 'ANALYTICS_IS_LOADING':
			return action.isLoading
		default:
			return state
	}
}

export function analyticsJobs(state = List([]), action) {
	switch(action.type) {
		case 'ANALYTICS_JOBS_FETCH_DATA_SUCCESS':
			return fromJS(action.analyticsJobs)
		default:
			return state
	}
}

export function analyticsJobsTemp(state = List([]), action) {
	switch(action.type) {
		case 'ANALYTICS_JOBS_FETCH_DATA_TEMP_SUCCESS':
			return fromJS(action.analyticsJobsTemp)
		default:
			return state
	}
}

export function analyticsFilterData(state = initAnalyticsFilterData, action) {
	switch(action.type) {
		case 'ANALYTICS_ADD_FILTER_JOB_ID_SUCCESS':
			return state.set('job_id', action.job_id)
		case 'ANALYTICS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'ANALYTICS_ADD_FILTER_ID_SUCCESS':
			return state.set('invite_id', action.invite_id)
		case 'ANALYTICS_ADD_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('invite_ids', List(action.invite_ids))
		case 'ANALYTICS_CLEAR_FILTER_DATA_SUCCESS':
			return state.merge(fromJS({
				job_id: 0,
				page: '',
				invite_id: '',
				invite_ids: []
			}))
		default:
			return state
	}
}

export function analyticsInvites(state = List([]), action) {
	switch(action.type) {
		case 'ANALYTICS_INVITES_FETCH_DATA_SUCCESS':
			return fromJS(action.analyticsInvites)
		default:
			return state
	}
}

export function analyticsInvitesPagination(state = Map({}), action) {
	switch(action.type) {
		case 'ANALYTICS_INVITES_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

export function analyticsCandidatesData(state = Map({}), action) {
  switch(action.type) {
    case 'ANALYTICS_CANDIDATES_DETAIL_SUCCESS':
    	return fromJS(action.analyticsCandidatesData)
    default:
    	return state
  }
}

export function analyticsInviteIds(state = List([]), action) {
  switch(action.type) {
    case 'ANALYTICS_FETCH_IDS_SUCCESS':
    	return List(action.ids)
    default:
    	return state
  }
}
