import React, { Component } from 'react'
import Select from 'react-select'


class RenderSelectMulti  extends Component {
	renderOption(option) {
		return(<span>{option.label}<i className="fa fa-info-circle" style={{'marginLeft': "5px","fontSize":"16"}} data-toggle="tooltip" data-placement="right" title={option.description}></i></span>)
	}

	render() {
		const {
			input,
			label,
			placeholder,
			options,
			disabled,
			className,
			meta: { touched, error },
		} = this.props

		return(
			<div>
			  <label>{label}</label>
		  	<Select
				className={className}
				value={input.value}
				onBlur={() => input.onBlur(input.value)}
				options={options}
				placeholder={placeholder}
				clearable={false}
				disabled={disabled}
				multi={true}
				removeSelected={true}
				simpleValue 
				optionRenderer={this.renderOption}
				onChange={(newValue, delta, source,text) => {
					console.log('NWE VALUE', newValue);
		  	  		var temp = newValue ;
					var arr = newValue.split(",")
					if(arr.includes('all')){
						temp = 'all'
					}
		  	  		// for (var i = 0; i < newValue.length; i++) {
		  	  		// 	var new_value_with_space = newValue[i].value.split(" ");
		  	  		// 	var new_value_with_comma = newValue[i].value.split(",");
		  	  		// 	if(new_value_with_comma.length > 1){
					// 		new_value_with_comma.map((value,index)=>{
					// 			if(value != ''){
					// 				temp.push({'className':'Select-create-option-placeholder','label':value.trim(),'value':value.trim()})
					// 			}
					// 		})
		  	  		// 	}else if(new_value_with_space.length > 1){
					// 		new_value_with_space.map((value,index)=>{
					// 			if(value != ''){
					// 				temp.push({'className':'Select-create-option-placeholder','label':value.trim(),'value':value.trim()})
					// 			}
					// 		})
		  	  		// 	}else if(!newValue[i].value.includes(" ") && !newValue[i].value.includes(",") ){
					// 				temp.push({'className':'Select-create-option-placeholder','label':newValue[i].value.trim(),'value':newValue[i].value.trim()})
		  	  		// 	}

		  	  		// }
            		input.onChange(temp);
          		}}
			/>
			{touched && error && <div><span className="error js-err-email">{error}</span></div>}
			</div>
		)
	}
}

export default RenderSelectMulti
