import { fromJS } from 'immutable'
import React from 'react'

class RenderSelect extends React.Component {
	render() {
		const { input, label, meta: { touched, error }, dropdownOption, onChange, hidden, disabled, hasInfo, info, disableOptionText } = this.props
		let options = dropdownOption
		let defaultOption = 'Select the number of option';
		if(disableOptionText){
			defaultOption = disableOptionText
		}

		if(disabled) {
			options = fromJS([
				{ value: '44', label: 'Account Holder' }
			])
		}
		return(
			<div className={`input-container ${touched && error && 'has-error'} ${hidden?'hide':''}`}>
		    <div><label>{label}</label>{hasInfo ? <i className="fa fa-info-circle purple m-l-5" data-toggle="tooltip" data-placement="right" title={info}></i> : ''}</div>
		    <div>
		    	<select
						onChange={onChange}
		    		{...input}
		    		className=""
					aria-readonly={disabled}
					>
		    		<option value='' disabled>{defaultOption}</option>
						{
							options.map((option, index)=>
								<option key={index} value={option.get('value')}>
									{option.get('label')}
								</option>
							)
						}
		    	</select>
		      {touched && error && <div><span className="error">{error}</span></div>}
		    </div>
		  </div>
		)
	}
}


export default RenderSelect
