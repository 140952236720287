// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map, List } from 'immutable'
import RenderSelect from '../partials/RenderSelect'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import validate from '../users/validate'
import RenderInput from '../partials/RenderInput'
import RenderTextArea from '../partials/RenderTextArea'
import RenderSelectMultiCreatable  from '../partials/RenderSelectMultiCreatable'

import {
	getDropdownUserLevel,
	sendEmailToUsers
} from '../../actions/users'
type Props = {
	sendEmailToUsers: Function,
	usersFilterData: Map<string, any>,
	handleSubmit: Function,
	pristine: boolean,
	submitting: boolean,
	clearReceiver: Function
}

class ModalComposeEmail extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			value: [],
			fromDate: '',
			toDate: '',
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleSelectChange',
			'handleFromDateChange',
			'handleToDateChange',
			'handleSendEmail'
		])
	}

	handleCloseModalClick(e: Object) {
		e.preventDefault()
		this.props.clearReceiver()
		$('#modalComposeEmail').modal('hide')
	}

	handleSelectChange (value) {
		this.setState({ value });
	}

	handleFromDateChange(date) {
    this.setState({
        fromDate: date
    });
  }

  handleToDateChange(date) {
    this.setState({
        toDate: date
    });
  }

	handleSendEmail(value){
		const {
			formValues,
			sendEmailToUsers,
			reset
		} = this.props

		if(value.receiver ==''){
			if (document.getElementById("js-err-email") != null){
				document.getElementById("js-err-email").innerHTML="Email field can't be empty";
			}
		}else if(value.body ==''){
			if (document.getElementById("js-err-message") != null){
				document.getElementById("js-err-message").innerHTML="Body Message field can't be empty";
			}
		}
		else{
			let emails=[]
			value.receiver.map(email=>{
				emails.push(email.value)
			})
			let composeEmail = value
			composeEmail.receiver = emails
			sendEmailToUsers(composeEmail).then(()=>{
				reset('ModalComposeEmail')
				$('#modalComposeEmail').modal('hide')
			})
		}
	}

	onChangeEmail(val){

	}

  render() {
  	const {
			receiver,
			handleSubmit,
    	pristine,
			submitting,
			invalid,
			formValues
		} = this.props;
    return (
      <div className="modal" id="modalComposeEmail" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
					<form onSubmit={handleSubmit(this.handleSendEmail)} id="job-form_create_new_job" encType="multipart/form-data">
        <div className="modal-dialog m-t-0">
						<div className="modal-content animated fadeIn">
							<div className="modal-header horizontal-item--between">
								<i onClick={this.handleCloseModalClick} className="fa fa-close"></i>
								<label>Compose Email</label>
								<button disabled={pristine || submitting || invalid}>Send Email</button>
							</div>
							<div className="modal-body">
								<Field
									name="receiver"
									component={RenderSelectMultiCreatable}
									placeholder="Enter your email(s)"
									label="Recipient(s)"
									defaultValue={receiver}
									onChange={this.onChangeEmail}
								/>
								<Field
									name="subject"
									type="text"
									component={RenderInput}
									label="Subject"
									placeholder="Subject"
									id="subject"
									className="input-text"
								/>
								<Field
									name="body"
									component={RenderTextArea}
									label="Message"
									placeholder="Message"
									id="body"
								/>
							</div>
						</div>
        </div>
					</form>
      </div>
    )
	}
}

ModalComposeEmail= reduxForm({
  form: 'ModalComposeEmail',
  validate: validate,
	initialValues: {
		subject: '',
		receiver:'',
		body: ""
  },
	enableReinitialize: true
})(ModalComposeEmail)

const selector = formValueSelector('ModalComposeEmail')

const mapStateToProps = (state, ownProps) => {
	const initialValues = {
		subject: '',
		receiver:ownProps.receiver,
		body: ''
	}
	return {
		formValues: selector(
			state,
			'subject',
			'body',
			'receiver'
		),
		initialValues,
		usersFilterData: state.usersFilterData,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		sendEmailToUsers: (is_active) => dispatch(sendEmailToUsers(is_active))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalComposeEmail)
