export default {
	emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
	phoneRegex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
	numberRegex: /^[0-9]*$/,
	exceptNumberRegex: /^([^0-9]*)$/,
	spaceRegex: /^\s+$/g,
	specialCharRegex: /^[a-zA-Z\d\-_.,\s]+$/,
	alphaNumeric: /^[-_. a-zA-Z0-9]+$/,
	urlRegex: /^https?:\/\/+[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
	stripePubKeyBeta: 'pk_test_On7F5lbpijoNlLvtVGSQsFnH',
	stripePubKeyLive: 'pk_live_IdHTcBcTnKOG0KMhWIdDQelw',
	paypalSecKeyBeta: 'Ae6rKJN84Lc4xUebNbNsKAFppIpQqVZS-BIFfItfzgL3668bHqgj7j2TQIL0KBT4u8w3n0370HKGgYE7',
	paypalSecKeyLive: 'Ae6rKJN84Lc4xUebNbNsKAFppIpQqVZS-BIFfItfzgL3668bHqgj7j2TQIL0KBT4u8w3n0370HKGgYE7'
}
