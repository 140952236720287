import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import history from '../dependencies/history'
import {setIsFetched} from './global'
import { Link } from  'react-router-dom'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function usersFetchDataSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_SUCCESS',
		users
	}
}

export function usersFetchDataHiringTeamSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_HIRING_TEAM_SUCCESS',
		users
	}
}

export function usersGetDetailSuccess(user) {
	return {
		type: 'USERS_GET_DETAIL_SUCCESS',
		user
	}
}

export function usersGetDetailTempSuccess(user) {
	return {
		type: 'USERS_GET_DETAIL_TEMP_SUCCESS',
		user
	}
}

export function usersHasErrored(bool) {
	return {
		type: 'USERS_HAS_ERRORED',
		hasErrored: bool
	}
}

export function usersSaveDataError(errors) {
	return {
		type: 'USERS_SAVE_DATA_ERROR',
		errors
	}
}

export function usersAddFilterPageNumberSuccess(page) {
	return {
		type: 'USERS_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function usersAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'USERS_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function usersAddFilterSortSuccess(sort) {
	return {
		type: 'USERS_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function usersAddFilterFullnameSuccess(fullname) {
	return {
		type: 'USERS_ADD_FILTER_FULLNAME_SUCCESS',
		fullname
	}
}

export function usersAddFilterSearchSuccess(search) {
	return {
		type: 'USERS_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}

export function usersAddFilterJobIdSuccess(job_id) {
	return {
		type: 'USERS_ADD_FILTER_JOB_ID_SUCCESS',
		job_id
	}
}

export function usersClearFilterDataSuccess() {
	return {
		type: 'USERS_CLEAR_FILTER_DATA_SUCCESS'
	}
}

export function usersAddFormIsUpdateSuccess(is_update) {
	return {
		type: 'USERS_ADD_FORM_IS_UPDATE_SUCCESS',
		is_update
	}
}

export function usersAddFormIsAccessIncJobsSuccess(is_access_inc_jobs) {
	return {
		type: 'USERS_ADD_FORM_IS_access_inc_jobs_SUCCESS',
		is_access_inc_jobs
	}
}

export function usersAddFormIsAccessExistingJobSuccess(is_access_existing_job) {
	return {
		type: 'USERS_ADD_FORM_IS_ACCESS_EXISTING_JOB_SUCCESS',
		is_access_existing_job
	}
}

export function usersAddFormIsDisableFullnameSuccess(is_disable_fullname) {
	return {
		type: 'USERS_ADD_FORM_IS_DISABLE_FULLNAME_SUCCESS',
		is_disable_fullname
	}
}

export function usersClearFormDataSuccess() {
	return {
		type: 'USERS_CLEAR_FORM_DATA_SUCCESS'
	}
}

export function usersFetchIdsSuccess(ids) {
	return {
		type: 'USERS_FETCH_IDS_SUCCESS',
		ids
	}
}

export function usersFetchPaginationSuccess(pagination) {
	return {
		type: 'USERS_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function usersFetchDataDropdownSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_DROPDOWN_SUCCESS',
		users
	}
}

export function usersAddNavigationPage(page) {
	return {
		type: 'USERS_ADD_NAVIGATION_PAGE_SUCCESS',
		page
	}
}

export function usersSaveDataRequest(formName) {
	return {
		type: 'USERS_SAVE_DATA_REQUEST',
		formName
	}
}

export function usersAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterPageNumberSuccess(page)))
		})
	}
}

// Support Dashboard Set Selected IDS
export function usersAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function usersAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterSortSuccess(sort)))
		})
	}
}

export function usersAddFilterFullname(fullname) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterFullnameSuccess(fullname)))
		})
	}
}

export function usersAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterSearchSuccess(search)))
		})
	}
}

export function usersAddFormIsUpdate(is_update) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFormIsUpdateSuccess(is_update)))
		})
	}
}

export function usersGetDetailAsync(user) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersGetDetailSuccess(user)))
		})
	}
}

export function usersGetDetailTempAsync(user) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersGetDetailTempSuccess(user)))
		})
	}
}

export function usersAddFilterJobId(job_id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterJobIdSuccess(job_id)))
		})
	}
}

export function usersClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersClearFilterDataSuccess()))
		})
	}
}

export function usersFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(usersFetchDataSuccess([]))
		dispatch(setIsFetched(false))
		
		return axios({
			url:`/support-dashboard/users/filter`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const userIds = response.data.data.ids
			const users = response.data.data.data
			const pagination = omit(response.data.data, 'data')

			dispatch(usersFetchIdsSuccess(userIds))
			dispatch(usersFetchPaginationSuccess(pagination))
			dispatch(usersFetchDataSuccess(users))
			dispatch(setIsFetched(true))
			
		})
		.catch((error) => {
			dispatch(usersFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

// When we need to assign user to temporary data like on edit team member, set temp parameter to true
export function usersGetDetailTemp(company_id, user_id) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/companies/${company_id}/team-member/${user_id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()

			const user = response.data
			dispatch(usersGetDetailTempSuccess(user))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user.',
				position: 'bl'
			}))
		})
	}
}

// Support Dashboard Save Data

export function usersCreateData(data) {
	if(!data.channel){
		data.channel = 'astrnt'
	}
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/users/store`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()
			if(response.data.error==true){
				dispatch(usersSaveDataError(response.data))
			}
			dispatch(Notifications.success({
				title: 'Success',
				message: 'User successfully created.',
				position: 'bl'
			}))

			let prevPage = `/support/users`
			if(data.prevPage){
				prevPage = data.prevPage
			}
			let redirect = { 
				pathname: `/support/users`,
				state: {prevPage: prevPage}
			}

			if(response.data.data){
				if(response.data.data.company){
					let company = response.data.data.company
					if(company.channel=='popskul'){
						dispatch(updateTotalAccountRequiringAction(1))
					}
					if(data.channel==='career_development_center'){
						redirect = { 
							pathname: `/support/university/${company.id}/edit`,
							state: {prevPage: prevPage},
						}
					}else{
						redirect = { 
							pathname: `/support/companies/${company.id}/edit`,
							state: {prevPage: prevPage},
						}
					}
				}
				
			}

			history.push(redirect)
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when creating user.',
					position: 'bl'
				}))
			}
		})
	}
}

export function usersUpdateData(userId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/users/${userId}/update`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User successfully updated.',
				position: 'bl'
			}))

			dispatch(reset('ModalAddMember'))
			history.push({ 
				pathname: `/support/users`,
				state: {prevPage: 'user-detail'}
			})
		})
		.catch((error) => {
			NProgress.done()
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating user.',
				position: 'bl'
			}))

			dispatch(usersSaveDataError(error.response.data.message))
		})
	}
}

export function usersUpdateProfileData(company_id, user_id, data, user) {
	return (dispatch) => {
		NProgress.start()

		axios({
			url: `/companies/${company_id}/users/${user_id}`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User successfully updated.',
				position: 'bl'
			}))

			const _user = user.set('preferredName', response.data.user.preferred_name)

			dispatch(usersGetDetailSuccess(_user))
			dispatch(usersSaveDataError({}))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured.',
			  position: 'bl'
			}))
		})
	}
}

// Support Dashboard Delete User
export function usersDelete(userIds) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url:`/support-dashboard/users/delete`,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {ids:userIds}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User successfully deleted.',
				position: 'bl'
			}))

			dispatch(usersAddFilterSelectedIds([]))
			// dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting user.',
			  position: 'bl'
			}))

			
		})
	}
}

// Get detail user without store the value to reducer.
export function usersGetDetailByParam(company_id, filter_data) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			axios({
				url: `/users/filter`,
				timeout: 120000,
				method: 'get',
				responseType: 'json',
				params: filter_data
			})
			.then((response) => {
				const user = response.data.user

				resolve(user)
			})
			.catch((error) => {
				dispatch(usersHasErrored(true))

				
			})
		})
	}
}

export function usersUpdateIsNotifiedAndLevel(companyId, userId, data, jobId = '') {
	return (dispatch) => {
		let url = `/companies/${companyId}/team-member/${userId}/job`
		let messageSuccess = 'Team member successfully updated.'
		let messageError = 'An error occured when updating team member.'

		if (jobId) {
			url = `/companies/${companyId}/jobs/${jobId}/hiring-team/${userId}`
			messageSuccess = 'Hiring team successfully updated.'
			messageError = 'An error occured when updating hiring team.'
		}

		return axios({
			url,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			data: data
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: 'Success',
				message: messageSuccess,
				position: 'bl'
			}))
		})
		.catch((error) => {
			dispatch(Notifications.error({
			  title: 'Error',
			  message: messageError,
			  position: 'bl'
			}))

			
		})
	}
}

/*
* List All User
*/
export function usersFetchAllData(filterData) {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url:`/support-dashboard/users`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			NProgress.done()

			const userIds = response.data.ids
			const users = response.data.users.data
			const pagination = omit(response.data.users, 'data')

			dispatch(usersFetchIdsSuccess(userIds))
			dispatch(usersFetchPaginationSuccess(pagination))
			dispatch(usersFetchDataSuccess(users))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading users.',
				position: 'bl'
			}))

			
		})
	}
}

/*
* List All User
*/


export function userGetDetail(id, type='user') {
	let url = `/support-dashboard/users/${id}/detail`;
	if(type=='contact-schedule'){
		 url = `/support-dashboard/companies/contact-schedule/${id}/detail`;

	}
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: url,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			dispatch(usersGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user.',
				position: 'bl'
			}))

			
		})
	}
}

export function userGetAuth() {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/initial-data`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			dispatch(userGetAuthSuccess(response.data.user))
			dispatch(informationFetchDataSuccess(response.data.information))
			dispatch(timezonesFetchDataSuccess(response.data.timezones))
			dispatch(accountManagerFetchDataSuccess(response.data.account_manager))
			// dispatch(Notifications.error({
			// 	title: 'Success',
			// 	message: <span>You have <b>{response.data.information.plan_request} new request</b> <Link to={`/support/custom-plan`} className="btn-notif">View Accounts Requiring Actions</Link></span>,
			// 	position: 'bl'
			// }))
		})
		.catch((error) => {
			NProgress.done()
		})
	}
}


export function usersAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function usersAddFilterColPerPageSuccess(per_page){
	return{
		type:'USERS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

export function usersValidateEmailOnCreateUserFormRequest(email, formName='UserForm') {
	return {
		type: 'USERS_VALIDATE_EMAIL_ON_CREATE_USER_FORM_REQUEST',
		email,
		formName
	}
}

export function userGetAuthSuccess(user) {
	return {
		type: 'USERS_GET_AUTH_SUCCESS',
		user
	}
}

export function sendEmailToUsers(data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/users/send-email`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()
			if(response.data.error==true){
				dispatch(usersSaveDataError(response.data))
			}
			dispatch(Notifications.success({
				title: 'Success',
				message: 'Email Sent',
				position: 'bl'
			}))
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when send email to user.',
					position: 'bl'
				}))
			}
		})
	}
}

// User Level
export function getDropdownUserLevel() {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/dropdown-user-level`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(dropdownUserLevelsFetchDataSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function dropdownUserLevelsFetchDataSuccess(user_level) {
	return {
		type: 'DROPDOWN_USER_LEVELS_FETCH_DATA_SUCCESS',
		user_level
	}
}

export function usersActivate(userIds, is_active) {
	return (dispatch) => {
		NProgress.start()
		let labelStatus = is_active==1 ? 'activated' : 'deactivated'
		return axios({
			url:`/support-dashboard/users/activate`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {ids:userIds, is_active: is_active}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: `User successfully ${labelStatus}`,
				position: 'bl'
			}))

			dispatch(usersAddFilterSelectedIds([]))
			// dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: `An error occured when ${labelStatus} user`,
			  position: 'bl'
			}))

			
		})
	}
}

export function resetUserPassword(userId, companyId) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url:`/support-dashboard/users/${userId}/reset-password`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			data:{
				company_id: companyId
			},
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User password successfully reseted.',
				position: 'bl'
			}))

			dispatch(usersAddFilterSelectedIds([]))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when reseting user password.',
			  position: 'bl'
			}))
		})
	}
}

export function clearFormUser(){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(clearFormUserSuccess()))
		})
	}
}

export function clearFormUserSuccess(user_level) {
	return {
		type: 'CLEAR_FORM_DATA_USER_SUCCESS',
		user_level
	}
}

export function usersAddFilterIsActiveSuccess(is_active){
	return{
		type:'USERS_ADD_FILTER_IS_ACTIVE_SUCCESS',
		is_active
	}
}

export function usersAddFilterIsActive(is_active){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersAddFilterIsActiveSuccess(is_active)))
		})
	}
}

export function usersAddFilterLoginFromSuccess(from){
	return{
		type:'USERS_ADD_FILTER_LOGIN_FROM_SUCCESS',
		from
	}
}

export function usersAddFilterLoginFrom(from){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersAddFilterLoginFromSuccess(from)))
		})
	}
}

export function usersAddFilterLoginToSuccess(to){
	return{
		type:'USERS_ADD_FILTER_LOGIN_TO_SUCCESS',
		to
	}
}

export function usersAddFilterLoginTo(to){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersAddFilterLoginToSuccess(to)))
		})
	}
}


export function usersAddFilterCompanyIdSuccess(company_id){
	return{
		type:'USERS_ADD_FILTER_COMPANY_ID_SUCCESS',
		company_id
	}
}

export function usersAddFilterCompanyId(company_id){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersAddFilterCompanyIdSuccess(company_id)))
		})
	}
}

export function usersAddFilterUserLevelSuccess(user_level){
	return{
		type:'USERS_ADD_FILTER_USER_LEVEL_SUCCESS',
		user_level
	}
}

export function usersAddFilterUserLevel(user_level){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersAddFilterUserLevelSuccess(user_level)))
		})
	}
}

// Start User Levels

export function userLevelsFetchDataSuccess(users) {
	return {
		type: 'USER_LEVEL_LIST_FETCH_DATA_SUCCESS',
		users
	}
}

export function userLevelGetDetailSuccess(user) {
	return {
		type: 'USER_LEVELS_GET_DETAIL_SUCCESS',
		user
	}
}

export function userLevelsFetchIdsSuccess(ids) {
	return {
		type: 'USER_LEVELS_FETCH_IDS_SUCCESS',
		ids
	}
}

export function userLevelsFetchPaginationSuccess(pagination) {
	return {
		type: 'USER_LEVELS_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}
export function userLevelsAddFilterPageNumberSuccess(page) {
	return {
		type: 'USER_LEVELS_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function userLevelsFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(userLevelsFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/user-level/filter`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const users = response.data.data.data
			const pagination = omit(response.data.data, 'data')
			dispatch(userLevelsFetchPaginationSuccess(pagination))
			dispatch(userLevelsFetchDataSuccess(users))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(userLevelsFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function userLevelsAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userLevelsAddFilterPageNumberSuccess(page)))
		})
	}
}

export function userLevelsAddFilterName(name) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userLevelsAddFilterNameSuccess(name)))
		})
	}
}

export function userLevelsAddFilterNameSuccess(name) {
	return {
		type: 'USER_LEVELS_ADD_FILTER_NAME_SUCCESS',
		name
	}
}

export function userLevelsAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(userLevelsAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function userLevelsAddFilterColPerPageSuccess(per_page){
	return{
		type:'USER_LEVELS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

export function userLevelsClearFilterData(name) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userLevelsClearFilterDataSuccess(name)))
		})
	}
}

export function userLevelsClearFilterDataSuccess(name) {
	return {
		type: 'USER_LEVELS_CLEAR_FILTER_DATA_SUCCESS',
		name
	}
}

export function userLevelCreateData(data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/user-level/save`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()
			dispatch(Notifications.success({
				title: 'Success',
				message: 'User Level successfully created.',
				position: 'bl'
			}))

			history.push({ 
				pathname: `/support/user-level`,
				state: {prevPage: 'user-level-detail'}
			})
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when creating user level.',
					position: 'bl'
				}))
			}
		})
	}
}

export function userLevelUpdateData(userId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/user-level/${userId}/update`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User level successfully updated.',
				position: 'bl'
			}))

			history.push({ 
				pathname: `/support/user-level`,
				state: {prevPage: 'user-level-detail'}
			})
		})
		.catch((error) => {
			NProgress.done()
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating user level.',
				position: 'bl'
			}))
		})
	}
}

export function userLevelGetDetail(id) {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/user-level/${id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			dispatch(userLevelGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user level.',
				position: 'bl'
			}))

			
		})
	}
}

// export function userLevelGetDetailSuccess(user) {
// 	return {
// 		type: 'USERS_GET_DETAIL_SUCCESS',
// 		user
// 	}
// }

export function resetFormUserLevel() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userLevelGetDetailSuccess({})))
		})
	}
}

export function userLevelDelete(userIds) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url:`/support-dashboard/user-level/delete`,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {ids:userIds}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User level successfully deleted.',
				position: 'bl'
			}))
			// dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting user level.',
			  position: 'bl'
			}))

			
		})
	}
}

export function userLevelsAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userLevelsAddFilterSortSuccess(sort)))
		})
	}
}

export function userLevelsAddFilterSortSuccess(sort) {
	return {
		type: 'USER_LEVELS_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function userLevelsAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userLevelsAddFilterSearchSuccess(search)))
		})
	}
}

export function userLevelsAddFilterSearchSuccess(search) {
	return {
		type: 'USER_LEVELS_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}
// End User Level

// Start Internal User
export function internalUsersFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(usersInternalFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/internal-users/filter`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const userIds = response.data.data.ids
			const users = response.data.data.data
			const pagination = omit(response.data.data, 'data')

			dispatch(usersInternalFetchIdsSuccess(userIds))
			dispatch(usersInternalFetchPaginationSuccess(pagination))
			dispatch(usersInternalFetchDataSuccess(users))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(usersInternalFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}


export function internalUsersValidateEmailOnCreateUserFormRequest(email) {
	return {
		type: 'INTERNAL_USERS_VALIDATE_EMAIL_ON_CREATE_USER_FORM_REQUEST',
		email
	}
}

export function getDropdownAdminLevel() {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/dropdown-admin-level`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(dropdownAdminLevelsFetchDataSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function dropdownAdminLevelsFetchDataSuccess(admin_level) {
	return {
		type: 'DROPDOWN_ADMIN_LEVELS_FETCH_DATA_SUCCESS',
		admin_level
	}
}

export function internalUserSaveData(data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/internal-users/save`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()
			dispatch(Notifications.success({
				title: 'Success',
				message: response.data.message,
				position: 'bl'
			}))

			history.push({ 
				pathname: `/support/internal-user/`,
				state: {prevPage: 'internal-user-detail'}
			})
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when creating internal user.',
					position: 'bl'
				}))
			}
		})
	}
}

export function internalUsersDelete(userIds) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url:`/support-dashboard/internal-users/delete`,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {ids:userIds}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: response.data.message,
				position: 'bl'
			}))

			dispatch(usersAddFilterSelectedIds([]))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting user.',
			  position: 'bl'
			}))
		})
	}
}

export function usersFetchReceiver(filterData) {
	return (dispatch) => {
		NProgress.start()
		let filter = filterData.toJS()
		filter.receiver = true
		return axios({
			url:`/support-dashboard/users/filter`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filter
		})
		.then((response) => {
			NProgress.done()
			const users = response.data.data
			dispatch(usersFetchReceiverDataSuccess(users))
		})
		.catch((error) => {
			NProgress.done()
		})
	}
}

export function usersFetchReceiverData(receivers) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersFetchReceiverDataSuccess(receivers)))
		})
	}
}

export function usersFetchReceiverDataSuccess(receivers) {
	return {
		type: 'USERS_FETCH_RECEIVERS_DATA_SUCCESS',
		receivers
	}
}

export function usersInternalAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersInternalAddFilterPageNumberSuccess(page)))
		})
	}
}

export function usersInternalAddFilterPageNumberSuccess(page) {
	return {
		type: 'USERS_INTERNAL_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function usersInternalAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersInternalAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function usersInternalAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'USERS_INTERNAL_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function usersInternalAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersInternalAddFilterSortSuccess(sort)))
		})
	}
}

export function usersInternalAddFilterSortSuccess(sort) {
	return {
		type: 'USERS_INTERNAL_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function usersInternalAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersInternalAddFilterSearchSuccess(search)))
		})
	}
}

export function usersInternalAddFilterSearchSuccess(search) {
	return {
		type: 'USERS_INTERNAL_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}

export function usersInternalAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(usersInternalAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function usersInternalAddFilterColPerPageSuccess(per_page){
	return{
		type:'USERS_INTERNAL_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

export function usersInternalClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersInternalClearFilterDataSuccess()))
		})
	}
}

export function usersInternalClearFilterDataSuccess() {
	return {
		type: 'USERS_INTERNAL_CLEAR_FILTER_DATA_SUCCESS'
	}
}

export function usersInternalFetchPaginationSuccess(pagination) {
	return {
		type: 'USERS_INTERNAL_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function usersInternalFetchDataSuccess(users) {
	return {
		type: 'USERS_INTERNAL_FETCH_DATA_SUCCESS',
		users
	}
}

export function usersInternalFetchIdsSuccess(ids) {
	return {
		type: 'USERS_INTERNAL_FETCH_IDS_SUCCESS',
		ids
	}
}

export function informationFetchDataSuccess(information) {
	return {
		type: 'INFORMATION_FETCH_DATA_SUCCESS',
		information
	}
}

export function timezonesFetchDataSuccess(timezones) {
	return {
		type: 'TIMEZONES_FETCH_DATA_SUCCESS',
		timezones
	}
}

export function accountManagerFetchDataSuccess(account_manager) {
	return {
		type: 'ACCOUNT_MANAGERS_FETCH_DATA_SUCCESS',
		account_manager
	}
}

export function updateTotalAccountRequiringAction(total=1) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(updateTotalAccountRequiringActionSuccess(total)))
		})
	}
}

export function updateTotalAccountRequiringActionSuccess(total) {
	return {
		type: 'UPDATE_TOTAL_ACCOUNT_REQUIRING_ACTION',
		total
	}
}