import { Map } from 'immutable'
import UAParser from 'ua-parser-js'



export function setHttpHeaders(params: Object = {}): Object {
    const parser = new UAParser()
    const device = parser.getDevice()
    const os = parser.getOS()
    const browser = parser.getBrowser()
  
    const headers = {
      device: '',
      os: `${os.name} ${os.version}`,
      browser: `${browser.name} ${browser.version}`,
      screenresolution: `${window.screen.availHeight}x${window.screen.availWidth}`
    }
  
    if (Object.keys(params).length) {
      Object.assign(headers, params)
    }
  
    if (device.type) {
      Object
        .assign(
          headers,
          {
            device: `${device.type} ${device.model} ${device.vendor}`
          }
        )
    }
      
    return headers
  }
  
  // Concatenate two List data that contain object, also it will ordered by id asc
export function globalConcatImmutableData(data1: List<Map<string, any>>, data2: List<Map<string, any>>, orderBy: string = 'asc'): List<Map<string, any>> {
  const data: List<Map<string, any>> = data1
    .concat(data2)
    .toSet()
    .toList()

  return orderBy === 'asc' ? data.sortBy((a) => a.get('id')) : data.sortBy((a) => -a.get('id'))
}

export function calculatePixelSize(textToMeasure: string, style: Object): Object {
  let widthCalculator = document.getElementById('size-calculator-width-span')

  if (!widthCalculator) {
    widthCalculator = document.createElement('span')
    widthCalculator.id = 'size-calculator-width-span'
    widthCalculator.style.cssText = 'position: absolute; white-space: pre; opacity: 0;'

    // $FlowFixMe
    document.body.appendChild(widthCalculator)
  }

  widthCalculator.innerText = textToMeasure

  if (style) {
    const relevantStyles = [
      'letterSpacing',
      'tabSize',
      'wordSpacing',
      'font',
      'fontFamily',
      'fontKerning',
      'fontSize',
      'fontStretch',
      'fontStyle',
      'fontVariant',
      'fontVariantCaps',
      'fontWeight'
    ]

    Object
      .keys(style)
      .filter((key) => {
        return relevantStyles.indexOf(key) >= 0
      })
      .forEach((key) => {
        // $FlowFixMe
        widthCalculator.style[key] = style[key]
      })
  }

  let size = {}
  let boundingRect = widthCalculator.getBoundingClientRect()

  if (boundingRect.width) {
    size.width = boundingRect.width
  } else {
    size.width = boundingRect.right - boundingRect.left
  }

  if (boundingRect.height) {
    size.height = boundingRect.height
  } else {
    size.height = boundingRect.bottom - boundingRect.top
  }

  return size
}


export function objectToFormData(obj: Object, form: Object, namespace: string) {
  let fd = form || new FormData()
  let formKey

  for(let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey)
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}

export function getUrlParams(name: string, url: string = ''): ?string {
	if (!url) {
		url = window.location.href
	}

	name = name.replace(/[[]/,"\\[").replace(/[\]]/,"\\]")

	const regexS = "[\\?&]"+name+"=([^&#]*)"
	const regex = new RegExp(regexS)
	const results = regex.exec(url)

	return results == null ? null : results[1]
}

export function formatBytes(bytes, k = 1000, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}