import React, { Component } from 'react'

class RenderInput  extends Component {
	render() {
		const { input, id, type, disabled, label, placeholder, desc, meta: { touched, error }, isValidatingEmail} = this.props

		return(
				<div className={`input-container ${touched && error && 'has-error'} ${isValidatingEmail && 'has-warning'}`}>
					<div><label>{label}</label></div>
					<div>
						<div className="input-search-container horizontal-item--between m-r-16">
							<input className="input-search" type={type} {...input} placeholder={placeholder} id={id} disabled={disabled}></input>
							<i className="fa fa-search"></i>
						</div>
						{touched && error && <div><span className="error">{error}</span></div>}
						{!error && isValidatingEmail && <div><span className="error">Checking your email...</span></div>}
					</div>
				</div>
		)
	}
}


export default RenderInput
