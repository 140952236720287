// @flow

import React from 'react'
import { connect } from 'react-redux'
import title_case from 'voca/title_case'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../../components/partials/PaginationSupport'

import {
	transactionsFetchData,
	transactionsAddFilterPageNumber,
	transactionsAddFilterSort,
	transactionsAddFilterSearch,
	transactionsClearFilterData,
	transactionsAddFilterColPerPage,
	transactionsAddFilterPaymentMethod,
	transactionsAddFilterPaymentStatus,
	transactionsUpdatePaymentStatus,
} from '../../actions/transactions'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import TableLoader from '../partials/TableLoader'

require('es6-promise').polyfill()

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	transactionsPagination: Map<string, any>,
	transactionsFilterData: Map<string, any>,
	transactionsFetchData: Function,
	transactionsAddFilterPageNumber: Function,
	transactionsAddFilterSort: Function,
	transactionsAddFilterSearch: Function,
	transactionsClearFilterData: Function,
	transactionsAddFilterPaymentMethod: Function,
	transactionsAddFilterPaymentStatus: Function,
	globalIndicatorData: Map<string, any>,
	transactionsAddFilterColPerPage: Function,
	transactionsUpdatePaymentStatus: Function,
};

type State = {
	isShown: boolean
};

class TransactionList extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selected_ids:[]
		}

		bindAll(this, [
			'handleChangePageSize',
			'handleSearchByName',
			'handleChangePaymentMethod',
			'handleChangePaymentStatus',
			'paymentStatusFormatter',
			'handleUpdatePaymentStatus',
			'documentFormatter',
			'handleSelectRow',
			'invoiceFormatter',
			'handleSortChange'
		])
	}

	componentDidMount() {
		const {
			transactionsFetchData,
			transactionsClearFilterData
		} = this.props

		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'transaction-detail'){
			transactionsFetchData(this.props.transactionsFilterData)
		}else{
			transactionsClearFilterData().then(()=>transactionsFetchData(this.props.transactionsFilterData))
		}
	}

	handleChangePageSize(e): void{
		const{
			transactionsAddFilterPageNumber,
			transactionsFetchData,
			transactionsAddFilterColPerPage,
		}=this.props
		const countFilter = e.target.value
		transactionsAddFilterPageNumber(1)
		.then(()=>{
			transactionsAddFilterColPerPage(countFilter)
			.then(()=>{
				transactionsFetchData(this.props.transactionsFilterData)
			})
		})

	}

	handleSearchByName(event){
		const{
			transactionsAddFilterPageNumber,
			transactionsFetchData,
			transactionsAddFilterSearch
		}=this.props

		let fullname=event.target.value
		if (event.charCode === 13) {
			transactionsAddFilterPageNumber(1).then(()=>{
				transactionsAddFilterSearch(fullname).then(()=>{
					transactionsFetchData(this.props.transactionsFilterData)
				})
			})
    	}
	}

	paymentFormatter(cell: Object, row: Object): Object {
		let paymentMethod = title_case(row.source.split('_').join(' '))
		paymentMethod = paymentMethod.replace('bca','BCA')
		return 	<div>
					<p style={{fontSize: '16px',lineHeight: '24px'}}>{`IDR  ${row.total_price},-`}<br/>{`${paymentMethod}`}</p>
				</div>
	}

	documentFormatter(cell: Object, row: Object): Object {
		if(row.efaktur_url=='' && row.invoice_url==''){
			return 	<span>N/A</span>
		}else{
			return 	<div>
				{ row.invoice_url!==''  &&
					<span>
						<a style={{cursor:'pointer'}} target="_blank" href={row.invoice_url}>Invoice</a>
					</span>
				}
				
				{
					row.efaktur_url!=='' &&
					<span>
						<a style={{cursor:'pointer'}} target="_blank" href={row.efaktur_url}>{row.invoice_url!=='' ? ' | ':''}Efaktur</a>
					</span>
				}
			</div>
		}
	}

	paymentStatusFormatter(cell: Object, row: Object): Object {
		return 	<div>
					<select
						className="select-status-payment m-r-10"
						name="status"
						id="status"
						value={row.status}
						disabled={!this.props.userPrivilege.includes('EDIT_TRANSACTION_STATUS')}
						onChange={(e)=>this.handleUpdatePaymentStatus(e, row.id)}>
						<option value="1" >Fully Paid</option>
						<option value="2" >Outstanding</option>
					</select>
				</div>
	}

	handleChangePaymentMethod(e): void{
		const{
			transactionsAddFilterPageNumber,
			transactionsFetchData,
			transactionsAddFilterPaymentMethod,
		}=this.props

		const payment_method = e.target.value
		transactionsAddFilterPageNumber(1)
		.then(()=>{
			transactionsAddFilterPaymentMethod(payment_method)
			.then(()=>{
				transactionsFetchData(this.props.transactionsFilterData)
			})
		})

	}

	handleChangePaymentStatus(e): void{
		const{
			transactionsAddFilterPageNumber,
			transactionsFetchData,
			transactionsAddFilterPaymentStatus,
		}=this.props
		
		const payment_status = e.target.value
		transactionsAddFilterPageNumber(1)
		.then(()=>{
			transactionsAddFilterPaymentStatus(payment_status)
			.then(()=>{
				transactionsFetchData(this.props.transactionsFilterData)
			})
		})

	}

	

	handleUpdatePaymentStatus(e, transactionId): void{
		e.preventDefault()
		let paymentStatus = e.target.value
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			transactionsUpdatePaymentStatus,
			transactionsFetchData
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: `Update`,
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Update Payment`,
			text: `Are you sure to Update payment status?`,
			onConfirm: () => {
				transactionsUpdatePaymentStatus(transactionId, paymentStatus).then(()=>{
					transactionsFetchData(this.props.transactionsFilterData)
					globalAddSweetAlertData({
						show: true,
						title: 'Success!',
						text: `Success Update payment statys`,
						confirmButtonText: 'OK',
						confirmButtonColor: '#564FDB',
						showLoaderOnConfirm: true,
						onConfirm: () => {
							globalHideSweetAlert()
						}
					})
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleSelectRow(row){
		this.props.history.push(`/support/transactions/${row.id}`)
	}

	invoiceFormatter(cell: Object, row: Object): Object {
		let res = <span>{row.invoice_number}</span> 
		if(this.props.userPrivilege.includes('SHOW_TRANSACTION_DETAIL')){
			res = <a style={{cursor:'pointer'}} onClick={()=>this.handleSelectRow(row)}>{row.invoice_number}</a>
		}
		return res
							
	}

	handleSortChange(name, order){
		const {
			transactionsFetchData,
			transactionsAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		transactionsAddFilterSort(sort).then(()=>transactionsFetchData(this.props.transactionsFilterData))
	}

  	render() {
		const {
			transactions,
			transactionsFetchData,
			transactionsPagination,
			transactionsFilterData,
			transactionsAddFilterPageNumber,
			globalIndicatorData,
		} = this.props

		let reportPagination: ?Object
		
		if (!transactions.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={transactionsPagination}
					fetchData={transactionsFetchData}
					filterData={transactionsFilterData}
					addPageNumber={transactionsAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''

		const displayOptions: Array<Object> = [
			{value: '10', label: '10'},
			{value: '20', label: '20'},
			{value: '30', label: '30'}
		]

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		};

    	return (

			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">Quota Top Up</h5>
									</div>
									<div className="flex-container flex-between">
										<div>
											<select
												className="select-status-payment m-r-10"
												name="status"
												id="status"
												value={transactionsFilterData.get('payment_status')}
												onChange={(e)=>this.handleChangePaymentStatus(e)}
												style={{width: 'auto'}}
											>
												<option value="" disabled selected>Select payment status</option>
												<option value="1" >Fully Paid</option>
												<option value="2" >Outstanding</option>
											</select>
											<select
												className="select-status-payment"
												name="status"
												id="status"
												value={transactionsFilterData.get('payment_method')}
												onChange={(e)=>this.handleChangePaymentMethod(e)}
												style={{width: 'auto'}}
											>
												<option value="" disabled selected>Select payment method</option>
												<option value="transfer" >Transfer</option>
												<option value="cash" >Cash</option>
											</select>
										</div>

										<div className="flex">
											<div className="search-support-container horizontal-item--between m-r-16">
												<input className="search-support" placeholder="Search by date or company" onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
									</div>

	                				<div className="report-list-container form-custom">
										<BootstrapTable
											data={transactions.toJS()}
											striped={true}
											ref='table'
											options={options}
										>
											<TableHeaderColumn dataField="id" isKey={true} hidden>ID</TableHeaderColumn>
											<TableHeaderColumn dataField="invoice_number" dataFormat={this.invoiceFormatter} dataSort={false}>Invoice Number</TableHeaderColumn>
											<TableHeaderColumn dataField="transaction_date" dataSort={true}>Date (GMT+7)</TableHeaderColumn>
											<TableHeaderColumn dataField="company_name" dataSort={true}>Company</TableHeaderColumn>
											<TableHeaderColumn dataField="candidate_credit" dataSort={false}>Candidate Credits</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.paymentFormatter}>Payment</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.paymentStatusFormatter}>Status</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.documentFormatter}>Document</TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
	                				</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		globalIndicatorData: state.globalIndicatorData,
		transactions: state.transactions,
		transactionsPagination: state.transactionsPagination,
		transactionsFilterData: state.transactionsFilterData,
		userPrivilege: state.userPrivilege,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		transactionsClearFilterData: () => dispatch(transactionsClearFilterData()),
		transactionsFetchData: (filter_data) => dispatch(transactionsFetchData(filter_data)),
		transactionsAddFilterPageNumber: (page) => dispatch(transactionsAddFilterPageNumber(page)),
		transactionsAddFilterSort: (sort) => dispatch(transactionsAddFilterSort(sort)),
		transactionsAddFilterSearch: (fullname) => dispatch(transactionsAddFilterSearch(fullname)),
		transactionsAddFilterPaymentMethod: (payment_method) => dispatch(transactionsAddFilterPaymentMethod(payment_method)),
		transactionsAddFilterPaymentStatus: (payment_status) => dispatch(transactionsAddFilterPaymentStatus(payment_status)),
		transactionsAddFilterColPerPage: (per_page) => dispatch(transactionsAddFilterColPerPage(per_page)),
		transactionsUpdatePaymentStatus: (transactionId, status) => dispatch(transactionsUpdatePaymentStatus(transactionId, status)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList)
