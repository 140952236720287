import React, { Component } from 'react'

class RenderTextArea  extends Component {
	render() {
		const { input, id, label, placeholder, desc, meta: { touched, error }, disabled, defaultValue, style, rows, name, max} = this.props
		let rowsCount='5'
		if(rows!=undefined){
			rowsCount=rows
		}
		let maxLength = 1000
		if(max!=undefined){
			maxLength = max
		}
		return(
				<div className={`input-container ${touched && error && 'has-error'}`}>
					<div><label>{label}</label></div>
					<div>
						<textarea   {...input} disabled={disabled} className="input-textarea"  rows={rowsCount} style={style} placeholder={placeholder} id={id} maxLength={maxLength}></textarea>
						{touched && error && <div><span className="error js-err-message">{error}</span></div>}
					</div>
				</div>
		)
	}
}


export default RenderTextArea
