import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import { List, fromJS } from 'immutable'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import history from '../dependencies/history'
import {setIsFetched} from './global'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function getDropdownFeatures() {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/features/dropdown`,
			timeout: 20000,
			method: 'POST',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(dropdownFeaturesFetchDataSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function dropdownFeaturesFetchDataSuccess(features) {
	return {
		type: 'DROPDOWN_FEATURES_FETCH_DATA_SUCCESS',
		features
	}
}


export function userLevelsCreateData(data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/user-levels/store`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()
			dispatch(Notifications.success({
				title: 'Success',
				message: 'Feature successfully created.',
				position: 'bl'
			}))

			history.push(`/support/user-levels`)
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when creating Feature.',
					position: 'bl'
				}))
			}
		})
	}
}

export function userLevelsUpdateData(userId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/user-levels/${userId}/update`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Feature successfully updated.',
				position: 'bl'
			}))

		})
		.catch((error) => {
			NProgress.done()
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating Feature.',
				position: 'bl'
			}))
		})
	}
}

export function userLevelsGetDetail(id) {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/user-levels/${id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			dispatch(userLevelsGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data.',
				position: 'bl'
			}))
		})
	}
}

export function userLevelsGetDetailSuccess(user) {
	return {
		type: 'FEATURES_GET_DETAIL_SUCCESS',
		user
	}
}


export function featuresFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(featuresFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/features/filter`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const features = response.data.data.data
			const pagination = omit(response.data.data, 'data')
			dispatch(featuresFetchPaginationSuccess(pagination))
			dispatch(featuresFetchDataSuccess(features))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(featuresFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}


export function featuresFetchPaginationSuccess(pagination) {
	return {
		type: 'FEATURES_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function featuresFetchDataSuccess(features) {
	return {
		type: 'FEATURES_FETCH_DATA_SUCCESS',
		features
	}
}

export function featuresAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(featuresAddFilterPageNumberSuccess(page)))
		})
	}
}

export function featuresAddFilterPageNumberSuccess(page) {
	return {
		type: 'FEATURES_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function featuresAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(featuresAddFilterSortSuccess(sort)))
		})
	}
}

export function featuresAddFilterSortSuccess(sort) {
	return {
		type: 'FEATURES_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function featuresAddFilterName(fullname) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(featuresAddFilterNameSuccess(fullname)))
		})
	}
}

export function featuresAddFilterNameSuccess(name) {
	return {
		type: 'FEATURES_ADD_FILTER_NAME_SUCCESS',
		name
	}
}

export function featuresAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(featuresAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function featuresAddFilterColPerPageSuccess(per_page){
	return{
		type:'FEATURES_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}


export function featuresClearFilterDataSuccess() {
	return {
		type: 'FEATURES_CLEAR_FILTER_DATA_SUCCESS'
	}
}

export function featuresClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(featuresClearFilterDataSuccess()))
		})
	}
}