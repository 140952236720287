// @flow

import { put, select, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import {
	change,
	startAsyncValidation,
	stopAsyncValidation
} from 'redux-form'

import {
	usersSaveDataApi,
	usersCheckEmailApi,
	usersCheckEmailApiSupport,
	usersCheckGetEmailApiSupport
} from '../apis/users'

import { getForm } from '../selectors'
import configurations from '../misc/configurations'

export function* usersValidateEmailOnUserRegisterWizardForm({email}: Object): Generator<*, *, *> {
	const errors: Object = {email: '<i style="font-size: 12px">This email is already registered. Would you like to <a href="https://app.astrnt.co/auth/login" style="color: #a94442; text-decoration: underline">login?</a></i>'}

	try {
		yield put(change('UserRegisterWizardForm', 'email', email, true, true))

		if (configurations.emailRegex.test(email)) {
			yield put(startAsyncValidation('UserRegisterWizardForm'))

			yield delay(1000)

			const response: Object = yield call(
				usersCheckEmailApi,
				email
			)

			if (response.status === 200) {
				if (response.data.message === 'new_email') {
					yield put(stopAsyncValidation('UserRegisterWizardForm', {}))
				} else {
					yield put(stopAsyncValidation('UserRegisterWizardForm', errors))
				}
			} else if (response.status === 422) {
				yield put(stopAsyncValidation('UserRegisterWizardForm', errors))
			}
		}
	} catch (error) {
		yield put(stopAsyncValidation('UserRegisterWizardForm', errors))
	}
}



export function* usersSaveData({formName}: Object): Generator<*, *, *> {
	try {
		yield put(startAsyncValidation('UserRegisterWizardForm'))

		const form: Object = yield select(getForm)
		const response: Object = yield call(
			usersSaveDataApi,
			form[formName].values
		)

		if (response.status >= 200 && response.status < 300) {
			yield put(stopAsyncValidation('UserRegisterWizardForm', {}))

			yield put({
				type: 'USERS_ADD_NAVIGATION_PAGE_SUCCESS',
				page: 3
			})
		} else {
			throw response
		}
	} catch (error) {
		yield put(stopAsyncValidation(formName, {}))
	}
}

export function* usersValidateEmailOnCreateUser({email, formName}: Object): Generator<*, *, *> {
	const errors: Object = {email: 'This email is already registered'}

	try {
		yield put(change(formName, 'email', email, true, true))

		if (configurations.emailRegex.test(email)) {
			yield put(startAsyncValidation(formName))

			yield delay(1000)

			const response: Object = yield call(
				usersCheckEmailApiSupport,
				email
			)

			if (response.status === 200) {
				yield put(stopAsyncValidation(formName, {}))
			} else if (response.status === 422) {
				yield put(stopAsyncValidation(formName, errors))
			}
		}
	} catch (error) {
		yield put(stopAsyncValidation(formName, errors))
	}
}

export function* internalUsersValidateEmailOnCreateUser({email}: Object): Generator<*, *, *> {
	const errors: Object = {email: 'This email is not registered, please use another email'}

	try {
		yield put(change('UserInternalForm', 'email', email, true, true))
		yield put(change('UserInternalForm', 'name', '', false, false))
		yield put(change('UserInternalForm', 'admin_userlevel_id', '', false, false))
		if (configurations.emailRegex.test(email)) {
			yield put(startAsyncValidation('UserInternalForm'))

			yield delay(1000)
			let params = {
				email:email,
				internal_user: true,
				get:true
			}
			
			const response: Object = yield call(
				usersCheckGetEmailApiSupport,
				params
			)

			if (response.status === 200) {
				let user=response.data.user
				if(response.data.status=='success'){
					yield put(change('UserInternalForm', 'name', user.name, true, true))
					yield put(stopAsyncValidation('UserInternalForm', {}))
				}else{
					yield put(change('UserInternalForm', 'name', '', false, false))
					yield put(change('UserInternalForm', 'admin_userlevel_id', '', false, false))
					yield put(stopAsyncValidation('UserInternalForm', {email:response.data.message}))
				}
			} else {
				yield put(change('UserInternalForm', 'name', '', false, false))
				yield put(change('UserInternalForm', 'admin_userlevel_id', '', false, false))
				yield put(stopAsyncValidation('UserInternalForm', errors))
			}
		}
	} catch (error) {
		yield put(change('UserInternalForm', 'name', '', false, false))
		yield put(change('UserInternalForm', 'admin_userlevel_id', '', false, false))
		yield put(stopAsyncValidation('UserInternalForm', errors))
	}
}
