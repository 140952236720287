import { List, fromJS } from 'immutable'

export function languages(state = List([]), action) {
	switch(action.type) {
		case 'LANGUAGES_FETCH_DATA_SUCCESS':
			return fromJS(action.languages)
		default:
			return state
	}
}
