import { Map, fromJS, List } from 'immutable'

const initAjaxRequestData = Map({
	jobsGetDetail: true,
	jobsFetchData: true,
	jobsCloneData: true,
	jobsCreateData: true,
	jobsUpdateData: true,
	candidatesFetchData: true,
	candidatesUpdateStatus: true,
	invitesFetchData: true
})

const initSweetAlertData = Map({
	show: false,
	title: '',
	text: '',
	onConfirm: () => {}
})

const initIndicatorData = Map({
	text: '',
	type: 'warning',
	isShow: false,
	className: ''
})

const initUiData = Map({
	welcome_video_link: '',
	is_onboarding_invitation: false,
	is_onboarding_manage_candidate: false,
	is_watched_video: false,
	is_display_main_layout: false,
	is_current_company_active: false,
	display_menu:1,
	display_advance_filter:'',
	display_color_picker:''
})

export function globalClass(state = '', action) {
	switch (action.type) {
		case 'GLOBAL_ADD_CLASS_SUCCESS':
			return action.classString
		default:
			return state
	}
}

export function globalActiveMenu(state = Map({}), action) {
	switch (action.type) {
		case 'GLOBAL_ACTIVE_MENU_JOBS_SUCCESS':
			return Map({
				menuJobs: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_CANDIDATES_ALL_SUCCESS':
			return Map({
				menuCandidatesAll: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_QUESTION_LIBRARY_SUCCESS':
			return Map({
				menuQuestionLibrary: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_HIRING_TEAM_SUCCESS':
			return Map({
				menuHiringTeam: 'active',
				menuJobs: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_ANALYTICS_SUCCESS':
			return Map({
				menuAnalytics: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_USER_PROFILE_SUCCESS':
			return Map({
				menuUserProfile: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_COMPANY_EDIT_SUCCESS':
			return Map({
				menuCompanyEdit: 'active',
			})
		case 'GLOBAL_ACTIVE_MENU_CANDIDATES_SUCCESS':
			return Map({
				menuCandidates: 'active',
				menuJobs: 'active'
			})
		case 'GLOBAL_ACTIVE_MENU_JOB_DESCRIPTION_SUCCESS':
			return Map({
				menuJobDetails: 'active',
				menuJobs: 'active'
			})
		case 'GLOBAL_ACTIVE_MENU_INVITATIONS_HISTORY_SUCCESS':
			return Map({
				menuInvitationsHistory: 'active',
				menuJobs: 'active'
			})
		case 'GLOBAL_ACTIVE_MENU_REPORTS_SUCCESS':
			return Map({
				menuReports: 'active',
				menuJobs: 'active'
			})
		case 'GLOBAL_ACTIVE_MENU_TEAM_MEMBER_SUCCESS':
			return Map({
				menuTeamMember: 'active',
			})
		default:
			return state
	}
}

export function globalAjaxRequest(state = initAjaxRequestData, action) {
	switch(action.type) {
		case 'GLOBAL_CURRENT_AJAX_REQUEST':
			return state.set(action.request_method, false)
		default:
			return state
	}
}

export function globalSweetAlertData(state = initSweetAlertData, action) {
	switch(action.type) {
		case 'GLOBAL_ADD_SWEET_ALERT_DATA_SUCCESS':
			return Map(action.options)
		case 'GLOBAL_HIDE_SWEET_ALERT_SUCCESS':
			return state.set('show', false)
		default:
			return state
	}
}

export function globalIndicatorData(state = initIndicatorData, action) {
	switch(action.type) {
		case 'GLOBAL_ADD_INDICATOR_DATA_SUCCESS':
			return Map(action.data)
		case 'GLOBAL_HIDE_INDICATOR_SUCCESS':
			return state.set('isShow', false)
		default:
			return state
	}
}

export function globalUiData(state = initUiData, action) {
	switch (action.type) {
		case 'GLOBAL_ADD_UI_WELCOME_VIDEO_LINK_SUCCESS':
			return state.set('welcome_video_link', action.welcome_video_link)
		case 'GLOBAL_ADD_UI_IS_ONBOARDING_INVITATION_SUCCESS':
			return state.set('is_onboarding_invitation', action.is_onboarding_invitation)
		case 'GLOBAL_ADD_UI_IS_ONBOARDING_MANAGE_CANDIDATE_SUCCESS':
			return state.set('is_onboarding_manage_candidate', action.is_onboarding_manage_candidate)
		case 'GLOBAL_ADD_UI_IS_WATCHED_VIDEO_SUCCESS':
			return state.set('is_watched_video', action.is_watched_video)
		case 'GLOBAL_ADD_UI_IS_DISPLAY_MAIN_LAYOUT_SUCCESS':
			return state.set('is_display_main_layout', action.is_display_main_layout)
		case 'GLOBAL_ADD_UI_IS_CURRENT_COMPANY_ACTIVE_SUCCESS':
			return state.set('is_current_company_active', action.is_current_company_active)
		case 'GLOBAL_ADD_UI_IS_CURRENT_DISPLAY_SUCCESS':
			return state.set('display_menu', action.display_menu)
		case 'GLOBAL_ADD_UI_IS_DISPLAY_ADVANCE_FILTER':
			return state.set('display_advance_filter',action.display_advance_filter)
		case 'GLOBAL_ADD_UI_IS_DISPLAY_COLOR_PICKER':
			return state.set('display_color_picker',action.display_color_picker)
		default:
			return state
	}
}

export function isFirstLogin(state = false, action) {
	switch (action.type) {
		case 'IS_FIRST_LOGIN':
			return action.isFirstLogin
		default:
			return state
	}
}

export function isFetched(state = false, action) {
	switch (action.type) {
		case 'IS_FETCHED':
			return action.isFetched
		default:
			return state
	}
}

export function information(state = Map({}), action) {
	switch(action.type) {
		case 'INFORMATION_FETCH_DATA_SUCCESS':
			return fromJS(action.information)
		case 'INFORMATION_ADD_PLAN_REQUEST_DATA_SUCCESS':
			return state.set('plan_request',action.plan_request)
		default:
			return state
	}
}

export function accountManagers(state = List([]), action) {
	switch(action.type) {
		case 'ACCOUNT_MANAGERS_FETCH_DATA_SUCCESS':
			return fromJS(action.account_manager)
		default:
			return state
	}
}
