// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import validate from './validateUserLevel'
import RenderInput from '../partials/RenderInput'
import RenderSelect from '../partials/RenderSelect'
import RenderTextArea from '../partials/RenderTextArea'

import {
	userLevelCreateData,
	userLevelUpdateData,
	userLevelGetDetail,
	resetFormUserLevel
} from '../../actions/users'

import {
	getDropdownFeatures
} from '../../actions/features'

require('es6-promise').polyfill()

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	globalIndicatorData: Map<string, any>,
  	changeFieldValue: Function,
	handleSubmit: Function,
	pristine: boolean,
	submitting: boolean,
	userLevelCreateData: Function,
	userLevelUpdateData: Function,
	userLevelGetDetail: Function,
	getDropdownFeatures:Function,
	resetFormUserLevel:Function
};

type State = {
	isShown: boolean
};

class UserLevelForm extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selectedFeatureValue:[],
			selectedFeatureLabel:[],
			tempSelectedFeature:{}
		}

		bindAll(this, [
			'handleSaveData',
			'handleSelectFeature'
		])
	}

	componentDidMount() {
		const {
			userLevelId,
			userLevelGetDetail,
			getDropdownFeatures,
			resetFormUserLevel,
			changeFieldValue
		} = this.props

		getDropdownFeatures().then(()=>{
			if(userLevelId!=undefined){
				userLevelGetDetail(userLevelId).then((res)=>{
					let filterValue =  this.props.userLevel.get('feature_lists').toJS()
					let filterLabel =  this.props.userLevel.get('feature_lists').toJS()
					this.setState({
						selectedFeatureValue: filterValue,
						selectedFeatureLabel: filterLabel
					})
					changeFieldValue('feature_list',filterLabel.join(', '))
				})
			}else{
				resetFormUserLevel()
			}
		})
	}

	handleSelectFeature(event){
		const {
			selectedFeatureValue,
			selectedFeatureLabel
		} = this.state

		let label = event.target.options[event.target.selectedIndex].text
		let value = event.target.value
		let filterValue = selectedFeatureValue.filter(val => val!== value)
		let filterLabel = selectedFeatureLabel.filter(val => val!== label)
		filterValue.push(value)
		filterLabel.push(label)
		this.setState({
			tempSelectedFeature: {label:label, value: value},
			selectedFeatureValue: filterValue,
			selectedFeatureLabel: filterLabel
		})
		this.props.changeFieldValue('feature_list',filterLabel.join(', '))
	}

	handleSaveData(values) {
		const {
			userLevelCreateData,
			userLevelUpdateData,
			userLevelId
		} = this.props

		if(userLevelId==undefined){
			userLevelCreateData(values)
		}else{
			userLevelUpdateData(userLevelId,values)
		}

	}

  	render() {
		const {
			globalIndicatorData,
			handleSubmit,
    		pristine,
			submitting,
			userLevelId,
  			asyncValidating,
			dropdownFeatures,
			userPrivilege
		} = this.props
		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''

    	return (
			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">{userLevelId==undefined ? 'Create User Level':'Edit User Level'}</h5>
									</div>

	                				<div className="div-support-create-user-container">
	                					<div><Link to={{pathname: '/support/user-level', state: {prevPage: 'user-level-detail'}}} className="back-button"><i className="fa fa-angle-left"></i>Back</Link></div>
	                					<hr />
										<form onSubmit={handleSubmit(this.handleSaveData)} id="job-form_create_new_job" encType="multipart/form-data">
											<div>
												<Field
													name="name"
													type="text"
													component={RenderInput}
													label="Name"
													placeholder="Name"
													id="name"
												/>
											</div>
											<div>
												<Field
													name="feature_list"
													component={RenderTextArea}
													label="Feature List"
													placeholder="Feature List"
													id="feature_list"
													disabled={true}
													style={{width:'100%'}}
													rows={5}
												/>
											</div>
											<div>
												<Field
													name="feature"
													component={RenderSelect}
													label="Add New Feature to User Level"
													placeholder="User Level"
													id="feature"
													onChange={this.handleSelectFeature}
													dropdownOption={dropdownFeatures}
												/>
											</div>
											<div className="center m-t-36">
												{
													userPrivilege.includes('CREATE_USER_LEVEL') &&
													<button disabled={pristine || submitting || asyncValidating} className="submit-button">Submit</button>
												}
											</div>
										</form>
									</div>
	              				</div>
	            			</div>
	          			</div>
	        		</section>
	      		</div>
			</div>
    	)
  	}
}

UserLevelForm = reduxForm({
	form: 'UserLevelForm',
	validate: validate,
		initialValues: {
			name: '',
			feature_list:''
	},
	enableReinitialize: true
})(UserLevelForm)

const selector = formValueSelector('UserLevelForm')

const mapStateToProps = (state, ownProps) => {
	const user = state.userLevel
	const initialValues = {
		name: user.get('name'),
		feature_list:''
	}

	return {
		formValues: selector(
			state,
			'name',
			'feature_list',
		),
		initialValues,
		pathName: ownProps.location.pathname,
		globalIndicatorData: state.globalIndicatorData,
		userLevelId: ownProps.match.params.userLevelId,
		dropdownFeatures: state.dropdownFeatures,
		userLevel:state.userLevel,
		userPrivilege: state.userPrivilege
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userLevelCreateData: (userData) => dispatch(userLevelCreateData(userData)),
		userLevelUpdateData: (userLevelId,userData) => dispatch(userLevelUpdateData(userLevelId,userData)),
		userLevelGetDetail: (userLevelId) => dispatch(userLevelGetDetail(userLevelId)),
		getDropdownFeatures: () => dispatch(getDropdownFeatures()),
		resetFormUserLevel: () => dispatch(resetFormUserLevel()),
		changeFieldValue: (field, value) => dispatch(change('UserLevelForm', field, value, true, true)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelForm)
