// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../../components/partials/PaginationSupport'

import {
	usersInternalAddFilterPageNumber,
	usersInternalAddFilterSort,
	usersInternalAddFilterColPerPage,
	usersInternalAddFilterSelectedIds,
	internalUsersFetchData,
	internalUsersDelete,
	usersInternalClearFilterData,
	usersInternalAddFilterSearch
} from '../../actions/users'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'


import TableLoader from '../partials/TableLoader'

require('es6-promise').polyfill()

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	usersInternalPagination: Map<string, any>,
	usersInternalFilterData: Map<string, any>,
	usersInternalAddFilterPageNumber: Function,
	usersInternalAddFilterSort: Function,
	globalIndicatorData: Map<string, any>,
	usersInternalAddFilterColPerPage: Function,
	usersInternalAddFilterSelectedIds: Function,
	internalUsersFetchData: Function,
	internalUsersDelete: Function,
	usersInternalClearFilterData: Function,
	usersInternalAddFilterSearch: Function,
};

type State = {
	isShown: boolean
};

class UserInternalList extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selected_ids:[]
		}

		bindAll(this, [
			'actionFormatter',
			'handleChangePageSize',
			'handleSearchByName',
			'handleDeleteUser',
			'handleSortChange',
			'handleEditClick'
		])
	}

	componentDidMount() {
		const {
			internalUsersFetchData,
			usersInternalClearFilterData
		} = this.props

		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'internal-user-detail'){
			internalUsersFetchData(this.props.usersInternalFilterData)
		}else{
			usersInternalClearFilterData()
			.then(()=>{
				internalUsersFetchData(this.props.usersInternalFilterData)
			})
		}
	}

	handleChangePageSize(e): void{
		const{
			usersInternalAddFilterPageNumber,
			usersInternalAddFilterColPerPage,
			internalUsersFetchData
		}=this.props
		const countFilter = e.target.value
		usersInternalAddFilterPageNumber(1)
		.then(()=>{
			usersInternalAddFilterColPerPage(countFilter)
			.then(()=>{
				internalUsersFetchData(this.props.usersInternalFilterData)
			})
		})

	}

	handleSearchByName(event){
		const{
			usersInternalAddFilterPageNumber,
			internalUsersFetchData,
			usersInternalAddFilterSearch
		}=this.props

		let keywords=event.target.value
		if (event.charCode === 13) {
			usersInternalAddFilterPageNumber(1).then(()=>{
				usersInternalAddFilterSearch(keywords).then(()=>{
					internalUsersFetchData(this.props.usersInternalFilterData)
				})
			})
    	}
	}

	handleDeleteUser(e, userId){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			internalUsersFetchData,
			internalUsersDelete
		} = this.props
		let userIds = userId
		if(typeof userIds == 'number'){
			userIds = [userId]
		}
		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Are you sure?`,
			text: "You will delete the user and you're not able to restore it.",
			onConfirm: () => {
				internalUsersDelete(userIds).then(()=>{
					internalUsersFetchData(this.props.usersInternalFilterData)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	actionFormatter(cell: Object, row: Object): Object {

		const {
			userPrivilege,
			userAuth
		} = this.props
		let privilege = userPrivilege.filter(item => ['UPDATE_INTERNAL_USER','DELETE_INTERNAL_USER'].includes(item))
		let itSelf=userAuth.get('id')==row.id ? true : false
		let adminRole=userAuth.get('role')!=undefined ? userAuth.get('role').get('id'):0
		if(row.email=='nigel@astrnt.co' || [3,4].includes(adminRole)|| itSelf || privilege.length<1){
			return null
		}

		if(adminRole==2 && ['Super Admin','Admin'].includes(row.user_level)){
			return null
		}
		
		
	  	return  <div className="dropdown">
					<a style={{cursor:'pointer'}} data-toggle="dropdown"><i className="fa fa-ellipsis-h" style={{color: 'black',fontSize: '14px'}}></i></a>
					<ul className="dropdown-menu pull-right">
						{
							userPrivilege.includes('UPDATE_INTERNAL_USER') &&
							<li><a onClick={(e)=>this.handleEditClick(row.id)} style={{color: '#404040',padding: '10px 15px'}}>Edit</a></li>
						}
						{
							userPrivilege.includes('DELETE_INTERNAL_USER') &&
							<li><a href="" onClick={(e)=>this.handleDeleteUser(e,row.id)} style={{color: '#ED2453',padding: '10px 15px'}}>Delete</a></li>
						}
					</ul>
				</div>
	}

	handleSortChange(name, order){
		const {
			internalUsersFetchData,
			usersInternalAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		usersInternalAddFilterSort(sort).then(()=>internalUsersFetchData(this.props.usersInternalFilterData))

	}

	handleEditClick(userId){
		this.props.history.push(`/support/internal-user/${userId}/edit`);
	}

  	render() {
		const {
			usersInternalPagination,
			usersInternalFilterData,
			usersInternalAddFilterPageNumber,
			globalIndicatorData,
			internalUsersFetchData,
			userPrivilege,
			userAuth,
			usersInternal
		} = this.props

		let reportPagination: ?Object

		if (!usersInternal.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={usersInternalPagination}
					fetchData={internalUsersFetchData}
					filterData={usersInternalFilterData}
					addPageNumber={usersInternalAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''

		const displayOptions: Array<Object> = [
			{value: '10', label: '10'},
			{value: '20', label: '20'},
			{value: '30', label: '30'}
		]

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		};
		let adminRole=userAuth.get('role')!=undefined ? userAuth.get('role').get('id'):0

    	return (

			<div className={isIndicatorClass}>
				<div className="div-support-container">
					<section className="wrapper">
						<div className="main-content">
							<div className="container-fluid">
								<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">Internal User</h5>
										<div>
											{
												(userPrivilege.includes('CREATE_INTERNAL_USER') && [1,2].includes(adminRole)) &&
												<Link to={{pathname: '/support/internal-user/new', state: {prevPage: 'internal-user-detail'}}} className="btn-support">Add New User</Link>
											}
										</div>
									</div>
									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="status"
												id="status"
												value={usersInternalFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
											</select>
											Entries
										</div>

										<div className="flex">
											<div className="search-support-container horizontal-item--between m-r-16">
												<input className="search-support" placeholder="Search by name or email" defaultValue={this.props.usersInternalFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
									</div>

									<div className="report-list-container form-custom">
										<BootstrapTable
											data={usersInternal.toJS()}
											striped={true}
											ref='table'
											options = {options}
										>
											<TableHeaderColumn dataField="id" isKey={true} dataSort={true} >ID</TableHeaderColumn>
											<TableHeaderColumn dataField="name" dataSort={true}>Name</TableHeaderColumn>
											<TableHeaderColumn dataField="email">Email</TableHeaderColumn>
											<TableHeaderColumn dataField="user_level">Admin Level</TableHeaderColumn>
											<TableHeaderColumn width='50' dataFormat={this.actionFormatter} dataAlign='center' columnClassName="dropdown-user-support"></TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
		globalIndicatorData: state.globalIndicatorData,
		usersInternalPagination: state.usersInternalPagination,
		usersInternalFilterData: state.usersInternalFilterData,
		userPrivilege: state.userPrivilege,
		userAuth: state.userAuth,
		usersInternal: state.usersInternal,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		usersInternalAddFilterPageNumber: (page) => dispatch(usersInternalAddFilterPageNumber(page)),
		usersInternalAddFilterSort: (sort) => dispatch(usersInternalAddFilterSort(sort)),
		usersInternalAddFilterColPerPage: (per_page) => dispatch(usersInternalAddFilterColPerPage(per_page)),
		usersInternalAddFilterSelectedIds: (ids) => dispatch(usersInternalAddFilterSelectedIds(ids)),
		internalUsersFetchData: (filter_data) => dispatch(internalUsersFetchData(filter_data)),
		internalUsersDelete: (userIds) => dispatch(internalUsersDelete(userIds)),
		usersInternalClearFilterData: () => dispatch(usersInternalClearFilterData()),
		usersInternalAddFilterSearch: (keywords) => dispatch(usersInternalAddFilterSearch(keywords)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInternalList)
