import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import history from '../dependencies/history'
import {setIsFetched} from './global'
import { Link } from  'react-router-dom'

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function usersFetchDataSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_SUCCESS',
		users
	}
}


export function voucherSaveData(data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/vouchers/save`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()
			dispatch(Notifications.success({
				title: 'Success',
				message: 'User Level successfully created.',
				position: 'bl'
			}))

			history.push({ 
				pathname: `/support/voucher`,
				state: {prevPage: 'voucher-detail'}
			})
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when creating user level.',
					position: 'bl'
				}))
			}
		})
	}
}

export function voucherUpdateData(voucherId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/support-dashboard/vouchers/${voucherId}/update`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User level successfully updated.',
				position: 'bl'
			}))

			history.push({ 
				pathname: `/support/voucher`,
				state: {prevPage: 'voucher-detail'}
			})
		})
		.catch((error) => {
			NProgress.done()
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating user level.',
				position: 'bl'
			}))
		})
	}
}

export function voucherGetDetail(id) {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/vouchers/${id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			dispatch(voucherGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user level.',
				position: 'bl'
			}))

			
		})
	}
}

export function resetFormVoucher() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(voucherGetDetailSuccess({})))
		})
	}
}

export function voucherGetDetailSuccess(voucher) {
	return {
		type: 'VOUCHER_GET_DETAIL_SUCCESS',
		voucher
	}
}

export function voucherCreateData() {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/vouchers/create`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			console.log('voucher',response.data.data);
			dispatch(voucherGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user level.',
				position: 'bl'
			}))

			
		})
	}
}

export function vouchersFetchDataSuccess(vouchers) {
	return {
		type: 'VOUCHERS_FETCH_DATA_SUCCESS',
		vouchers
	}
}

export function vouchersFetchIdsSuccess(ids) {
	return {
		type: 'VOUCHERS_FETCH_IDS_SUCCESS',
		ids
	}
}

export function vouchersFetchPaginationSuccess(pagination) {
	return {
		type: 'VOUCHERS_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function vouchersClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersClearFilterDataSuccess()))
		})
	}
}

export function vouchersClearFilterDataSuccess() {
	return {
		type: 'VOUCHERS_CLEAR_FILTER_DATA_SUCCESS'
	}
}


export function vouchersAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersAddFilterPageNumberSuccess(page)))
		})
	}
}

export function vouchersAddFilterPageNumberSuccess(page) {
	return {
		type: 'VOUCHERS_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function vouchersAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersAddFilterSearchSuccess(search)))
		})
	}
}

export function vouchersAddFilterSearchSuccess(search) {
	return {
		type: 'VOUCHERS_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}


export function vouchersAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersAddFilterSortSuccess(sort)))
		})
	}
}

export function vouchersAddFilterSortSuccess(sort) {
	return {
		type: 'VOUCHERS_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function vouchersAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function vouchersAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'VOUCHERS_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function vouchersAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function vouchersAddFilterColPerPageSuccess(per_page){
	return{
		type:'VOUCHERS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}


export function vouchersFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(vouchersFetchDataSuccess([]))
		dispatch(setIsFetched(false))
		let filter = filterData.toJS();
		if(filter.category=='email'){
			let emails= []
			filter.category_values.map(email=>{
				emails.push(email.value)
			})
			filter.category_values = emails.toString();
		}

		if(filter.product_values=='all'){
			filter.product_values = ''
		}

		if(filter.category_values=='all'){
			filter.category_values = ''
		}

		return axios({
			url:`/support-dashboard/vouchers/filter`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filter
		})
		.then((response) => {
			const vouchersIds = response.data.data.ids
			const vouchers = response.data.data.data
			const pagination = omit(response.data.data, 'data')

			dispatch(vouchersFetchIdsSuccess(vouchersIds))
			dispatch(vouchersFetchPaginationSuccess(pagination))
			dispatch(vouchersFetchDataSuccess(vouchers))
			dispatch(setIsFetched(true))
			
		})
		.catch((error) => {
			dispatch(vouchersFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function vouchersUsageFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(vouchersFetchDataSuccess([]))
		dispatch(setIsFetched(false))
		let filter = filterData.toJS();
		if(filter.category=='email'){
			let emails= []
			filter.category_values.map(email=>{
				emails.push(email.value)
			})
			filter.category_values = emails.toString();
		}

		if(filter.product_values=='all'){
			filter.product_values = ''
		}

		if(filter.category_values=='all'){
			filter.category_values = ''
		}
		
		return axios({
			url:`/support-dashboard/vouchers/filter/usage`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filter
		})
		.then((response) => {
			const vouchersIds = response.data.data.ids
			const vouchers = response.data.data.data
			const pagination = omit(response.data.data, 'data')

			dispatch(vouchersFetchIdsSuccess(vouchersIds))
			dispatch(vouchersFetchPaginationSuccess(pagination))
			dispatch(vouchersFetchDataSuccess(vouchers))
			dispatch(setIsFetched(true))
			
		})
		.catch((error) => {
			dispatch(vouchersFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

// Support Dashboard Delete Voucher
export function vouchersDelete(voucherIds) {
	return (dispatch) => {
		NProgress.start()
		
		return axios({
			url:`/support-dashboard/vouchers/${Array.isArray(voucherIds)?voucherIds[0]:voucherIds}/delete`,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {ids:voucherIds}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'voucher successfully deleted.',
				position: 'bl'
			}))

			dispatch(vouchersAddFilterSelectedIds([]))
			// dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting voucher.',
			  position: 'bl'
			}))

			
		})
	}
}

export function voucherGetCertificates(params) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/vouchers/certificates`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(voucherGetCertificatesSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user level.',
				position: 'bl'
			}))

		})
	}
}

export function vouchersDeleteBulk(voucherIds) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url:`/support-dashboard/vouchers/delete`,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {ids:voucherIds}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'voucher successfully deleted.',
				position: 'bl'
			}))

			dispatch(vouchersAddFilterSelectedIds([]))
			// dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting voucher.',
			  position: 'bl'
			}))

			
		})
	}
}


export function voucherGetCertificatesSuccess(certificates) {
	return {
		type: 'VOUCHER_GET_CERTIFICATES_SUCCESS',
		certificates
	}
}

export function validateVoucherCode(code, formName='VoucherForm') {
	return {
		type: 'VALIDATE_VOUCHER_CODE',
		code,
		formName
	}
}





//FILTER
export function vouchersAddFilterPlatformSuccess(platform){
	return{
		type:'VOUCHERS_ADD_FILTER_PLATFORM_SUCCESS',
		platform
	}
}

export function vouchersAddFilterPlatform(platform){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterPlatformSuccess(platform)))
		})
	}
}

export function vouchersAddFilterProductSuccess(product){
	return{
		type:'VOUCHERS_ADD_FILTER_PRODUCT_SUCCESS',
		product
	}
}

export function vouchersAddFilterProduct(product){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterProductSuccess(product)))
		})
	}
}

export function vouchersAddFilterProductValuesSuccess(product_values){
	return{
		type:'VOUCHERS_ADD_FILTER_PRODUCT_VALUES_SUCCESS',
		product_values
	}
}

export function vouchersAddFilterProductValues(product_values){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterProductValuesSuccess(product_values)))
		})
	}
}

export function vouchersAddFilterCategorySuccess(category){
	return{
		type:'VOUCHERS_ADD_FILTER_CATEGORY_SUCCESS',
		category
	}
}

export function vouchersAddFilterCategory(category){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterCategorySuccess(category)))
		})
	}
}

export function vouchersAddFilterCategoryValuesSuccess(category_values){
	return{
		type:'VOUCHERS_ADD_FILTER_CATEGORY_VALUES_SUCCESS',
		category_values
	}
}

export function vouchersAddFilterCategoryValues(category_values){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterCategoryValuesSuccess(category_values)))
		})
	}
}

export function vouchersAddFilterDiscountTypeSuccess(discount_type){
	return{
		type:'VOUCHERS_ADD_FILTER_DISCOUNT_TYPE_SUCCESS',
		discount_type
	}
}

export function vouchersAddFilterDiscountType(discount_type){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterDiscountTypeSuccess(discount_type)))
		})
	}
}

export function vouchersAddFilterDiscountValueSuccess(discount_value){
	return{
		type:'VOUCHERS_ADD_FILTER_DISCOUNT_VALUE_SUCCESS',
		discount_value
	}
}

export function vouchersAddFilterDiscountValue(discount_value){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterDiscountValueSuccess(discount_value)))
		})
	}
}

export function vouchersAddFilterCurrencySuccess(currency){
	return{
		type:'VOUCHERS_ADD_FILTER_CURRENCY_SUCCESS',
		currency
	}
}

export function vouchersAddFilterCurrency(currency){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterCurrencySuccess(currency)))
		})
	}
}

export function vouchersAddFilterMaxUserSuccess(max_user){
	return{
		type:'VOUCHERS_ADD_FILTER_MAX_USER_SUCCESS',
		max_user
	}
}

export function vouchersAddFilterMaxUser(max_user){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterMaxUserSuccess(max_user)))
		})
	}
}

export function vouchersAddFilterValidFromSuccess(from){
	return{
		type:'VOUCHERS_ADD_FILTER_VALID_FROM_SUCCESS',
		valid_from : from
	}
}

export function vouchersAddFilterValidFrom(from){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterValidFromSuccess(from)))
		})
	}
}

export function vouchersAddFilterValidToSuccess(to){
	return{
		type:'VOUCHERS_ADD_FILTER_VALID_TO_SUCCESS',
		valid_to:to
	}
}

export function vouchersAddFilterValidTo(to){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterValidToSuccess(to)))
		})
	}
}

export function vouchersAddFilterStatusSuccess(status){
	return{
		type:'VOUCHERS_ADD_FILTER_STATUS_SUCCESS',
		status
	}
}

export function vouchersAddFilterStatus(status){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(vouchersAddFilterStatusSuccess(status)))
		})
	}
}

export function vouchersAddFilterFrom(from) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersAddFilterFromSuccess(from)))
		})
	}
}

export function vouchersAddFilterFromSuccess(from) {
	return {
		type: 'VOUCHERS_ADD_FILTER_FROM_SUCCESS',
		from
	}
}

export function vouchersAddFilterTo(to) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(vouchersAddFilterToSuccess(to)))
		})
	}
}

export function vouchersAddFilterToSuccess(to) {
	return {
		type: 'VOUCHERS_ADD_FILTER_TO_SUCCESS',
		to
	}
}
