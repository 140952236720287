// @flow

import React from 'react'
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { List, Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../../components/partials/PaginationSupport'
import ModalAdvancedFilter from '../../components/partials/ModalAdvancedFilter'
import ModalComposeEmail from '../../components/partials/ModalComposeEmail'
import Notifications from 'react-notification-system-redux'
import $ from 'jquery'
import {
	usersDelete,
	usersFetchData,
	usersGetDetailTempSuccess,
	usersAddFilterPageNumber,
	usersAddFilterSort,
	usersAddFilterFullname,
	usersAddFilterSearch,
	usersClearFormDataSuccess,
	usersAddFilterColPerPage,
	usersAddFilterSelectedIds,
	usersActivate,
	resetUserPassword,
	usersClearFilterData,
	usersFetchReceiver,
	usersFetchReceiverData,
} from '../../actions/users'

import {
	companiesGetDetail
} from '../../actions/companies'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
	globalActiveMenuTeamMember,
	globalActiveMenuHiringTeam,
} from '../../actions/global'

import axios from "../../dependencies/_axios";
import TableLoader from '../partials/TableLoader'
import initializers from '../../dependencies/initializers'

require('es6-promise').polyfill()

type Props = {
	pathName: string,
	jobId: string,
	featureListCompany: Map<string, any>,
	companyId: string,
	company: Map<string, any>,
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	globalActiveMenuHiringTeam: Function,
	globalActiveMenuTeamMember: Function,
	usersGetDetailTempSuccess: Function,
	usersDelete: Function,
	users: List<Map<string, any>>,
	user: Map<string, any>,
	userTemp: Map<string, any>,
	usersPagination: Map<string, any>,
	usersFilterData: Map<string, any>,
	usersFetchData: Function,
	usersAddFilterPageNumber: Function,
	usersAddFilterSort: Function,
	usersAddFilterFullname: Function,
	usersAddFilterSearch: Function,
	usersClearFormDataSuccess: Function,
	usersClearFilterData: Function,
	globalIndicatorData: Map<string, any>,
	usersAddFilterColPerPage: Function,
	usersAddFilterSelectedIds: Function,
	usersActivate: Function,
	resetUserPassword: Function,
	companiesGetDetail: Function,
	showErrorNotifications: Function,
	usersFetchReceiver: Function,
	usersFetchReceiverData: Function,
};

type State = {
	isShown: boolean
};

class UserList extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selected_ids:[],
			receiver:[],
			isDownloading: false,
			isFetched: false,
		}

		bindAll(this, [
			'handleSelectionActive',
			'handleSelectionDisable',
			'handleSelection',
			'actionFormatter',
			'statusFormatter',
			'handleChangePageSize',
			'handleSearchByName',
			'handleSelectRow',
			'handleSelectAll',
			'handleComposeEmail',
			'handleGetSelectedData',
			'handleDownloadUserData',
			'handleDeleteUser',
			'handleActivateUser',
			'handleResetPasswordUser',
			'handleComposeEmailUser',
			'clearEmailReceiver',
			'handleVisitDashboard',
			'handleSortChange',
			'handleEditClick'
		])
	}

	componentDidMount() {
		const {
			usersFetchData,
			usersClearFilterData
		} = this.props
		
		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'user-detail'){
			usersFetchData(this.props.usersFilterData)
		}else{
			usersClearFilterData()
			.then(()=>{
				usersFetchData(this.props.usersFilterData)
			})
		}
		
	}

	handleSelectionActive(e: Object): void {
		e.preventDefault()

		this.setState({isShown: true})
	}

	handleSelectionDisable(e: Object): void {
		e.preventDefault()

		this.setState({isShown: false})
	}

	handleSelection(): Object {
		if (this.state.isShown) {
			return { display: 'block' }
		} else {
			return { display: 'none' }
		}
	}

	handleDeleteUser(e, userId){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			usersDelete,
			usersFetchData
		} = this.props
		let userIds = userId
		if(typeof userIds == 'number'){
			userIds = [userId]
		}
		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Are you sure?`,
			text: "You will delete the user and you're not able to restore it.",
			onConfirm: () => {
				usersDelete(userIds).then(()=>{
					usersFetchData(this.props.usersFilterData)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleResetPasswordUser(e, userId, companyId){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			resetUserPassword,
			usersFetchData
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Reset',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Reset User Password?`,
			text: 'This account will receive an email to reset the password automatically by system after you choose Reset button below.',
			onConfirm: () => {
				resetUserPassword(userId, companyId).then(()=>{
					usersFetchData(this.props.usersFilterData)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleActivateUser(e, userId, isActive){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			usersActivate,
			usersFetchData
		} = this.props

		let labelStatus = isActive==1 ? 'Deactivate' : 'Activate'
		let is_active = isActive == 1 ? 0 : 1

		let userIds = userId
		if(typeof userIds == 'number'){
			userIds = [userId]
		}

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: `${labelStatus}`,
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `${labelStatus} User`,
			text: `Are you sure to ${labelStatus.toLowerCase()} this user?`,
			onConfirm: () => {
				usersActivate(userIds, is_active).then(()=>{
				 	usersFetchData(this.props.usersFilterData)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleChangePageSize(e): void{
		const{
			usersAddFilterPageNumber,
			usersFetchData,
			usersAddFilterColPerPage,
		}=this.props
		const countFilter = e.target.value
		usersAddFilterPageNumber(1)
		.then(()=>{
			usersAddFilterColPerPage(countFilter)
			.then(()=>{
				usersFetchData(this.props.usersFilterData)
			})
		})

	}

	handleSearchByName(event){
		const{
			usersAddFilterPageNumber,
			usersFetchData,
			usersAddFilterSearch
		}=this.props

		let keywords=event.target.value
		if (event.charCode === 13) {
			usersAddFilterPageNumber(1).then(()=>{
				usersAddFilterSearch(keywords).then(()=>{
					usersFetchData(this.props.usersFilterData)
				})
			})
		}
	}

	actionFormatter(cell: Object, row: Object): Object {
		const {
			userPrivilege, 
			userAuth
		} = this.props

		let itSelf=userAuth.get('id')==row.id ? true : false
		let myPrivilege=['UPDATE_USER','ACTIVATE_USER','RESET_USERS_PASSWORD','VISIT_USERS_DASHBOARD','SEND_EMAIL_TO_USERS','DELETE_USERS']
		let res = userPrivilege.filter(item => myPrivilege.includes(item));
		
		if(itSelf || res.length<1){
			return null
		}

		let impersonateUrl = `${initializers.api_host}/support-dashboard/user/${row.id}/${row.company_id}/impersonate`
	  	return <div className="dropdown">
					<a style={{cursor:'pointer'}} data-toggle="dropdown"><i className="fa fa-ellipsis-h" style={{color: 'black',fontSize: '14px'}}></i></a> 
					<ul className="dropdown-menu pull-right">
						{
							(userPrivilege.includes('UPDATE_USER') && row.admin_level != 1) &&
							<li><a onClick={(e)=>this.handleEditClick(row.id)} style={{color: '#404040',padding: '10px 15px'}}>Edit</a></li>
						}
						{
							userPrivilege.includes('ACTIVATE_USERS') &&
							<li><a href="" onClick={(e)=>this.handleActivateUser(e,row.id, row.is_active)} style={{color: '#404040',padding: '10px 15px'}}>{row.is_active == 1 ? 'Deactivate' : 'Activate'}</a></li>
						}
						{
							userPrivilege.includes('RESET_USERS_PASSWORD') &&
							<li><a href="" onClick={(e)=>this.handleResetPasswordUser(e, row.id, row.company_id)} style={{color: '#404040',padding: '10px 15px'}}>Reset Password</a></li>
						}
						{
							userPrivilege.includes('VISIT_USERS_DASHBOARD') &&
							<li><a href="" onClick={(e)=>this.handleVisitDashboard(e, impersonateUrl)} style={{color: '#404040',padding: '10px 15px'}}>Visit Dashboard</a></li>
						}
						{
							userPrivilege.includes('SEND_EMAIL_TO_USERS') &&
							<li><a href="" onClick={(e)=>this.handleComposeEmailUser(e, row.id)} style={{color: '#404040',padding: '10px 15px'}}>Compose Email</a></li>
						}
						{
							(userPrivilege.includes('DELETE_USERS') && row.admin_level != 1) &&
							<li><a href="" onClick={(e)=>this.handleDeleteUser(e,row.id)} style={{color: '#ED2453',padding: '10px 15px'}}>Delete</a></li>
						}
					</ul>
	  			</div>
	}

	statusFormatter(cell: Object, row: Object): Object {
		let colorText='text-green'
		let status = 'Active'
		if(row.is_active==0){
			colorText='text-red'
			status='Inactive'
		}
		return <span className={colorText}>{status}</span>
	}

	handleSelectRow(row, isSelected){
		const {
			usersFilterData,
			usersAddFilterSelectedIds
		} = this.props

		let ids=usersFilterData.get('selected_ids').toJS()
		if(isSelected){
			ids.push(row.id);
		}else{
			ids=ids.filter(id => id !== row.id);
		}

		usersAddFilterSelectedIds(ids)
	}

	handleSelectAll(isSelected, rows){
		const {
			usersFilterData,
			usersAddFilterSelectedIds
		} = this.props

		let ids=usersFilterData.get('selected_ids').toJS()
		const selectedIds = rows.map(({ id }) => id)
		if(isSelected){
			ids = ids.concat(selectedIds)
		}else{
			ids=ids.filter(id => !selectedIds.includes(id))
		}

		usersAddFilterSelectedIds(ids)
	}

	handleComposeEmail(e: Object){
		e.preventDefault()
		const {
			usersFilterData,
			usersFetchReceiver,
		} = this.props

		this.clearEmailReceiver()
		
		usersFetchReceiver(usersFilterData).then(()=>{
			this.setState({receiver:this.props.receivers.toJS()})
			$('#modalComposeEmail').modal('show')
		})
		
	}

	handleComposeEmailUser(e: Object, id){
		e.preventDefault()
		const {
			users,
		} = this.props
		this.clearEmailReceiver()
		let receiverFilter =users.toJS().find((user)=> user.id===id)
		this.setState({receiver:[{label:receiverFilter.email,value:receiverFilter.email}]})
		$('#modalComposeEmail').modal('show')
	}

	clearEmailReceiver(){
		this.props.usersFetchReceiverData([])
		this.setState({receiver:[]})
	}

	handleGetSelectedData(e: Object){
		e.preventDefault()

		$('#modalAdvancedFilter').modal('show')
	}

	handleDownloadUserData(e){
		e.preventDefault()
		const {
			showErrorNotifications,
			usersFilterData
		} = this.props
		this.setState({isDownloading:true})
		axios({
			url: `/support-dashboard/users/export`,
			method: 'POST',
			timeout: 60000,
			responseType: 'blob',
			crossDomain: true,
			data: usersFilterData.toJS()
		}).then((response) => {
			setTimeout(()=>{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${'users.xlsx'}`)
				document.body.appendChild(link);
				link.click()
				this.setState({isDownloading:false})
			}, 3000)
		})
		.catch((error)=>{
			let errorMessage = 'Please contact account holder'
			showErrorNotifications(
				'Download Failed',
				errorMessage,
				'bl'
			)
			this.setState({isDownloading:false})
		})
	}

	companyFormatter(cell: Object, row: Object): Object {
	  	return `${row.company_id} - ${row.company_name}`
	}

	handleVisitDashboard(e, url){
		window.open(url,'_blank')
	}

	handleSortChange(name, order){
		const {
			usersFetchData,
			usersAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		usersAddFilterSort(sort).then(()=>usersFetchData(this.props.usersFilterData))

	}

	handleEditClick(userId){
		this.props.history.push(`/support/users/${userId}/edit`);
	}
	
	purifyData(data){
		const sanitize_data = data.map((value,index)=>{
			 value.company_name = renderToString(value.company_name)
			 value.name = renderToString(value.name)
			 value.preferred_name = renderToString(value.preferred_name)
			 value.email = renderToString(value.email)
			 return value
		})

		return sanitize_data;
	}

  	render() {
		const {
			users,
			usersFetchData,
			usersPagination,
			usersFilterData,
			usersAddFilterPageNumber,
			globalIndicatorData,
			userPrivilege
		} = this.props
		
		// console.log(users.toJS())

		let reportPagination: ?Object

		if (!users.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={usersPagination}
					fetchData={usersFetchData}
					filterData={usersFilterData}
					addPageNumber={usersAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''

		const selectRowProp = {
		  mode: 'checkbox',
			onSelect: this.handleSelectRow,
			onSelectAll: this.handleSelectAll,
			selected:usersFilterData.get('selected_ids').toJS() 
 		};

		const displayOptions: Array<Object> = [
			{value: '10', label: '10'},
			{value: '20', label: '20'},
			{value: '30', label: '30'}
		]

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		};

    	return (
			<div className={isIndicatorClass}>
				<div className="div-support-container">
					<section className="wrapper">
						<div className="main-content">
							<div className="container-fluid">
								<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">Users</h5>
										<div>
											<button disabled={this.state.isDownloading} className="btn-support btn-white m-r-16" onClick={this.handleDownloadUserData}><i className={`${this.state.isDownloading ? 'fa fa-spinner fa-pulse fa-fw':''}`}></i>Download user data</button>
											{
												userPrivilege.includes('CREATE_USER') &&
												<Link to="/support/users/new" className="btn-support">Add User</Link>
											}
										</div>
									</div>
									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="status"
												id="status"
												value={usersFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
											</select>
											Entries
											{
												(usersFilterData.get('selected_ids').size > 0 && userPrivilege.includes('SEND_EMAIL_TO_USERS')) &&
												<div className="m-l-24 inline-content">
													<button onClick={ this.handleComposeEmail } className="btn-support">Compose Email</button>
												</div>
											}
										</div>

										<div className="flex">
											<div className="search-support-container horizontal-item--between m-r-16">
												<input className="search-support" placeholder="Search by name or email" defaultValue={this.props.usersFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
											<button onClick={ this.handleGetSelectedData } className="btn-support">Advanced Filter</button>
										</div>
									</div>

									<div className="report-list-container user-list form-custom table-condensed">
										<BootstrapTable
											data={this.purifyData(users.toJS())}
											striped={true}
											selectRow={ selectRowProp }
											ref='table'
											options = {options}
											containerStyle={ { width: 'fit-content'}}
											rowStyle={ { overflowWrap: 'break-word', wordBreak: 'break-all' } }
										>
											<TableHeaderColumn dataField="id" isKey={true} hidden>ID</TableHeaderColumn>
											<TableHeaderColumn tdStyle={{maxWidth:'250px'}} thStyle={{maxWidth:'250px'}} dataField="name" dataSort={true} columnClassName="hov-expand">Full Name</TableHeaderColumn>
											<TableHeaderColumn tdStyle={{maxWidth:'250px'}} thStyle={{maxWidth:'250px'}} dataField="preferred_name" dataSort={true} columnClassName="hov-expand">Preferred Name</TableHeaderColumn>
											<TableHeaderColumn tdStyle={{maxWidth:'300px'}} thStyle={{maxWidth:'300px'}} dataField="email" dataSort={true} columnClassName="hov-expand">Email</TableHeaderColumn>
											<TableHeaderColumn width="130" dataField="mobile_number" dataSort={false} columnClassName="hov-expand">Phone Number</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.companyFormatter} columnClassName="hov-expand">Company</TableHeaderColumn>
											<TableHeaderColumn width="160" dataField="user_level" columnClassName="hov-expand">User Level</TableHeaderColumn>
											<TableHeaderColumn width='80' dataFormat={this.statusFormatter} columnClassName="hov-expand">Status</TableHeaderColumn>
											<TableHeaderColumn width="200" dataField="last_logged_in" columnClassName="hov-expand">Last Logged In</TableHeaderColumn>
											<TableHeaderColumn width='50' dataFormat={this.actionFormatter} dataAlign='center' columnClassName="dropdown-user-support"></TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
									</div>
								</div>
							</div>
						</div>
					</section>
					<ModalAdvancedFilter />
					<ModalComposeEmail receiver={this.state.receiver} clearReceiver={this.clearEmailReceiver}/>
				</div>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		jobId: ownProps.match.params.jobId,
		pathName: ownProps.location.pathname,
		companyId: ownProps.match.params.companyId,
		featureListCompany: state.featureListCompany,
		globalIndicatorData: state.globalIndicatorData,
		company: state.company,
		user: state.user,
		users: state.users,
		userTemp: state.userTemp,
		usersPagination: state.usersPagination,
		usersFilterData: state.usersFilterData,
		companiesDropdown: state.companiesDropdown,
		userPrivilege: state.userPrivilege,
		userAuth: state.userAuth,
		receivers: state.receivers,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		globalActiveMenuTeamMember: () => dispatch(globalActiveMenuTeamMember()),
		globalActiveMenuHiringTeam: () => dispatch(globalActiveMenuHiringTeam()),
		usersClearFormDataSuccess: () => dispatch(usersClearFormDataSuccess()),
		usersGetDetailTempSuccess: (user) => dispatch(usersGetDetailTempSuccess(user)),
		usersFetchData: (filter_data) => dispatch(usersFetchData(filter_data)),
		usersAddFilterPageNumber: (page) => dispatch(usersAddFilterPageNumber(page)),
		usersAddFilterSort: (sort) => dispatch(usersAddFilterSort(sort)),
		usersAddFilterFullname: (fullname) => dispatch(usersAddFilterFullname(fullname)),
		usersAddFilterSearch: (keywords) => dispatch(usersAddFilterSearch(keywords)),
		usersDelete: (company_id, user_ids) => dispatch(usersDelete(company_id, user_ids)),
		usersAddFilterColPerPage: (per_page) => dispatch(usersAddFilterColPerPage(per_page)),
		usersAddFilterSelectedIds: (ids) => dispatch(usersAddFilterSelectedIds(ids)),
		usersClearFilterData: () => dispatch(usersClearFilterData()),
		usersActivate: (ids, is_active) => dispatch(usersActivate(ids, is_active)),
		resetUserPassword: (userId, companyId) => dispatch(resetUserPassword(userId, companyId)),
		companiesGetDetail: (companyId) => dispatch(companiesGetDetail(companyId)),
		showErrorNotifications: (title, message, position) => {
			return dispatch(Notifications.error({
			  title,
			  message,
				position
			}))
		},
		usersFetchReceiver: (filter) => dispatch(usersFetchReceiver(filter)),
		usersFetchReceiverData: (receivers) => dispatch(usersFetchReceiverData(receivers)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
