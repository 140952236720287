// @flow

import React from 'react'
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { List, Map, fromJS } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import PaginationSupport from '../partials/PaginationSupport'
import $ from 'jquery'

import {
	companiesFetchData,
	companiesAddFilterPageNumber,
	companiesAddFilterSearch,
	companiesAddFilterSort,
	companiesAddFilterName,
	companiesAddFilterColPerPage,
	companiesAddFilterStatus,
	companiesClearFilterData,
	companiesAddFilterChannel
} from '../../actions/companies'

import ModalDownloadAllAccounts from '../partials/ModalDownloadAllAccounts'
import ModalConnectPopskul from '../partials/ModalConnectPopskul'

import TableLoader from '../partials/TableLoader'

require('es6-promise').polyfill()

type Props = {
	companiesFetchData: Function,
	companiesFetchDataAsync: Function,
	companiesPagination: Map<string, any>,
	companies: List<Map<string, any>>,
	companiesFilterData: Map<string, any>,
	companiesAddFilterPageNumber: Function,
	globalIndicatorData: Map<string, any>,
	companiesAddFilterSearch: Function,
	companiesAddFilterSort: Function,
	companiesAddFilterName: Function,
	companiesAddFilterColPerPage: Function,
	companiesAddFilterStatus: Function,
	companiesClearFilterData: Function,
	companiesAddFilterChannel: Function,
};

type State = {
	isShown: boolean
};

class CompanyList extends React.Component<Props, State> {
	state: State;

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selectedCompany:{}
		}

		bindAll(this, [
			'handleSortChange',
			'handleSearchByName',
			'handleChangePageSize',
			'handleFilterByStatus',
			'handleModalDownload',
			'creditFormatter',
			'idFormatter',
			'handleSelectRow',
			'dateFormatter',
			'popskulActionFormater',
			'handleConnectDisconnectPopskul',
			'onCloseModal'
		])
	}

	componentDidMount() {
		const {
			companiesFetchData,
			companiesClearFilterData,
			companiesAddFilterChannel
		} = this.props

		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'company-detail'){
			companiesAddFilterChannel('career_development_center').then(()=>{
				companiesFetchData(this.props.companiesFilterData)
			})
		}else{
			companiesClearFilterData()
			.then(()=>{
				companiesAddFilterChannel('career_development_center').then(()=>{
					companiesFetchData(this.props.companiesFilterData)
				})
			})
		}
	}

	handleModalDownload(e: Object){
		e.preventDefault()

		$('#modalDownloadAllAccounts').modal('show')
	}

	handleChangePageSize(e): void{
		const{
			companiesAddFilterPageNumber,
			companiesFetchData,
			companiesAddFilterColPerPage,
		}=this.props

		const countFilter = e.target.value
		companiesAddFilterPageNumber(1)
		.then(()=>{
			companiesAddFilterColPerPage(countFilter)
			.then(()=>{
				companiesFetchData(this.props.companiesFilterData)
			})
		})

	}

	handleSortChange(name, order){
		const {
			companiesFetchData,
			companiesAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		companiesAddFilterSort(sort).then(()=>companiesFetchData(this.props.companiesFilterData))

	}

	handleSearchByName(event){
		const{
			companiesAddFilterPageNumber,
			companiesFetchData,
			companiesAddFilterSearch
		}=this.props

		let keywords=event.target.value
		if (event.charCode === 13) {
			companiesAddFilterPageNumber(1).then(()=>{
				companiesAddFilterSearch(keywords).then(()=>{
					companiesFetchData(this.props.companiesFilterData)
				})
			})
    	}
	}

	handleFilterByStatus(event){
		const{
			companiesAddFilterPageNumber,
			companiesFetchData,
			companiesAddFilterStatus
		}=this.props

		let status=event.target.value
		companiesAddFilterPageNumber(1).then(()=>{
			companiesAddFilterStatus(status).then(()=>{
				companiesFetchData(this.props.companiesFilterData)
			})
		})
	}

	creditFormatter(cell: Object, row: Object): Object {
		let colorText='text-red'
		let credit = parseInt(row.candidate_credit);
		let creditUsed = parseInt(row.credit_used);
		let creditText = `${credit-creditUsed}/${creditUsed}`
		if(row.status==='Active'){
			colorText='text-green'
			creditText = row.credit_left;
		}
		return <span className={colorText}>{creditText}</span>
	}

	dateFormatter(cell: Object, row: Object): Object {
		let date = (row.valid_date ? moment(row.valid_date).format("MMM D, YYYY") : '')
		return <span>{date}</span>
	}
	
	handleSelectRow(row, index){
		if(index <=7 ){
			this.props.history.push({ 
				pathname: `/support/university/${row.id}`,
				state: {prevPage: 'company-index'}
			})
		}
	}

	idFormatter(cell: Object, row: Object): Object {
		return <a style={{cursor:'pointer'}} onClick={()=>this.handleSelectRow(row)}>{row.id}</a>							
	}

	purifyData(data){
		const sanitize_data = data.map((value,index)=>{
			 value.title = renderToString(value.title)
			 value.administrator = renderToString(value.administrator)
			 value.email = renderToString(value.email)
			 return value
		})

		return sanitize_data;
	}

	popskulActionFormater(cell, row){
		const {
			userPrivilege, 
			userAuth
		} = this.props

		// let itSelf=userAuth.get('id')==row.id ? true : false
		// let myPrivilege=['UPDATE_USER','ACTIVATE_USER','RESET_USERS_PASSWORD','VISIT_USERS_DASHBOARD','SEND_EMAIL_TO_USERS','DELETE_USERS']
		// let res = userPrivilege.filter(item => myPrivilege.includes(item));
		
		// if(itSelf || res.length<1){
		// 	return null
		// }
		let buttonLabel = 'Connect'
		let buttonClass = 'button__primary'
		console.log('b', row);
		if(row.popskul_connection==1){
			buttonLabel = 'Disconnect'
			buttonClass = 'button__secondary'
		}
		return <button onClick={(e)=>this.handleConnectDisconnectPopskul(e, row)} className={`button ${buttonClass}`}>{buttonLabel}</button>
	}

	handleConnectDisconnectPopskul(e, company){
		e.preventDefault()
		this.setState({selectedCompany:company})
		$('#modalConnectPopskul').modal('show')
	}

	onCloseModal(){
		this.setState({selectedCompany:{}})
		this.props.companiesFetchData(this.props.companiesFilterData)
	}

  	render() {
		const {
			companies,
			companiesPagination,
			companiesAddFilterPageNumber,
			companiesFilterData,
			companiesFetchData,
			globalIndicatorData,
		} = this.props

		let reportPagination: ?Object

		if (!companies.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={companiesPagination}
					fetchData={companiesFetchData}
					filterData={companiesFilterData}
					addPageNumber={companiesAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		const options = {
			onSortChange: this.handleSortChange,
			onRowClick: (row, columnIndex)=>this.handleSelectRow(row, columnIndex),
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		};
    	return (

			<div className={isIndicatorClass}>
	      		<div className="div-support-container table">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container">
										<h5 className="title-heading">University</h5>
									</div>

									<div className="flex-container flex-between">
										<div className="flex-container vertical-item--center">
											Show
											<select
												className="select-status-user"
												name="status"
												id="status"
												value={companiesFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
										  	</select>
										 	 Entries
										  	<select 
											  	className="select-status-user" 
												name="status" 
												id="status"
												value={companiesFilterData.get('status')}
												onChange={(e)=>this.handleFilterByStatus(e)}
											>
												<option value="" disabled selected>Filter by status</option>
												<option value="">All</option>
												<option value="free_trial">Free Trial</option>
												<option value="suspended">Suspended</option>
												<option value="active">Active</option>
												<option value="expired">Expired</option>
										  	</select>
										  	<div className="search-support-container horizontal-item--between">
												<input className="search-support" placeholder="Search by name or account holder" defaultValue={this.props.companiesFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
										<div>
											<button onClick={this.handleModalDownload} className="btn-support-action primary p-l-16 p-r-16 p-t-12 p-b-12"><i className='icon-i-download-white'></i>Download All Accounts Overview</button>
										</div>
									</div>

	                				<div className="report-list-container form-custom support-company-list">
										<BootstrapTable
											data={this.purifyData(companies.toJS())}
											striped={true}
											options={options}
											hover
										>
											<TableHeaderColumn dataField="id" isKey={true} dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField="title" dataSort={true}>Name</TableHeaderColumn>
											<TableHeaderColumn dataField="administrator" dataSort={true}>Administrator</TableHeaderColumn>
											<TableHeaderColumn dataField="total_candidate" dataSort={true}>Number of Students</TableHeaderColumn>
											<TableHeaderColumn dataField="valid_date" dataFormat={this.dateFormatter} dataSort={true}>Expiry Date (GMT+7)</TableHeaderColumn>
											<TableHeaderColumn dataField="status" dataSort={true}>Status</TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
	                				</div>
	              				</div>
	            			</div>
	          			</div>
	        		</section>
	      		</div>
	      		<ModalDownloadAllAccounts />
				<ModalConnectPopskul company={this.state.selectedCompany} onCloseModal={this.onCloseModal}/>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		companies: state.companies,
		companiesPagination: state.companiesPagination,
		companiesFilterData: state.companiesFilterData,
		companiesIsLoading: state.companiesIsLoading,
		globalIndicatorData: state.globalIndicatorData,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		companiesFetchData: (filter_data) => dispatch(companiesFetchData(filter_data)),
		companiesAddFilterPageNumber: (page) => dispatch(companiesAddFilterPageNumber(page)),
		companiesAddFilterSearch: (search) => dispatch(companiesAddFilterSearch(search)),
		companiesAddFilterSort: (sort) => dispatch(companiesAddFilterSort(sort)),
		companiesAddFilterName: (name) => dispatch(companiesAddFilterName(name)),
		companiesAddFilterColPerPage: (col) => dispatch(companiesAddFilterColPerPage(col)),
		companiesAddFilterStatus: (status) => dispatch(companiesAddFilterStatus(status)),
		companiesClearFilterData: (status) => dispatch(companiesClearFilterData(status)),
		companiesAddFilterChannel: (channel) => dispatch(companiesAddFilterChannel(channel)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList)
