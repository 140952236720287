import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { reducer as notifications } from 'react-notification-system-redux'
import { routerReducer as routing } from 'react-router-redux'
import { userLevels } from './user_levels'
import { languages } from './languages'

import {
	transaction,
	transactions,
	transactionsFilterData,
	transactionsPagination
} from './transactions'

import {
	featureListCompany,
	featureListJob,
	dropdownFeatures,
	featureLists,
	featuresFilterData,
	featuresPagination,
} from './feature_list'

import {
	company,
	credit,
	companies,
	companiesUiData,
	companiesIsLoading,
	companiesHasErrored,
	companiesTransaction,
	companiesTransactions,
	companiesUsageActivity,
	companiesUpdateDataError,
	companiesTransactionsPagination,
	companiesTransactionsFilterData,
	companiesIds,
	companiesPagination,
	companiesDropdown,
	companiesFilterData,
	creditsStatementFilterData,
	creditsStatementPagination,
	dropdownCompanies
} from './companies'

import {
	globalClass,
	globalUiData,
	globalActiveMenu,
	globalAjaxRequest,
	globalIndicatorData,
	globalSweetAlertData,
	isFirstLogin,
	isFetched,
	information,
	accountManagers
} from './global'

import {
	user,
	users,
	usersHiringTeam,
	usersFilterData,
	usersHiringTeamFilterData,
	usersFormData,
	usersHasErrored,
	usersSaveDataError,
	usersPagination,
	userIds,
	userTemp,
	usersDropdown,
	usersNavigationData,
	userAuth,
	dropdownUserLevels,
	userLevelList,
	userLevel,
	userLevelIds,
	userLevelsPagination,
	userLevelsFilterData,
	dropdownAdminLevels,
	receivers,
	usersInternalFilterData,
	usersInternalPagination,
	userInternalIds,
	usersInternal,
} from './users'

import {
	analyticsJobs,
	analyticsJobsTemp,
	analyticsFilterData,
	analyticsInvites,
	analyticsInvitesPagination,
	analyticsCandidatesData,
	analyticsInviteIds
} from './analytics'

import { timezones } from './timezones'
import { sessionsToken, isAuthorize,userPrivilege } from './sessions'

import {
	customPlans,
	customPlansPagination,
	customPlansFilterData,
	totalAccountRequiringAction,
} from './custom_plans'

import {
	publishJobs,
	publishJobsPagination,
	publishJobsFilterData,
	totalPublishJobsRequest,
} from './job'

import{
	voucher,
	vouchers,
	vouchersIds,
	vouchersPagination,
	vouchersFilterData,
	certificates
} from './vouchers'

const reduxForm = form.plugin({
  SectionModalForm: (state, action) => {
    switch (action.type) {
      case 'SECTIONS_RESET_SECTION_MODAL_FORM_SUCCESS':
        return {
          values: {},
          syncErrors: state.syncErrors,
          registeredFields: state.registeredFields
        }
      default:
        return state
    }
  }
})

export default combineReducers({
	analyticsCandidatesData,
	analyticsFilterData,
	analyticsInviteIds,
	analyticsInvites,
	analyticsInvitesPagination,
	analyticsJobs,
	analyticsJobsTemp,
	companies,
	companiesHasErrored,
	companiesIsLoading,
	companiesTransaction,
	companiesTransactions,
	companiesTransactionsFilterData,
	companiesTransactionsPagination,
	companiesUiData,
	companiesUpdateDataError,
	companiesUsageActivity,
	companiesIds,
	companiesPagination,
	companiesFilterData,
	creditsStatementFilterData,
	creditsStatementPagination,
	company,
	credit,
	companiesDropdown,
	featureListCompany,
	featureListJob,
	dropdownFeatures,
	featureLists,
	featuresFilterData,
	featuresPagination,
	form: reduxForm,
	globalActiveMenu,
	globalAjaxRequest,
	globalClass,
	globalIndicatorData,
	globalSweetAlertData,
	globalUiData,
	languages,
	notifications,
	routing,
	transaction,
	transactions,
	transactionsFilterData,
	transactionsPagination,
	user,
	userIds,
	userLevels,
	users,
	usersDropdown,
	usersFilterData,
	usersFormData,
	usersHasErrored,
	usersHiringTeam,
	usersHiringTeamFilterData,
	usersNavigationData,
	usersPagination,
	usersSaveDataError,
	userTemp,
	userAuth,
	dropdownUserLevels,
	dropdownAdminLevels,
	userLevelList,
	userLevel,
	userLevelIds,
	userLevelsPagination,
	userLevelsFilterData,
	isFirstLogin,
	timezones,
	sessionsToken,
	isAuthorize,
	userPrivilege,
	receivers,
	usersInternalFilterData,
	usersInternalPagination,
	userInternalIds,
	usersInternal,
	isFetched,
	information,
	accountManagers,
	customPlans,
	customPlansPagination,
	customPlansFilterData,
	dropdownCompanies,
	totalAccountRequiringAction,
	publishJobs,
	publishJobsPagination,
	publishJobsFilterData,
	totalPublishJobsRequest,
	voucher,
	vouchers,
	vouchersIds,
	vouchersPagination,
	vouchersFilterData,
	certificates,
})
