// @flow

import { put, select, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
	change,
	startAsyncValidation,
	stopAsyncValidation
} from 'redux-form'

import {
	usersCheckVoucherCode
} from '../apis/vouchers'


export function* validateVoucherCode({code, formName}: Object): Generator<*, *, *> {
	const errors: Object = {code: 'This voucher code is already registered'}

	try {
		yield put(change(formName, 'code', code, true, true))

		yield put(startAsyncValidation(formName))

		const response: Object = yield call(
		usersCheckVoucherCode,
		code
		)

		if (response.status === 200) {
		yield put(stopAsyncValidation(formName, {}))
		} else if (response.status === 422) {
		yield put(stopAsyncValidation(formName, errors))
		}
	} catch (error) {
		yield put(stopAsyncValidation(formName, errors))
	}
}