// @flow

import axios from "../dependencies/_axios";

export function featureListCompanyFetchDataApi(companyId: string) {
	return axios({
		url: `/companies/${companyId}/feature-list`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'}
	})
}

export function featureListJobFetchDataApi(companyId: string, jobId: string) {
	return axios({
		url: `/companies/${companyId}/jobs/${jobId}/feature-list`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'}
	})
}
