// @flow

import { put, call } from 'redux-saga/effects'

import {
	featureListJobFetchDataApi,
	featureListCompanyFetchDataApi
} from '../apis/feature_list'

export function* featureListCompanyFetchData({ companyId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			featureListCompanyFetchDataApi,
			companyId
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'FEATURE_LIST_COMPANY_FETCH_DATA_SUCCESS',
				featureListCompany: response.data
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}

export function* featureListJobFetchData({ companyId, jobId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			featureListJobFetchDataApi,
			companyId,
			jobId
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'FEATURE_LIST_JOB_FETCH_DATA_SUCCESS',
				featureListJob: response.data
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}
