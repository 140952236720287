// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from  'react-router'
import { Link } from  'react-router-dom'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import loader from '../../assets/img/loader.gif'
import imgCreditEmpty from '../../assets/img/credit-empty.svg'
import imgCreditNotFound from '../../assets/img/credit-not-found.svg'

require('es6-promise').polyfill()

type Props = {
	isFetched: boolean,
	feature: string
};

class TableLoader extends React.Component<Props> {
	constructor(props: Object) {
		super(props)
		bindAll(this, [
		])
	}


	componentDidUpdate(prevProps){
		const {
			notifications
		} = this.props
	}

	render() {
		const {
			isFetched,
			feature
		} = this.props
		
		if(isFetched){
			let title = 'No Data Avaliable'
			let description = 'Select another filter to get more results'
			if(feature=='custom-plan'){
				title = 'No Accounts Requesting Action Right Now'
				description = 'Here, you will be able to see the organisations that require an action to be taken by our team.'
			}
			
			return (
				<div className="div-empty-credit flex-container flex-column horizontal-item--center">
					<img src={imgCreditEmpty} />
					<label>{title}</label>
					<p>{description}</p>
				</div>
			)
		}else{
			return(
				<div className="splash-container" id="table-loader">
					<img src={loader} className='img-responsive'/>
				</div>
			)
		}
	}
}

export default TableLoader
