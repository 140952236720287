// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { List, Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../../components/partials/PaginationSupport'

import {
	featuresFetchData,
	featuresAddFilterPageNumber,
	featuresAddFilterSort,
	featuresAddFilterName,
	featuresAddFilterColPerPage,
	featuresClearFilterData
} from '../../actions/features'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert
} from '../../actions/global'

import TableLoader from '../partials/TableLoader'

require('es6-promise').polyfill()

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	featureLists: List<Map<string, any>>,
	featuresPagination: Map<string, any>,
	featuresFilterData: Map<string, any>,
	featuresFetchData: Function,
	featuresAddFilterPageNumber: Function,
	featuresAddFilterSort: Function,
	featuresAddFilterName: Function,
	featuresClearFormDataSuccess: Function,
	globalIndicatorData: Map<string, any>,
	featuresAddFilterColPerPage: Function,
	featuresClearFilterData: Function,
};

type State = {
	isShown: boolean
};

class FeatureList extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
		}

		bindAll(this, [
			'handleChangePageSize',
			'handleSearchByName',
			'handleSortChange'
		])
	}

	componentDidMount() {
		const {
			featuresFetchData,
			featuresClearFilterData
		} = this.props
		
		featuresClearFilterData()
		.then(()=>{
			featuresFetchData(this.props.featuresFilterData)
		})
	}

	handleChangePageSize(e): void{
		const{
			featuresAddFilterPageNumber,
			featuresFetchData,
			featuresAddFilterColPerPage,
		}=this.props
		const countFilter = e.target.value
		featuresAddFilterPageNumber(1)
		.then(()=>{
			featuresAddFilterColPerPage(countFilter)
			.then(()=>{
				featuresFetchData(this.props.featuresFilterData)
			})
		})

	}

	handleSearchByName(event){
		const{
			featuresAddFilterPageNumber,
			featuresFetchData,
			featuresAddFilterName
		}=this.props

		let feature_name=event.target.value
		if (event.charCode === 13) {
			featuresAddFilterPageNumber(1).then(()=>{
				featuresAddFilterName(feature_name).then(()=>{
					featuresFetchData(this.props.featuresFilterData)
				})
			})
    	}
	}
	
	handleSortChange(name, order){
		const {
			featuresFetchData,
			featuresAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		featuresAddFilterSort(sort).then(()=>featuresFetchData(this.props.featuresFilterData))
	}

  	render() {
		const {
			featureLists,
			featuresFetchData,
			featuresPagination,
			featuresFilterData,
			featuresAddFilterPageNumber,
			globalIndicatorData,
		} = this.props
		
		let reportPagination: ?Object

		if (!featureLists.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={featuresPagination}
					fetchData={featuresFetchData}
					filterData={featuresFilterData}
					addPageNumber={featuresAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''

		const displayOptions: Array<Object> = [
			{value: '10', label: '10'},
			{value: '20', label: '20'},
			{value: '30', label: '30'}
		]

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		};

    	return (
			<div className={isIndicatorClass}>
				<div className="div-support-container">
					<section className="wrapper">
						<div className="main-content">
							<div className="container-fluid">
								<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">Feature List</h5>
										<div>
											{/* <Link to="/support/features/new" className="btn-support">Add New Feature</Link> */}
										</div>
									</div>
									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="status"
												id="status"
												value={featuresFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
											</select>
											Entries
										</div>

										<div className="flex">
											<div className="search-support-container horizontal-item--between m-r-16">
												<input className="search-support" placeholder="Search by name or group" onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
									</div>

									<div className="report-list-container form-custom">
										<BootstrapTable
											data={featureLists.toJS()}
											striped={true}
											ref='table'
											options={options}
										>
											<TableHeaderColumn dataField="id" isKey={true} dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField="feature_name" dataSort={true}>Name</TableHeaderColumn>
											<TableHeaderColumn dataField="feature_group" dataSort={true}>Feature Group</TableHeaderColumn>
											<TableHeaderColumn dataField="type" dataSort={true}>Type</TableHeaderColumn>
											{/*<TableHeaderColumn width='50' dataFormat={this.actionFormatter} dataAlign='center' columnClassName="dropdown-user-support"></TableHeaderColumn>*/}
										</BootstrapTable>
										{reportPagination}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		globalIndicatorData: state.globalIndicatorData,
		featureLists: state.featureLists,
		featuresPagination: state.featuresPagination,
		featuresFilterData: state.featuresFilterData,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		featuresFetchData: (filter_data) => dispatch(featuresFetchData(filter_data)),
		featuresAddFilterPageNumber: (page) => dispatch(featuresAddFilterPageNumber(page)),
		featuresAddFilterSort: (sort) => dispatch(featuresAddFilterSort(sort)),
		featuresAddFilterName: (fullname) => dispatch(featuresAddFilterName(fullname)),
		featuresClearFilterData: () => dispatch(featuresClearFilterData()),
		featuresAddFilterColPerPage: (col) => dispatch(featuresAddFilterColPerPage(col)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureList)
