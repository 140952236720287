import { List, Map, fromJS } from 'immutable'

const initUiData = Map({
	usage_activity_period: '',
	is_blocking_transition: false
})

const initTransactionsFilterData = Map({
	page: ''
})

const initFilterData = fromJS({
	page: '',
	selected_ids: [],
	sort: '',
	name: '',
	per_page:10,
	status:'',
	search:'',
	custom_plan:'',
	channel:''
})

const initCreditFilterData = fromJS({
	company_id:0,
	page: '',
	sort: '',
	search: '',
	per_page:10,
	from:'',
	to:''
})

export function companiesHasErrored(state = false, action) {
	switch (action.type) {
		case 'COMPANIES_HAS_ERRORED':
			return action.hasErrored
		default:
			return state
	}
}

export function companiesIsLoading(state = false, action) {
	switch (action.type) {
		case 'COMPANIES_IS_LOADING':
			return action.isLoading
		default:
			return state
	}
}

export function companies(state = List([]), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_DATA_SUCCESS':
			return fromJS(action.companies)
		default:
			return state
	}
}

export function company(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_GET_DETAIL_SUCCESS':
			return Map(action.company)
		case 'COMPANIES_GET_LOCATIONS_SUCCESS':
			return state.set('location', action.locations)
		default:
			return state
	}
}

export function credit(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_GET_CREDIT_SUCCESS':
			return Map(action.credit)
		default:
			return state
	}
}

export function companiesUpdateDataError(state = {}, action) {
	switch(action.type) {
		case 'COMPANIES_UPDATE_DATA_ERROR':
			return action.errors
		default:
			return state
	}
}

export function companiesUiData(state = initUiData, action) {
	switch(action.type) {
		case 'COMPANIES_ADD_UI_IS_BLOCKING_TRANSITION_SUCCESS':
			return state.set('is_blocking_transition', action.is_blocking_transition)
		case 'COMPANIES_ADD_UI_USAGE_ACTIVITY_PERIOD_SUCCESS':
			return state.set('usage_activity_period', action.usage_activity_period)
		default:
			return state
	}
}

export function companiesUsageActivity(state = List([]), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_USAGE_ACTIVITY_DATA_SUCCESS':
			return fromJS(action.usageActivity)
		default:
			return state
	}
}

export function companiesTransaction(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_GET_TRANSACTION_DETAIL_SUCCESS':
			return fromJS(action.transaction)
		default:
			return state
	}
}

export function companiesTransactions(state = List([]), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_TRANSACTIONS_DATA_SUCCESS':
			return fromJS(action.transactions)
		default:
			return state
	}
}

export function companiesTransactionsFilterData(state = initTransactionsFilterData, action) {
	switch(action.type) {
		case 'COMPANIES_TRANSACTIONS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'COMPANIES_TRANSACTIONS_ADD_FILTER_ALL_SUCCESS':
			return fromJS(action.all)
		default:
			return state
	}
}

export function companiesTransactionsPagination(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_TRANSACTIONS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}


export function companiesIds(state = List([]), action) {
  switch(action.type) {
    case 'COMPANIES_FETCH_IDS_SUCCESS':
    	return List(action.ids)
    default:
    	return state
  }
}

export function companiesPagination(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

//SD
export function companiesDropdown(state = List([]), action) {
	switch (action.type) {
		case 'DROPDOWN_COMPANIES_FETCH_DATA_SUCCESS':
			return fromJS(action.companies)
		default:
			return state
	}
}


export function companiesFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'COMPANIES_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'COMPANIES_ADD_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('selected_ids', List(action.selected_ids))
		case 'COMPANIES_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'COMPANIES_ADD_FILTER_NAME_SUCCESS':
			return state.set('name', action.fullname)
		case 'COMPANIES_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'COMPANIES_ADD_FILTER_STATUS_SUCCESS':
			return state.set('status',action.status)
		case 'COMPANIES_ADD_FILTER_SEARCH_SUCCESS':
			return state.set('search', action.search)
		case 'COMPANIES_ADD_FILTER_CUSTOM_PLAN_SUCCESS':
			return state.set('custom_plan', action.custom_plan)
		case 'COMPANIES_ADD_FILTER_CHANNEL_SUCCESS':
			return state.set('channel', action.channel)
		case 'COMPANIES_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				selected_ids: [],
				sort: '',
				name: '',
				per_page:10,
				status:'',
				search:'',
				custom_plan:'',
				channel:''
			})
		default:
			return state
	}
}

export function creditsStatementFilterData(state = initCreditFilterData, action) {
	switch(action.type) {
		case 'CREDITS_STATEMENT_ADD_FILTER_COMPANY_ID_SUCCESS':
			return state.set('company_id', action.company_id)
		case 'CREDITS_STATEMENT_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'CREDITS_STATEMENT_ADD_FILTER_SEARCH_SUCCESS':
			return state.set('search', action.search)
		case 'CREDITS_STATEMENT_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'CREDITS_STATEMENT_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'CREDITS_STATEMENT_ADD_FILTER_FROM_SUCCESS':
			return state.set('from',action.from)
		case 'CREDITS_STATEMENT_ADD_FILTER_TO_SUCCESS':
			return state.set('to', action.to)
		case 'CREDITS_STATEMENT_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				company_id:0,
				page: '',
				sort: '',
				search: '',
				per_page:10,
				from:'',
				to:''
			})
		default:
			return state
	}
}

export function creditsStatementPagination(state = Map({}), action) {
	switch(action.type) {
		case 'CREDITS_STATEMENT_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

export function dropdownCompanies(state = List([]), action) {
	switch(action.type) {
		case 'DROPDOWN_COMPANIES_FETCH_DATA_SUCCESS':
			let current = state.toJS()
			current = current.concat(action.companies)
			return state.merge(fromJS(current))
		case 'CLEAR_DROPDOWN_COMPANIES_DATA_SUCCESS':
			return state = List([])
		default:
			return state
	}
}