import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import history from './dependencies/history'
import configureStore from './store/configureStore'
import $ from 'jquery'
import bootstrap from 'bootstrap'


import SupportDashboardIndex from './components/pages/SupportDashboardIndex'
import CompanyDetail from './components/companies/CompanyDetail'
import CompanyEdit from './components/companies/CompanyEdit'
import CompanyUpdateCredit from './components/companies/CompanyUpdateCredit'
import CompanyList from './components/companies/CompanyList'
import UserList from './components/users/UserList'
import UserForm from './components/users/UserForm'
import FeatureList from './components/features/FeatureList'
import UserInternalList from './components/users/UserInternalList'
import UserInternalForm from './components/users/UserInternalForm'
import UserLevel from './components/users/UserLevel'
import UserLevelForm from './components/users/UserLevelForm'
import TransactionList from './components/transactions/TransactionList'
import TransactionDetail from './components/transactions/TransactionDetail'
import SessionIndex from './components/SessionIndex'
import CustomPlan from './components/companies/CustomPlan'
import CustomPlanCreateUser from './components/companies/CustomPlanCreateUser'
import UniversityList from './components/companies/UniversityList'
import UniversityDetail from './components/companies/UniversityDetail'
import UniversityEdit from './components/companies/UniversityEdit'

import VoucherList from './components/voucher/VoucherList'
import VoucherDetail from './components/voucher/VoucherDetail'

import PublishJob from './components/job/PublishJob'

import VoucherForm from './components/vouchers/VoucherForm'

import * as serviceWorker from './serviceWorker';

//css

import './assets/sass/public/bootstrap-custom.scss'
import 'font-awesome/css/font-awesome.css'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import 'react-select/dist/react-select.css'
import 'nprogress/nprogress.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import './assets/sass/public/style.scss'
import './assets/sass/main.scss'
import './assets/sass/style.scss'
import './assets/sass/style-responsive.scss'


//Turn this off while development to show console.log
  // console.log = () => {};
  // console.error = () => {};
  // console.debug = () => {};
//---------------------------------------------------//

const store = configureStore()
const App = {
  init: function() {
    this.renderSupportDashboardIndex()
  },


  renderSupportDashboardIndex: function() {
    const supportDashboardIndexTarget = document.querySelector('#root')

    if (supportDashboardIndexTarget) {
      ReactDOM.render(
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <SupportDashboardIndex>
                <Switch>
                  <Route exact path='/' component={SessionIndex} />
                  <Route exact path='/support' component={SessionIndex} />
                  <Route exact path='/support/companies' component={CompanyList} />
                  <Route exact path='/support/companies/:companiesId' component={CompanyDetail} />
                  <Route exact path='/support/companies/:companiesId/edit' component={CompanyEdit} />
                  <Route exact path='/support/companies/:companiesId/update-credit' component={CompanyUpdateCredit} />
                  <Route exact path='/support/users' component={UserList} />
                  <Route exact path='/support/users/:userId/edit' component={UserForm } />
                  <Route exact path='/support/users/new' component={UserForm } />
                  <Route exact path='/support/features' component={FeatureList } />
                  <Route exact path='/support/internal-user' component={UserInternalList } />
                  <Route exact path='/support/internal-user/new' component={UserInternalForm } />
                  <Route exact path='/support/internal-user/:userId/edit' component={UserInternalForm } />
                  <Route exact path='/support/user-level' component={UserLevel } />
                  <Route exact path='/support/user-level/new' component={UserLevelForm } />
                  <Route exact path='/support/user-level/:userLevelId/edit' component={UserLevelForm } />
                  <Route exact path='/support/transactions' component={TransactionList } />
                  <Route exact path='/support/transactions/:transactionId' component={TransactionDetail} />
                  <Route exact path='/support/custom-plan' component={CustomPlan} />
                  <Route exact path='/support/popskul-request' component={PublishJob} />
                  <Route exact path='/support/popskul-request/:id/create-user' component={CustomPlanCreateUser} />
                  <Route exact path='/support/university' component={UniversityList} />
                  <Route exact path='/support/university/:companiesId' component={UniversityDetail} />
                  <Route exact path='/support/university/:companiesId/edit' component={UniversityEdit} />
                  <Route exact path='/support/voucher/new' component={VoucherForm} />
                  <Route exact path='/support/voucher' component={VoucherList} />
                  <Route exact path='/support/voucher/:voucherId/edit' component={VoucherForm} />
                  <Route exact path='/support/voucher/:voucherId' component={VoucherDetail} />
                </Switch>
              </SupportDashboardIndex>
            </ConnectedRouter>
          </Provider>,
        supportDashboardIndexTarget
      )
    }
  }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


$(function() {
  App.init()
})
