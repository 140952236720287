// @flow

import axios from "../dependencies/_axios";
import { objectToFormData } from '../helpers/global'

export function transactionsSaveDataApi(companyId: string | number, data: Object = {}) {
	return axios({
		url: `/companies/${companyId}/transactions`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data
	})
}

export function transactionsSaveCreditCardDataApi(companyId: string | number, data: Object = {}) {
	return axios({
		url: `/companies/${companyId}/top-up/payment-methods/credit-card`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'multipart/form-data'
		},
		data: objectToFormData(data)
	})
}

export function transactionsSaveBankTransferDataApi(companyId: string | number, data: Object = {}) {


	return axios({
		url: `/companies/${companyId}/top-up/payment-methods/bank-transfer`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'multipart/form-data'
		},
		data: objectToFormData(data)
	})
}
