export const getForm = (state) => state.form
export const getCompany = (state) => state.company
export const getCompaniesTransaction = (state) => state.companiesTransaction
export const getCompaniesTransactions = (state) => state.companiesTransactions
export const getCompaniesTransactionsFilterData = (state) => state.companiesTransactionsFilterData
export const getCompaniesTransactionsPagination = (state) => state.companiesTransactionsPagination
export const getAdminCompany = (state) => state.adminCompany
export const getAdminCompanies = (state) => state.adminCompanies
export const getAdminCompaniesFilterData = (state) => state.adminCompaniesFilterData
export const getAdminTransaction = (state) => state.adminTransaction
export const getAdminTransactions = (state) => state.adminTransactions
export const getAdminTransactionsFilterData = (state) => state.adminTransactionsFilterData
export const getCountries = (state) => state.countries
export const getPaymentPackages = (state) => state.paymentPackages
export const getCandidatesFilterData = (state) => state.candidatesFilterData
export const getTransaction = (state) => state.transaction
export const getTransactions = (state) => state.transactions
export const getSection = (state) => state.section
export const getSectionsUiData = (state) => state.sectionsUiData
export const getQuestions = (state) => state.questions
export const getQuestionsTemp = (state) => state.questionsTemp
export const getQuestionsFilterData = (state) => state.questionsFilterData
export const getInvitesUiData = (state) => state.invitesUiData
export const getInvitesFormData = (state) => state.invitesFormData
export const getCandidateIds = (state) => state.candidateIds
export const getJob = (state) => state.job
export const getVideosFilterData = (state) => state.videosFilterData
export const getVideoDurationInit = (state) => state.videoDurationInit
export const getQuestionsAll = (state) => state.questionsAll
export const getGlobalUiData = (state) => state.globalUiData