import Promise from 'bluebird'
import axios from "../dependencies/_axios";
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import {
	usersGetDetailSuccess,
	usersFetchDataDropdownSuccess
} from './users'

import { globalUnCheckTheBoxHelper } from '../helpers/global'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function globalResetStateSuccess(bool) {
	return {
		type: 'GLOBAL_RESET_STATE_SUCCESS'
	}
}

export function globalAddClassSuccess(classString) {
	return {
		type: 'GLOBAL_ADD_CLASS_SUCCESS',
		classString
	}
}

export function globalActiveMenuJobs() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_JOBS_SUCCESS'
	}
}

export function globalActiveMenuCandidatesAll() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_CANDIDATES_ALL_SUCCESS'
	}
}

export function globalActiveMenuQuestionLibrary() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_QUESTION_LIBRARY_SUCCESS'
	}
}

export function globalActiveMenuTeamMember() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_TEAM_MEMBER_SUCCESS'
	}
}

export function globalActiveMenuHiringTeam() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_HIRING_TEAM_SUCCESS'
	}
}

export function globalActiveMenuAnalytics() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_ANALYTICS_SUCCESS'
	}
}

export function globalActiveMenuUserProfile() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_USER_PROFILE_SUCCESS'
	}
}

export function globalActiveMenuCompanyEdit() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_COMPANY_EDIT_SUCCESS'
	}
}

export function globalActiveMenuCandidates() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_CANDIDATES_SUCCESS'
	}
}

export function globalActiveMenuJobDescription() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_JOB_DESCRIPTION_SUCCESS'
	}
}

export function globalCurrentAjaxRequestSuccess(request_method) {
	return {
		type: 'GLOBAL_CURRENT_AJAX_REQUEST',
		request_method
	}
}

export function globalAddSweetAlertDataSuccess(options) {
	return {
		type: 'GLOBAL_ADD_SWEET_ALERT_DATA_SUCCESS',
		options
	}
}

export function globalHideSweetAlertSuccess() {
	return {
		type: 'GLOBAL_HIDE_SWEET_ALERT_SUCCESS'
	}
}

export function globalGetCSRFTokenSuccess(csrf_token) {
	return {
		type: 'GLOBAL_GET_CSRF_TOKEN',
		csrf_token
	}
}

export function globalActiveMenuInvitationsHistory() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_INVITATIONS_HISTORY_SUCCESS'
	}
}

export function globalActiveMenuReports() {
	return {
		type: 'GLOBAL_ACTIVE_MENU_REPORTS_SUCCESS'
	}
}

export function globalShowNotificationRequest(title, message, position, level) {
	return {
		type: 'GLOBAL_SHOW_NOTIFICATION_REQUEST',
		title,
		message,
		position,
		level
	}
}

export function globalClearFeatureDataSuccess() {
	return {
		type: 'GLOBAL_CLEAR_FEATURE_DATA_SUCCESS'
	}
}

export function globalCurrentDisplaySuccess(display_menu){
	//0 = column && 1 = gallerymode
	return{
		type:'GLOBAL_ADD_UI_IS_CURRENT_DISPLAY_SUCCESS',
		display_menu:display_menu
	}
}

export function globalAddUiDisplayAdvanceFilterSuccess(display_advance_filter){
	return{
		type:'GLOBAL_ADD_UI_IS_DISPLAY_ADVANCE_FILTER',
		display_advance_filter:display_advance_filter
	}
}

export function globalAddUiDisplayColorPickerSuccess(display_color_picker){
	return{
		type:'GLOBAL_ADD_UI_IS_DISPLAY_COLOR_PICKER',
		display_color_picker:display_color_picker
	}
}


export function globalGetCSRFToken() {
	return (dispatch) => {
		let jobsIndexTarget = document.querySelector('#js-job-index-component')
		let props = JSON.parse(jobsIndexTarget.dataset.props)

		dispatch(globalGetCSRFTokenSuccess(props.csrfToken))
	}
}

export function globalAddClass(classString) {
	return (dispatch) => {
		dispatch(globalAddClassSuccess(classString))
	}
}

export function globalCurrentAjaxRequest(request_method) {
	return (dispatch) => {
		dispatch(globalCurrentAjaxRequestSuccess(request_method))
	}
}

export function globalAddSweetAlertData(options) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(globalAddSweetAlertDataSuccess(options)))
		})
	}
}

export function globalHideSweetAlert() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(globalHideSweetAlertSuccess()))
		})
	}
}

// export function globalInitialFetchData(company_id) {
// 	return (dispatch) => {
// 		return axios({
// 			url: `/companies/${company_id}/initial-data`,
// 			timeout: 20000,
// 			method: 'get',
// 			responseType: 'json',
// 			headers: {'X-Requested-With': 'XMLHttpRequest'}
// 		})
// 		.then((response) => {
// 			const data = response.data

// 			dispatch(companiesFetchDataSuccess(data.companies))
// 			dispatch(industriesFetchDataSuccess(data.industries))
// 			dispatch(jobsFetchDataDropdownSuccess(data.jobs))
// 			dispatch(userLevelsFetchDataSuccess(data.user_levels))
// 			dispatch(usersGetDetailSuccess(data.user))
// 			dispatch(usersFetchDataDropdownSuccess(data.users))
// 			dispatch(languagesFetchDataSuccess(data.languages))
// 			dispatch(jobsFetchDataTypeDropdownSuccess(data.job_list_references))
// 			dispatch(countriesFetchDataSuccess(data.countries))
// 			dispatch(paymentPackagesFetchDataSuccess(data.payment_packages))
// 		})
// 		.catch((error) => {
// 			dispatch(Notifications.error({
// 				title: 'Error',
// 				message: 'An error occured when loading initial data, please refresh the page.',
// 				position: 'bl'
// 			}))

// 			console.error(error)
// 		})
// 	}
// }


export function globalCurrentDisplay(display_menu){
	return(dispatch)=>{
		return new Promise((resolve,reject)=>{
			resolve(dispatch(globalCurrentDisplaySuccess(display_menu)))
		})
	}
}


export function globalAddUiDisplayAdvanceFilter(display_advance_filter){
	return(dispatch)=>{
		return new Promise((resolve,reject)=>{
			resolve(dispatch(globalAddUiDisplayAdvanceFilterSuccess(display_advance_filter)))
		})
	}
}

export function globalAddUiDisplayColorPicker(display_color_picker){
	return(dispatch)=>{
		return new Promise((resolve,reject)=>{
			resolve(dispatch(globalAddUiDisplayColorPickerSuccess(display_color_picker)))
		})
	}
}

export function setIsFirstLogin(isFirstLogin) {
	return {
		type: 'IS_FIRST_LOGIN',
		isFirstLogin
	}
}

export function setIsFetched(isFetched) {
	return {
		type: 'IS_FETCHED',
		isFetched
	}
}
