import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import { List, fromJS } from 'immutable'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import { globalHideSweetAlert } from './global'

import { globalConcatImmutableData } from '../helpers/global'
import {setIsFetched} from './global'
import history from '../dependencies/history'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function customPlansFetchDataSuccess(companies) {
	return {
		type: 'CUSTOM_PLANS_FETCH_DATA_SUCCESS',
		companies
	}
}


export function customPlansHasErrored(bool) {
	return {
		type: 'CUSTOM_PLANS_HAS_ERRORED',
		hasErrored: bool
	}
}

export function customPlansAddFilterPageNumberSuccess(page) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function customPlansAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function customPlansAddFilterSortSuccess(sort) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function customPlansAddFilterNameSuccess(name) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_NAME_SUCCESS',
		name
	}
}


export function customPlansClearFilterDataSuccess() {
	return {
		type: 'CUSTOM_PLANS_CLEAR_FILTER_DATA_SUCCESS'
	}
}


export function customPlansClearFormDataSuccess() {
	return {
		type: 'CUSTOM_PLANS_CLEAR_FORM_DATA_SUCCESS'
	}
}

export function customPlansFetchIdsSuccess(ids) {
	return {
		type: 'CUSTOM_PLANS_FETCH_IDS_SUCCESS',
		ids
	}
}

export function customPlansFetchPaginationSuccess(pagination) {
	return {
		type: 'CUSTOM_PLANS_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function customPlansFetchDataDropdownSuccess(companies) {
	return {
		type: 'CUSTOM_PLANS_FETCH_DATA_DROPDOWN_SUCCESS',
		companies
	}
}

export function customPlansAddNavigationPage(page) {
	return {
		type: 'CUSTOM_PLANS_ADD_NAVIGATION_PAGE_SUCCESS',
		page
	}
}

export function customPlansSaveDataRequest(formName) {
	return {
		type: 'CUSTOM_PLANS_SAVE_DATA_REQUEST',
		formName
	}
}

export function customPlansValidateEmailOnUserRegisterWizardFormRequest(email) {
	return {
		type: 'CUSTOM_PLANS_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST',
		email
	}
}

export function customPlansAddFilterSearchSuccess(search) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}

export function customPlansAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterPageNumberSuccess(page)))
		})
	}
}

export function customPlansAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function customPlansAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterSortSuccess(sort)))
		})
	}
}

export function customPlansAddFilterName(name) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterNameSuccess(name)))
		})
	}
}



export function customPlansClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansClearFilterDataSuccess()))
		})
	}
}

export function customPlansAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterSearchSuccess(search)))
		})
	}
}

export function customPlansAddFilterStatus(status) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterStatusSuccess(status)))
		})
	}
}

export function customPlansAddFilterStatusSuccess(status) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_STATUS_SUCCESS',
		status
	}
}

export function customPlansAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(customPlansAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function customPlansAddFilterColPerPageSuccess(per_page){
	return{
		type:'CUSTOM_PLANS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

// Support Dashboard
export function customPlansFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(customPlansFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/companies/filter`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const companyIds = response.data.data.ids
			const companies = response.data.data.data
			const pagination = omit(response.data.data, 'data')
			dispatch(customPlansFetchIdsSuccess(companyIds))
			dispatch(customPlansFetchPaginationSuccess(pagination))
			dispatch(customPlansFetchDataSuccess(companies))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(customPlansFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function handleUpdateStatus(companyId, status, appointmentId, channel='talk to us') {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/set-plan-status`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {appointment_id: appointmentId, status: status, channel: channel}
		})
		.then((response) => {
			//update total notofocation when status = 3 (Done)
			if(status==3){
				dispatch(updateTotalAccountRequiringAction())
			}
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function customPlansAddFilterChannel(status) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(customPlansAddFilterChannelSuccess(status)))
		})
	}
}

export function customPlansAddFilterChannelSuccess(status) {
	return {
		type: 'CUSTOM_PLANS_ADD_FILTER_CHANNEL_SUCCESS',
		status
	}
}

export function updateTotalAccountRequiringAction(total=1) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(updateTotalAccountRequiringActionSuccess(total)))
		})
	}
}

export function updateTotalAccountRequiringActionSuccess(total) {
	return {
		type: 'UPDATE_TOTAL_ACCOUNT_REQUIRING_ACTION',
		total
	}
}






