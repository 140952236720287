// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { List, Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../../components/partials/PaginationSupport'

import {
	userLevelDelete,
	userLevelsFetchData,
	userLevelsAddFilterPageNumber,
	userLevelsAddFilterSort,
	userLevelsAddFilterSearch,
	usersClearFormDataSuccess,
	userLevelsAddFilterColPerPage,
	usersAddFilterSelectedIds,
	userLevelsClearFilterData
} from '../../actions/users'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert
} from '../../actions/global'

import TableLoader from '../partials/TableLoader'

require('es6-promise').polyfill()

type Props = {
	pathName: string,
	jobId: string,
	featureListCompany: Map<string, any>,
	companyId: string,
	company: Map<string, any>,
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	users: List<Map<string, any>>,
	userLevelsPagination: Map<string, any>,
	userLevelsFilterData: Map<string, any>,
	userLevelsFetchData: Function,
	userLevelsAddFilterPageNumber: Function,
	userLevelsAddFilterSort: Function,
	userLevelsAddFilterSearch: Function,
	usersClearFormDataSuccess: Function,
	globalIndicatorData: Map<string, any>,
	userLevelsAddFilterColPerPage: Function,
	usersAddFilterSelectedIds: Function,
	userLevelDelete: Function,
	userLevelsClearFilterData: Function,
};

type State = {
	isShown: boolean
};

class UserLevel extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selected_ids:[]
		}

		bindAll(this, [
			'actionFormatter',
			'featureFormatter',
			'handleChangePageSize',
			'handleSearchByName',
			'handleSelectRow',
			'handleSelectAll',
			'handleDeleteUser',
			'handleSortChange',
			'handleEditClick'
		])
	}

	componentDidMount() {
		const {
			userLevelsFetchData,
			userLevelsClearFilterData
		} = this.props

		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'user-level-detail'){
			userLevelsFetchData(this.props.userLevelsFilterData)
		}else{
			userLevelsClearFilterData()
			.then(()=>{
				userLevelsFetchData(this.props.userLevelsFilterData)
			})
		}
	}

	handleChangePageSize(e): void{
		const{
			userLevelsAddFilterPageNumber,
			userLevelsFetchData,
			userLevelsAddFilterColPerPage,
		}=this.props
		const countFilter = e.target.value
		userLevelsAddFilterPageNumber(1)
		.then(()=>{
			userLevelsAddFilterColPerPage(countFilter)
			.then(()=>{
				userLevelsFetchData(this.props.userLevelsFilterData)
			})
		})

	}

	handleSearchByName(event){
		const{
			userLevelsAddFilterPageNumber,
			userLevelsFetchData,
			userLevelsAddFilterSearch
		}=this.props

		let fullname=event.target.value
		if (event.charCode === 13) {
			userLevelsAddFilterPageNumber(1).then(()=>{
				userLevelsAddFilterSearch(fullname).then(()=>{
					userLevelsFetchData(this.props.userLevelsFilterData)
				})
			})
    	}
	}

	actionFormatter(cell: Object, row: Object): Object {
		const {userPrivilege} = this.props
		let privilege = userPrivilege.filter(item => ['UPDATE_USER_LEVEL','DELETE_USER_LEVEL'].includes(item));
		
		if(privilege.length<1){
			return null
		}
		
	  	return <div className="dropdown">
					<a style={{cursor:'pointer'}} data-toggle="dropdown"><i className="fa fa-ellipsis-h" style={{color: 'black',fontSize: '14px'}}></i></a>
					<ul className="dropdown-menu pull-right">
						{
							userPrivilege.includes('UPDATE_USER_LEVEL') &&
							<li><a onClick={(e)=>this.handleEditClick(row.id)} style={{color: '#404040',padding: '10px 15px'}}>Edit</a></li>
						}
						{
							userPrivilege.includes('DELETE_USER_LEVEL') &&
							<li><a href="" onClick={(e)=>this.handleDeleteUser(e,row.id)} style={{color: '#ED2453',padding: '10px 15px'}}>Delete</a></li>
						}
					</ul>
				</div>
	}

	featureFormatter(cell: Object, row: Object): Object {
		if(row.feature_lists!=null){

	  	return row.feature_lists.join(', ')
		}
		return ''
	}

	handleSelectRow(row, isSelected){
		const {
			userLevelsFilterData,
			usersAddFilterSelectedIds
		} = this.props

		let ids=userLevelsFilterData.get('selected_ids').toJS()
		if(isSelected){
			ids.push(row.id);
		}else{
			ids=ids.filter(id => id !== row.id);
		}

		usersAddFilterSelectedIds(ids)
	}

	handleSelectAll(isSelected, rows){
		const {
			userLevelsFilterData,
			usersAddFilterSelectedIds
		} = this.props

		let ids=userLevelsFilterData.get('selected_ids').toJS()
		const selectedIds = rows.map(({ id }) => id)
		if(isSelected){
			ids = ids.concat(selectedIds)
		}else{
			ids=ids.filter(id => !selectedIds.includes(id))
		}
		usersAddFilterSelectedIds(ids)
	}

	handleDeleteUser(e, userId){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			userLevelDelete,
			userLevelsFetchData,
		} = this.props
		let userIds = userId
		if(typeof userIds == 'number'){
			userIds = [userId]
		}
		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Delete User`,
			text: 'Are you sure to delete this user level?',
			onConfirm: () => {
				userLevelDelete(userIds).then(()=>{
					userLevelsFetchData(this.props.userLevelsFilterData)
					globalAddSweetAlertData({
						show: true,
						title: 'Success!',
						text: "Success Delete User Level",
						confirmButtonText: 'OK',
						confirmButtonColor: '#564FDB',
						showLoaderOnConfirm: true,
						onConfirm: () => {
							globalHideSweetAlert()
						}
					})
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleSortChange(name, order){
		const {
			userLevelsFetchData,
			userLevelsAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		userLevelsAddFilterSort(sort).then(()=>userLevelsFetchData(this.props.userLevelsFilterData))

	}

	handleEditClick(userId){
		this.props.history.push(`/support/user-level/${userId}/edit`);
	}

  	render() {
		const {
			userLevelList,
			userLevelsFetchData,
			userLevelsPagination,
			userLevelsFilterData,
			userLevelsAddFilterPageNumber,
			globalIndicatorData,
			userPrivilege
		} = this.props

		let reportPagination: ?Object

		if (!userLevelList.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={userLevelsPagination}
					fetchData={userLevelsFetchData}
					filterData={userLevelsFilterData}
					addPageNumber={userLevelsAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		const selectRowProp = {
		  mode: 'checkbox',
			clickToSelect: true,
			onSelect: this.handleSelectRow,
			onSelectAll: this.handleSelectAll
		};

		const displayOptions: Array<Object> = [
			{value: '10', label: '10'},
			{value: '20', label: '20'},
			{value: '30', label: '30'}
		]

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		};

    	return (
			<div className={isIndicatorClass}>
				<div className="div-support-container">
					<section className="wrapper">
						<div className="main-content">
							<div className="container-fluid">
								<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">User Level</h5>
										<div>
											{
												userPrivilege.includes('CREATE_USER_LEVEL') &&
												<Link to="/support/user-level/new" className="btn-support">Add New Level</Link>
											}
										</div>
									</div>
									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="status"
												id="status"
												value={userLevelsFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
											</select>
											Entries
										</div>

										<div className="flex">
											<div className="search-support-container horizontal-item--between m-r-16">
												<input className="search-support" placeholder="Search by level or feature list" defaultValue={this.props.userLevelsFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
									</div>

									<div className="report-list-container form-custom">
										<BootstrapTable
											data={userLevelList.toJS()}
											striped={true}
											selectRow={ selectRowProp }
											ref='table'
											options={options}
										>
											<TableHeaderColumn dataField="id" isKey={true} dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField="name" dataSort={true}>User Level</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.featureFormatter} >Feature List</TableHeaderColumn>
											<TableHeaderColumn width='50' dataFormat={this.actionFormatter} dataAlign='center' columnClassName="dropdown-user-support"></TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		pathName: ownProps.location.pathname,
		globalIndicatorData: state.globalIndicatorData,
		userLevelList: state.userLevelList,
		userLevelsPagination: state.userLevelsPagination,
		userLevelsFilterData: state.userLevelsFilterData,
		userPrivilege: state.userPrivilege,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		usersClearFormDataSuccess: () => dispatch(usersClearFormDataSuccess()),
		userLevelsFetchData: (filter_data) => dispatch(userLevelsFetchData(filter_data)),
		userLevelsAddFilterPageNumber: (page) => dispatch(userLevelsAddFilterPageNumber(page)),
		userLevelsAddFilterSort: (sort) => dispatch(userLevelsAddFilterSort(sort)),
		userLevelsAddFilterSearch: (name) => dispatch(userLevelsAddFilterSearch(name)),
		userLevelDelete: (user_ids) => dispatch(userLevelDelete(user_ids)),
		userLevelsAddFilterColPerPage: (per_page) => dispatch(userLevelsAddFilterColPerPage(per_page)),
		usersAddFilterSelectedIds: (ids) => dispatch(usersAddFilterSelectedIds(ids)),
		userLevelsClearFilterData: () => dispatch(userLevelsClearFilterData()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLevel)
