// @flow

import React from 'react'
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { List, Map } from 'immutable'
import moment from 'moment'
import capitalize from 'voca/capitalize'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../../components/partials/PaginationSupport'
import ModalVoucherAdvancedFilter from '../../components/partials/ModalVoucherAdvancedFilter'
import ModalComposeEmail from '../../components/partials/ModalComposeEmail'
import Notifications from 'react-notification-system-redux'
import $ from 'jquery'
import {
	usersGetDetailTempSuccess,
	usersAddFilterFullname,
	usersClearFormDataSuccess,
	usersActivate,
	resetUserPassword,
	usersFetchReceiver,
	usersFetchReceiverData,
} from '../../actions/users'

import {
	vouchersFetchData,
	vouchersClearFilterData,
	vouchersAddFilterPageNumber,
	vouchersAddFilterSearch,
	vouchersAddFilterSort,
	vouchersUsageFetchData,
	vouchersDelete,
	vouchersDeleteBulk,
	vouchersAddFilterColPerPage,
	vouchersAddFilterSelectedIds,
	vouchersAddFilterPlatform,
} from '../../actions/vouchers'

import {
	companiesGetDetail
} from '../../actions/companies'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
	globalActiveMenuTeamMember,
	globalActiveMenuHiringTeam,
} from '../../actions/global'

import axios from "../../dependencies/_axios";
import TableLoader from '../partials/TableLoader'
import initializers from '../../dependencies/initializers'

require('es6-promise').polyfill()

type Props = {
	pathName: string,
	jobId: string,
	featureListCompany: Map<string, any>,
	companyId: string,
	company: Map<string, any>,
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	globalActiveMenuHiringTeam: Function,
	globalActiveMenuTeamMember: Function,
	usersGetDetailTempSuccess: Function,
	vouchersDelete: Function,
	users: List<Map<string, any>>,
	user: Map<string, any>,
	userTemp: Map<string, any>,
	vouchersPagination: Map<string, any>,
	vouchersFilterData: Map<string, any>,
	vouchersFetchData: Function,
	vouchersAddFilterPageNumber: Function,
	vouchersAddFilterSort: Function,
	usersAddFilterFullname: Function,
	vouchersAddFilterSearch: Function,
	usersClearFormDataSuccess: Function,
	vouchersClearFilterData: Function,
	globalIndicatorData: Map<string, any>,
	vouchersAddFilterColPerPage: Function,
	vouchersAddFilterSelectedIds: Function,
	usersActivate: Function,
	resetUserPassword: Function,
	companiesGetDetail: Function,
	showErrorNotifications: Function,
	usersFetchReceiver: Function,
	usersFetchReceiverData: Function,
	vouchersAddFilterPlatform: Function,
};

type State = {
	isShown: boolean
};
const getClientTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
class VoucherList extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selected_ids:[],
			receiver:[],
			isDownloading: false,
			isFetched: false,
			active_tab:''
		}

		bindAll(this, [
			'handleSelectionActive',
			'handleSelectionDisable',
			'handleSelection',
			'actionFormatter',
			'statusFormatter',
			'handleChangePageSize',
			'handleSearchByName',
			'handleSelectRow',
			'handleSelectAll',
			'handleComposeEmail',
			'handleGetSelectedData',
			'handleDownloadVouchersData',
			'handleDeleteVouchers',
			'handleComposeEmailUser',
			'clearEmailReceiver',
			'handleVisitDashboard',
			'handleSortChange',
			'handleEditClick',
			'handleTabActive',
			'handleDeleteVouchersBulk',
			'handleViewDetailVoucher',
		])
	}

	handleTabActive(params){
		const{
			vouchersUsageFetchData,
			vouchersFetchData,
			vouchersClearFilterData
		}=this.props;

		this.setState({active_tab:params})
		if (params != 'usage') {
			vouchersClearFilterData()
			.then(()=>{
				vouchersFetchData(this.props.vouchersFilterData)
			})
			
		}else{
			vouchersClearFilterData()
			.then(()=>{
				vouchersUsageFetchData(this.props.vouchersFilterData)
			})
		}
	}

	componentDidMount() {
		const {
			vouchersFetchData,
			vouchersClearFilterData
		} = this.props
		
		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'user-detail'){
			vouchersFetchData(this.props.vouchersFilterData)
		}else{
			vouchersClearFilterData()
			.then(()=>{
				vouchersFetchData(this.props.vouchersFilterData)
			})
		}
		
	}

	handleSelectionActive(e: Object): void {
		e.preventDefault()

		this.setState({isShown: true})
	}

	handleSelectionDisable(e: Object): void {
		e.preventDefault()

		this.setState({isShown: false})
	}

	handleSelection(): Object {
		if (this.state.isShown) {
			return { display: 'block' }
		} else {
			return { display: 'none' }
		}
	}

	handleDeleteVouchers(e, voucherId){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			vouchersDelete,
			vouchersFetchData
		} = this.props
		let voucherIds = voucherId
		if(typeof voucherIds == 'number'){
			voucherIds = [voucherId]
		}
		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Delete Voucher?`,
			text: "You will delete this voucher and you're not able to restore it.",
			onConfirm: () => {
				vouchersDelete(voucherIds).then(()=>{
					vouchersFetchData(this.props.vouchersFilterData)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleDeleteVouchersBulk(e, voucherId){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			vouchersDeleteBulk,
			vouchersFetchData,
			vouchersFilterData,
		} = this.props
		// let voucherIds = voucherId
		let ids=vouchersFilterData.get('selected_ids').toJS()
		let voucherIds = ids
		// if(typeof voucherIds == 'number'){
		// 	voucherIds = [voucherId]
		// }
		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Delete Voucher?`,
			text: `You will delete ${voucherIds.length} voucher and you're not able to restore it.`,
			onConfirm: () => {
				vouchersDeleteBulk(voucherIds).then(()=>{
					vouchersFetchData(this.props.vouchersFilterData)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}


	

	

	handleChangePageSize(e): void{
		const{
			vouchersAddFilterPageNumber,
			vouchersFetchData,
			vouchersAddFilterColPerPage,
			vouchersUsageFetchData,
		}=this.props
		const countFilter = e.target.value
		vouchersAddFilterPageNumber(1)
		.then(()=>{
			vouchersAddFilterColPerPage(countFilter)
			.then(()=>{
				if (this.state.active_tab != 'usage') {
					vouchersFetchData(this.props.vouchersFilterData)
				}else{
					vouchersUsageFetchData(this.props.vouchersFilterData)
				}
			})
		})

	}

	handleSearchByName(event){
		const{
			vouchersAddFilterPageNumber,
			vouchersFetchData,
			vouchersAddFilterSearch,
			vouchersUsageFetchData
		}=this.props

		let keywords=event.target.value
		if (event.charCode === 13) {
			vouchersAddFilterPageNumber(1).then(()=>{
				vouchersAddFilterSearch(keywords).then(()=>{
					if (this.state.active_tab != 'usage') {
						vouchersFetchData(this.props.vouchersFilterData)
					}else{
						vouchersUsageFetchData(this.props.vouchersFilterData)
					}
					
				})
			})
		}
	}

	actionFormatter(cell: Object, row: Object): Object {
		const {
			userPrivilege, 
			userAuth
		} = this.props

		// let itSelf=userAuth.get('id')==row.id ? true : false
		let myPrivilege=['UPDATE_USER','ACTIVATE_USER','RESET_USERS_PASSWORD','VISIT_USERS_DASHBOARD','SEND_EMAIL_TO_USERS','DELETE_USERS']
		let res = userPrivilege.filter(item => myPrivilege.includes(item));
		
		if(res.length<1){
			return null
		}

		let impersonateUrl = `${initializers.api_host}/support-dashboard/user/${row.id}/${row.company_id}/impersonate`
	  	return <div className="dropdown">
					<a style={{cursor:'pointer'}} data-toggle="dropdown"><i className="fa fa-ellipsis-h" style={{color: 'black',fontSize: '14px'}}></i></a> 
					<ul className="dropdown-menu pull-right">
						{
							
							<li><a onClick={
								(e)=>
									this.handleViewDetailVoucher(row.id)
							} style={{color: '#404040',padding: '10px 15px'}}>View</a></li>
						}
						{
							(userPrivilege.includes('EDIT_VOUCHER') && row.admin_level != 1) &&
							<li><a onClick={(e)=>this.handleEditClick(row.id)} style={{color: '#404040',padding: '10px 15px'}}>Edit</a></li>
						}
						{
							(userPrivilege.includes('DELETE_VOUCHER') && row.admin_level != 1) &&
							<li><a href="" onClick={(e)=>this.handleDeleteVouchers(e,row.id)} style={{color: '#ED2453',padding: '10px 15px'}}>Delete</a></li>
						}
					</ul>
	  			</div>
	}

	statusFormatter(cell: Object, row: Object): Object {
		switch (row.status) {
			case 'active':
				return <span className={'text-green'}>{capitalize(row.status)}</span>
				
			case 'expired':
				return <span className={'text-red'}>{capitalize(row.status)}</span>
		
			default:
				return <span className={'text-blue'}>{capitalize(row.status)}</span>
			

		}
	
	}

	typeFormatter(cell:Object, row:Object):Object{
		switch (row.discount_type) {
			case 'percent':
				return 'Percentage'
				
			case 'amount':
				return 'Amount'
			default:
				return row.discount_type
		}


	}

	valueFormatter(cell:Object, row:Object):Object{
		if (row.discount_type.toLowerCase() == 'percent') {
				return `${parseInt(row.discount_value).toFixed()} %`
		}else {
			
			if(row.currency.toLowerCase() == 'idr'){
				const currency = new Intl.NumberFormat('de-DE').format(parseInt(row.discount_value).toFixed()); 
				return `Rp ${currency}`
			}else{
				return `${row.currency.toUpperCase()} ${parseInt(row.discount_value).toFixed()}`
			}
		}

	}

	voucherRedeemerFormatter( row:Object):Object{
		console.log(row.account_name)
		if (row.platform == 'popskul') {
			
			return `${row.account_name}`
		}
		if (row.platform == 'astrnt') {
				return `${row.company_id} - ${row.company_name}`
		}

		
	}
	
	dateFormatter(row,issued=null):Object{
		if (row) {
			const utcDate = moment(row);
    
			// Convert to local timezone
		
			
			// Format the date as "MMM DD, YYYY"
			if (issued) {
				const utc_date = moment.utc(row);
				const localDate = utc_date.local();
				console.log(localDate,'localdate')
				return localDate.format("MMM D, YYYY hh:mm A");

			}
			return utcDate.format("MMM DD, YYYY");

			
		}



	}

	handleViewDetailVoucher(id){
		const{history,vouchersAddFilterSelectedIds,vouchersUsageFetchData} = this.props
		vouchersAddFilterSelectedIds([id])
		.then(()=>{
			vouchersUsageFetchData(this.props.vouchersFilterData)
			this.props.history.push(`/support/voucher/${id}`)
		})
	}

	handleSelectRow(row, isSelected){
		const {
			vouchersFilterData,
			vouchersAddFilterSelectedIds
		} = this.props

		let ids=vouchersFilterData.get('selected_ids').toJS()
		if(isSelected){
			ids.push(row.id);
		}else{
			ids=ids.filter(id => id !== row.id);
		}
		console.log(ids,'ids')
		vouchersAddFilterSelectedIds(ids)
	}

	handleSelectAll(isSelected, rows){
		const {
			vouchersFilterData,
			vouchersAddFilterSelectedIds
		} = this.props

		let ids=vouchersFilterData.get('selected_ids').toJS()
		const selectedIds = rows.map(({ id }) => id)
		if(isSelected){
			ids = ids.concat(selectedIds)
		}else{
			ids=ids.filter(id => !selectedIds.includes(id))
		}

		vouchersAddFilterSelectedIds(ids)
	}

	handleComposeEmail(e: Object){
		e.preventDefault()
		const {
			vouchersFilterData,
			usersFetchReceiver,
		} = this.props

		this.clearEmailReceiver()
		
		usersFetchReceiver(vouchersFilterData).then(()=>{
			this.setState({receiver:this.props.receivers.toJS()})
			$('#modalComposeEmail').modal('show')
		})
		
	}

	handleComposeEmailUser(e: Object, id){
		e.preventDefault()
		const {
			users,
		} = this.props
		this.clearEmailReceiver()
		let receiverFilter =users.toJS().find((user)=> user.id===id)
		this.setState({receiver:[{label:receiverFilter.email,value:receiverFilter.email}]})
		$('#modalComposeEmail').modal('show')
	}

	clearEmailReceiver(){
		this.props.usersFetchReceiverData([])
		this.setState({receiver:[]})
	}

	handleGetSelectedData(e: Object){
		e.preventDefault()
		if(this.props.vouchersFilterData.get('platform') == ''){
			this.props.vouchersAddFilterPlatform('astrnt')
		}
		$('#ModalVoucherAdvancedFilter').modal('show')
	}

	handleDownloadVouchersData(e){
		e.preventDefault()
		const {
			showErrorNotifications,
			vouchersFilterData
		} = this.props
		this.setState({isDownloading:true})
		const _data = vouchersFilterData.toJS()
		_data.timezone = getClientTimezone();
		axios({
			url: `/support-dashboard/vouchers/export`,
			method: 'post',
			timeout: 60000,
			responseType: 'blob',
			crossDomain: true,
			data: _data
		}).then((response) => {
			setTimeout(()=>{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a')
				link.href = url
				const formattedDate = moment().format('DD-MM-YYYY');
				link.setAttribute('download', `${'Voucher Data And Usage '}${formattedDate}.xlsx`)
				document.body.appendChild(link);
				link.click()
				this.setState({isDownloading:false})
			}, 3000)
		})
		.catch((error)=>{
			let errorMessage = 'Please contact account holder'
			showErrorNotifications(
				'Download Failed',
				errorMessage,
				'bl'
			)
			this.setState({isDownloading:false})
		})
	}

	companyFormatter(cell: Object, row: Object): Object {
	  	return `${row.company_id} - ${row.company_name}`
	}

	handleVisitDashboard(e, url){
		window.open(url,'_blank')
	}

	handleSortChange(name, order){
		const {
			vouchersFetchData,
			vouchersAddFilterSort,
			vouchersUsageFetchData,
		} = this.props

		let sort={
			name: name,
			value: order
		}

		vouchersAddFilterSort(sort).then(()=>
		{
			if (this.state.active_tab != 'usage') {
				vouchersFetchData(this.props.vouchersFilterData)
			}else{
				vouchersUsageFetchData(this.props.vouchersFilterData)
			}
		}
	)

	}

	handleEditClick(voucherId){
		this.props.history.push(`/support/voucher/${voucherId}/edit`);
	}
	
	purifyData(data){
		const sanitize_data = data.map((value,index)=>{
			 value.company_name = renderToString(value.company_name)
			 value.name = renderToString(value.name)
			 value.preferred_name = renderToString(value.preferred_name)
			 value.email = renderToString(value.email)
			 return value
		})

		return sanitize_data;
	}

	IssuerFormatter(row){
		if (row.platform == 'popskul') {
			return row.account_email
		}else{
			return row.email
		}
	}

  	render() {
		const {
			vouchers,
			vouchersFetchData,
			vouchersPagination,
			vouchersFilterData,
			vouchersAddFilterPageNumber,
			vouchersUsageFetchData,
			globalIndicatorData,
			userPrivilege
		} = this.props
		
		// console.log(users.toJS())

		let reportPagination: ?Object

		if (!vouchers.isEmpty()) {
			reportPagination =
			this.state.active_tab != 'usage' ?
				<PaginationSupport
					pagination={vouchersPagination}
					fetchData={vouchersFetchData}
					filterData={vouchersFilterData}
					addPageNumber={vouchersAddFilterPageNumber} />
					: 	
					<PaginationSupport
					pagination={vouchersPagination}
					fetchData={vouchersUsageFetchData}
					filterData={vouchersFilterData}
					addPageNumber={vouchersAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''

		const selectRowProp = {
		  mode: 'checkbox',
			onSelect: this.handleSelectRow,
			onSelectAll: this.handleSelectAll,
			selected:vouchersFilterData.get('selected_ids').toJS() 
 		};

		const displayOptions: Array<Object> = [
			{value: '10', label: '10'},
			{value: '20', label: '20'},
			{value: '30', label: '30'}
		]

		const options = {
			onSortChange: this.handleSortChange,
			// noDataText: <TableLoader isFetched={this.props.isFetched}/>
			noDataText: 'There are no vouchers yet'
		};
		const optionsUsage = {
			onSortChange: this.handleSortChange,
			// noDataText: <TableLoader isFetched={this.props.isFetched}/>
			noDataText: 'There are no usage yet'
		};

    	return (
			<div className={isIndicatorClass}>
				<div className="div-support-container">
					<section className="wrapper">
						<div className="main-content">
							<div className="container-fluid">
								<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading m-b-0">Vouchers</h5>
										<div>
											<button disabled={this.state.isDownloading} className="btn-support btn-white m-r-16" onClick={this.handleDownloadVouchersData}><i className={`${this.state.isDownloading ? 'fa fa-spinner fa-pulse fa-fw':''}`}></i>Download Voucher Data</button>
											{
												userPrivilege.includes('CREATE_VOUCHER') &&
												<Link to="/support/voucher/new" className="btn-support">Create Voucher</Link>
											}
										</div>
									</div>
									<div className="tabs">
											<div onClick={(e)=>{this.handleTabActive('list')}} class={`tab ${this.state.active_tab != 'usage' ? 'active':''}`}>Voucher List</div>
											<div onClick={(e)=>{this.handleTabActive('usage')}}  class={`tab ${this.state.active_tab == 'usage' ? 'active':''}`}>Voucher Usage</div>
									</div>
									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="status"
												id="status"
												value={vouchersFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
											</select>
											entries
											{
												(this.state.active_tab != 'usage' && userPrivilege.includes('DELETE_VOUCHER') && vouchersFilterData.get('selected_ids').size > 0) &&
												<div className="m-l-24 inline-content">
													<button onClick={ this.handleDeleteVouchersBulk } className="btn-support-delete"> 
													<i className="fa fa-trash" />Bulk Delete</button>
												</div>
											}
										</div>

										<div className="flex">
											<div className="search-support-container horizontal-item--between m-r-16">
												<input className="search-support" placeholder="Search" defaultValue={this.props.vouchersFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
											<button onClick={ this.handleGetSelectedData } className="btn-support">Advanced Filter</button>
										</div>
									</div>

									<div className="report-list-container user-list form-custom table-condensed">
										{this.state.active_tab != 'usage' ?
												<BootstrapTable
												key={'vouchers-list'} 
												data={this.purifyData(vouchers.toJS())}
												striped={true}
												selectRow={ selectRowProp }
												ref='table'
												options = {options}
												noDataIndication={'there is no voucher yet'}
												containerStyle={ { width: 'fit-content'}}
												rowStyle={ { overflowWrap: 'break-word', wordBreak: 'break-all' } }
											>
												<TableHeaderColumn dataField="id" isKey={true} hidden>ID</TableHeaderColumn>
												<TableHeaderColumn dataSort={true} dataField="code" columnClassName="hov-expand">Voucher Code</TableHeaderColumn>
												<TableHeaderColumn width="130" dataFormat={this.typeFormatter} tdStyle={{maxWidth:'130px'}} thStyle={{maxWidth:'130px'}} dataField="discount_type" dataSort={true} columnClassName="hov-expand">Discount Type</TableHeaderColumn>
												<TableHeaderColumn width="130"dataFormat={this.valueFormatter} tdStyle={{maxWidth:'130px'}} thStyle={{maxWidth:'130px'}} dataField="discount_value"  columnClassName="hov-expand">Discount Value</TableHeaderColumn>
												<TableHeaderColumn dataField="product" dataSort={true} columnClassName="hov-expand">Applicable Product</TableHeaderColumn>
												<TableHeaderColumn width='100' dataField="max_user" dataSort={true}  columnClassName="hov-expand">Max Redemption</TableHeaderColumn>
												{/* <TableHeaderColumn dataFormat={this.companyFormatter} dataField="max_user" dataSort={true}  columnClassName="hov-expand">Max Redemption</TableHeaderColumn> */}
												
												<TableHeaderColumn width="130" dataFormat={(cell,row)=>this.dateFormatter(row.valid_from)} dataField="valid_from" dataSort={true}  columnClassName="hov-expand">Valid From</TableHeaderColumn>
												<TableHeaderColumn width='130' dataFormat={(cell,row)=>this.dateFormatter(row.valid_to)} dataField="valid_to" dataSort={true}  columnClassName="hov-expand">Valid To</TableHeaderColumn>
												{/* <TableHeaderColumn width='80' dataField="valid_to" dataFormat={this.statusFormatter} dataSort={true}  columnClassName="hov-expand">Valid To</TableHeaderColumn> */}
												
												<TableHeaderColumn width="70" dataFormat={this.statusFormatter} dataField="status" dataSort={true}  columnClassName="hov-expand">Status</TableHeaderColumn>
												<TableHeaderColumn width='50' dataFormat={this.actionFormatter} dataAlign='center' columnClassName="dropdown-user-support"></TableHeaderColumn>
											</BootstrapTable>
										
										:
											<BootstrapTable
												key={'usage-vouchers-data'} 
												data={this.purifyData(vouchers.toJS())}
												striped={true}
												selectRow={ selectRowProp }
												ref='table'
												options = {optionsUsage}
												containerStyle={ { width: 'fit-content'}}
												noDataText="No vouchers available"
												rowStyle={ { overflowWrap: 'break-word', wordBreak: 'break-all' } }
											>
												<TableHeaderColumn dataField="id" isKey={true} hidden>ID</TableHeaderColumn>
												<TableHeaderColumn width="150" dataField="code" columnClassName="hov-expand">Voucher Code</TableHeaderColumn>
												<TableHeaderColumn width="250" dataField="product" dataSort={true} columnClassName="hov-expand">Applicable Product</TableHeaderColumn>
												<TableHeaderColumn dataFormat={this.typeFormatter} width="100" dataField="discount_type" dataSort={true} columnClassName="hov-expand">Discount Type</TableHeaderColumn>
												<TableHeaderColumn dataFormat={this.valueFormatter} width="100" dataField="discount_value" dataSort={true} columnClassName="hov-expand">Discount Value</TableHeaderColumn>
		
												<TableHeaderColumn width='250' dataFormat={(cell,row)=>this.voucherRedeemerFormatter(row)} dataField="combined_name" dataSort={true}  columnClassName="hov-expand">Voucher Redemeer</TableHeaderColumn>
												{/* <TableHeaderColumn dataFormat={this.companyFormatter} dataField="max_user" dataSort={true}  columnClassName="hov-expand">Max Redemption</TableHeaderColumn> */}
												
												<TableHeaderColumn width="130" dataFormat={(cell,row)=>this.IssuerFormatter(row)} dataField="combined_email" dataSort={true}  columnClassName="hov-expand">Issuer</TableHeaderColumn>
												<TableHeaderColumn width='130' dataFormat={(cell,row)=>this.dateFormatter(row.created_at,'issued')} dataField="created_at" dataSort={true}  columnClassName="hov-expand">Issue Date</TableHeaderColumn>
											
											</BootstrapTable>
										}
										{reportPagination}
									</div>
								</div>
							</div>
						</div>
					</section>
					<ModalVoucherAdvancedFilter activeTab={this.state.active_tab}/>
					<ModalComposeEmail receiver={this.state.receiver} clearReceiver={this.clearEmailReceiver}/>
				</div>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		jobId: ownProps.match.params.jobId,
		pathName: ownProps.location.pathname,
		companyId: ownProps.match.params.companyId,
		featureListCompany: state.featureListCompany,
		globalIndicatorData: state.globalIndicatorData,
		company: state.company,
		user: state.user,
		vouchers: state.vouchers,
		userTemp: state.userTemp,
		vouchersPagination: state.vouchersPagination,
		vouchersFilterData: state.vouchersFilterData,
		companiesDropdown: state.companiesDropdown,
		userPrivilege: state.userPrivilege,
		userAuth: state.userAuth,
		receivers: state.receivers,
		isFetched: state.isFetched,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		globalActiveMenuTeamMember: () => dispatch(globalActiveMenuTeamMember()),
		globalActiveMenuHiringTeam: () => dispatch(globalActiveMenuHiringTeam()),
		usersClearFormDataSuccess: () => dispatch(usersClearFormDataSuccess()),
		usersGetDetailTempSuccess: (user) => dispatch(usersGetDetailTempSuccess(user)),
		vouchersFetchData: (filter_data) => dispatch(vouchersFetchData(filter_data)),
		vouchersUsageFetchData: (filter_data) => dispatch(vouchersUsageFetchData(filter_data)),
		vouchersAddFilterPageNumber: (page) => dispatch(vouchersAddFilterPageNumber(page)),
		vouchersAddFilterSort: (sort) => dispatch(vouchersAddFilterSort(sort)),
		usersAddFilterFullname: (fullname) => dispatch(usersAddFilterFullname(fullname)),
		vouchersAddFilterSearch: (keywords) => dispatch(vouchersAddFilterSearch(keywords)),
		vouchersDelete: (company_id, voucher_ids) => dispatch(vouchersDelete(company_id, voucher_ids)),
		vouchersDeleteBulk: (company_id, voucher_ids) => dispatch(vouchersDeleteBulk(company_id, voucher_ids)),
		vouchersAddFilterColPerPage: (per_page) => dispatch(vouchersAddFilterColPerPage(per_page)),
		vouchersAddFilterSelectedIds: (ids) => dispatch(vouchersAddFilterSelectedIds(ids)),
		vouchersClearFilterData: () => dispatch(vouchersClearFilterData()),
		usersActivate: (ids, is_active) => dispatch(usersActivate(ids, is_active)),
		resetUserPassword: (userId, companyId) => dispatch(resetUserPassword(userId, companyId)),
		companiesGetDetail: (companyId) => dispatch(companiesGetDetail(companyId)),
		showErrorNotifications: (title, message, position) => {
			return dispatch(Notifications.error({
			  title,
			  message,
				position
			}))
		},
		usersFetchReceiver: (filter) => dispatch(usersFetchReceiver(filter)),
		usersFetchReceiverData: (receivers) => dispatch(usersFetchReceiverData(receivers)),
		vouchersAddFilterPlatform: (platform) => dispatch(vouchersAddFilterPlatform(platform)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherList)
