// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map, List } from 'immutable'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import moment from 'moment'

import {
	vouchersFetchData,
	vouchersAddFilterPageNumber,
	vouchersAddFilterPlatform,
	vouchersAddFilterProduct,
	vouchersAddFilterProductValues,
	vouchersAddFilterCategory,
	vouchersAddFilterCategoryValues,
	vouchersAddFilterDiscountType,
	vouchersAddFilterDiscountValue,
	vouchersAddFilterCurrency,
	vouchersAddFilterMaxUser,
	vouchersAddFilterStatus,
	vouchersAddFilterValidFrom,
	vouchersAddFilterValidTo,
	voucherGetCertificates,
	vouchersClearFilterData,
	vouchersUsageFetchData,
} from '../../actions/vouchers'

import {
	globalAddSweetAlertData,
} from '../../actions/global'

import {
	getDropdownCompanies,
	companiesGetDetail
} from '../../actions/companies'

type Props = {
	vouchersFetchData: Function,
	vouchersAddFilterPageNumber: Function,
	vouchersAddFilterPlatform: Function,
	vouchersAddFilterProduct: Function,
	vouchersAddFilterProductValues: Function,
	vouchersAddFilterCategory: Function,
	vouchersAddFilterCategoryValues: Function,
	vouchersAddFilterDiscountType: Function,
	vouchersAddFilterDiscountValue: Function,
	vouchersAddFilterCurrency: Function,
	vouchersAddFilterMaxUser: Function,
	vouchersAddFilterValidFrom: Function,
	vouchersAddFilterValidTo: Function,
	vouchersAddFilterStatus: Function,
	getDropdownCompanies: Function,
	companiesGetDetail: Function,
	vouchersFilterData: Map<string, any>,
	voucherGetCertificates: Function,
	vouchersClearFilterData: Function,
	vouchersUsageFetchData: Function,
}

class ModalVoucherAdvancedFilter extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			value: [],
			valueCompanies: [],
			fromDate: '',
			toDate: '',
			isActive:1,
			selectedCompany:''
		}

		bindAll(this, [
			'handleSubmitFilter',
			'handleCloseModalClick',
			'handleSelectPlatform',
			'handleSelectProduct',
			'handleSelectProductValues',
			'handleSelectCategory',
			'handleSelectCategoryValues',
			'handleSelectDiscountType',
			'handleSelectCurrency',
			'handleChangeDiscountValue',
			'handleSelectMaxUser',
			'handleFromDateChange',
			'handleToDateChange',
			'handleSelectStatus',
		])
	}

	componentDidMount() {
		this.props.getDropdownCompanies({label_id: true})
		this.props.voucherGetCertificates({})
	}

	handleCloseModalClick(e: Object) {
		e.preventDefault()
		this.props.vouchersClearFilterData()
		$('#ModalVoucherAdvancedFilter').modal('hide')
	}




	handleSubmitFilter(e){
		e.preventDefault()
		const {
			vouchersFetchData,
			vouchersAddFilterPageNumber,
			vouchersAddFilterStatus,
			vouchersUsageFetchData,
			activeTab
		} = this.props
		
		vouchersAddFilterPageNumber(1).then(()=>{
			if(activeTab=='usage'){
				vouchersUsageFetchData(this.props.vouchersFilterData)
			}else{
				vouchersFetchData(this.props.vouchersFilterData)
			}
		})
		
		$('#ModalVoucherAdvancedFilter').modal('hide')
	}

	handleSelectPlatform(e, value) {
		const {
			vouchersAddFilterPlatform,
			vouchersClearFilterData
		} = this.props
		vouchersClearFilterData().then(()=>vouchersAddFilterPlatform(value))
	}

	handleSelectProduct(value) {
		const {
			vouchersAddFilterProduct,
			vouchersAddFilterProductValues
		} = this.props

		vouchersAddFilterProduct(value).then(()=>vouchersAddFilterProductValues(''))
	}

	handleSelectProductValues(value) {
		const {
			vouchersAddFilterProductValues
		} = this.props

		vouchersAddFilterProductValues(value)
	}
	handleSelectCategory(value) {
		const {
			vouchersAddFilterCategory,
			vouchersAddFilterCategoryValues
		} = this.props

		vouchersAddFilterCategory(value).then(()=>vouchersAddFilterCategoryValues(''))
	}
	handleSelectCategoryValues(value) {
		const {
			vouchersAddFilterCategoryValues
		} = this.props

		vouchersAddFilterCategoryValues(value)
	}
	handleSelectDiscountType(value) {
		const {
			vouchersAddFilterDiscountType,
			vouchersAddFilterCurrency,
			vouchersAddFilterDiscountValue
		} = this.props

		vouchersAddFilterDiscountType(value).then(()=>{
			vouchersAddFilterCurrency('').then(()=>{
				vouchersAddFilterDiscountValue('')
			})
		})
	}
	handleSelectCurrency(value) {
		const {
			vouchersAddFilterCurrency
		} = this.props

		vouchersAddFilterCurrency(value)
	}

	handleChangeDiscountValue(e) {
		const {
			vouchersAddFilterDiscountValue
		} = this.props

		vouchersAddFilterDiscountValue(e.target.value)
	}

	handleSelectMaxUser(value) {
		const {
			vouchersAddFilterMaxUser
		} = this.props

		vouchersAddFilterMaxUser(value)
	}

	handleFromDateChange(value) {
		const {
			vouchersAddFilterValidFrom
		} = this.props
		let date = value.format('YYYY-MM-DD')
		vouchersAddFilterValidFrom(date)
	}

	handleToDateChange(value) {
		const {
			vouchersAddFilterValidTo
		} = this.props
		let date = value.format('YYYY-MM-DD')
		vouchersAddFilterValidTo(date)
	}

	handleSelectStatus(value) {
		const {
			vouchersAddFilterStatus
		} = this.props

		vouchersAddFilterStatus(value)
	}

  	render() {
		const {
			vouchersFilterData,
			companiesDropdown,
			certificates
		} = this.props

  		let dropdownProduct=[]
		let dropdownCurrency=[
			{value:'idr',label:'IDR'},
			{value:'usd',label:'USD'}
		]
		let dropdownCategoryValues = [
			{value: "all", label: "All Account Segments"},
			{value: "Talent Acquisition", label: "Talent Acquisition", description:'Hire top talent efficiently. Make data-driven hiring decisions.'},
			{value: "Talent Management", label: "Talent Management", description:'Develop and retain high-performing employees. Build a strong workforce.'},
			{value: "Small Medium Enterprise", label: "Small Medium Enterprise", description:'Hire the right people to grow your business. Optimize hiring processes.'},
			{value: "University Admission", label: "University Admission", description:'Select qualified students for enrollment. Build a diverse student body.'},
			{value: "Career Development Center", label: "Career Development Center", description:'Prepare students for successful careers. Measure student outcomes.'}
		]

		if(vouchersFilterData.get('category')=='company'){
			dropdownCategoryValues = companiesDropdown.toJS()
			dropdownCategoryValues.unshift({value: "all", label: "All Companies"})
		}

		let categoryTitle = {
			'account-segment': 'Account Segment',
			'company': 'Company ID',
			'email': 'Email',
		}

		if(vouchersFilterData.get('platform')=='popskul'){
			dropdownProduct = [
				{value:'Competency Certification',label:'Competency Certification'},
				{value:'Courses',label:'Courses'}
			]
		}else{
			dropdownProduct = [
				{value:'Purchase Essential Monthly',label:'Purchase Essential Monthly'},
				{value:'Purchase Essential Yearly',label:'Purchase Essential Yearly'},
				{value:'Purchase Block Credit',label:'Purchase Block Credit'}
			]
		}

		let productValues = []

		if(vouchersFilterData.get('platform')=='popskul'){
			if(vouchersFilterData.get('product')=='Competency Certification'){
				productValues = certificates.toJS();
				productValues.unshift({value: "all", label: "All Competency Certification"})
			}else{
				productValues.unshift({value: "all", label: "All Courses"})
			}
		}

		let dropdownStatus = [
			{label:'All Status', value:''},
			{label:'Active', value:'active'},
			{label:'Upcoming', value:'upcoming'},
			{label:'Expired', value:'expired'}
		]

		let dropdownDiscountType = [
			{label:'Percentage', value:'percent'},
			{label:'Amount', value:'amount'},
			{label:'Fixed Price', value:'price'}
		]

		let dropdownCategory = [
			{label:'By Account Segment', value:'account-segment'},
			{label:'By Company ID', value:'company'},
			{label:'By Email', value:'email'}
		]
		console.log('VOUCHER FILTER DATA', vouchersFilterData.toJS());
		  
    	return (
      		<div className="modal custom-modal" id="ModalVoucherAdvancedFilter" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        		<div className="modal-dialog wrapper-modal-advanced-filter">
          			<div className="modal-content animated bounceInRight">
            			<div className="modal-header">
            				<label>Advanced Filter</label>
            			</div>
						<div className="modal-body">
							<div className="input-container">
								<label>Applicable Platform</label>
								<div className="toggle-container">
									<button className={`btn-status-toggle ${vouchersFilterData.get('platform') === 'astrnt' ? 'on':'off'}`} onClick={(e)=>this.handleSelectPlatform(e,'astrnt')}>ASTRNT</button>
									<button className={`btn-status-toggle ${vouchersFilterData.get('platform') === 'popskul' ? 'on':'off'}`} onClick={(e)=>this.handleSelectPlatform(e,'popskul')}>POPSkul</button>
								</div>
							</div>
							<div className="input-container">
								<label>Product</label>
								<Select
									closeOnSelect={true}
									options={dropdownProduct}
									onChange={this.handleSelectProduct}
									placeholder="Select Product"
				          			removeSelected={false}
									simpleValue
									value={vouchersFilterData.get('product')}
								/>
							</div>
							{
								(vouchersFilterData.get('platform') == 'popskul' && vouchersFilterData.get('product')) && 
								<div className="input-container">
									<label>{vouchersFilterData.get('product')}</label>
									<Select
										closeOnSelect={true}
										options={productValues}
										multi
										onChange={this.handleSelectProductValues}
										placeholder="Select Product"
										removeSelected={false}
										simpleValue
										value={vouchersFilterData.get('product_values')}
									/>
								</div>
							}

							{
								vouchersFilterData.get('platform') == 'astrnt' && 
								<div className="input-container">
									<label>Category</label>
									<Select
										closeOnSelect={true}
										options={dropdownCategory}
										onChange={this.handleSelectCategory}
										placeholder="Select Category"
										removeSelected={false}
										simpleValue
										value={vouchersFilterData.get('category')}
									/>
								</div>
							}
							{
								(['account-segment','company'].includes(vouchersFilterData.get('category')) && vouchersFilterData.get('platform') == 'astrnt') && 
								<div className="input-container">
									<label>{categoryTitle[vouchersFilterData.get('category')]}</label>
									<Select
										closeOnSelect={false}
										options={dropdownCategoryValues}
										onChange={this.handleSelectCategoryValues}
										placeholder={`- Select ${categoryTitle[vouchersFilterData.get('category')]} -`}
										removeSelected={true}
										simpleValue
										multi
										value={vouchersFilterData.get('category_values')}
									/>
								</div>
							}
							{
								(vouchersFilterData.get('category') == 'email' && vouchersFilterData.get('platform') == 'astrnt') && 
								<div className="input-container">
									<label>Email</label>
									<Select.Creatable
										className="select2 select-white"
										value={vouchersFilterData.get('category_values')}
										onChange={(newValue, delta, source,text) => {
											var temp = [] ;
											for (var i = 0; i < newValue.length; i++) {
												var new_value_with_space = newValue[i].value.split(" ");
												var new_value_with_comma = newValue[i].value.split(",");
												if(new_value_with_comma.length > 1){
													new_value_with_comma.map((value,index)=>{
														if(value != ''){
															temp.push({'className':'Select-create-option-placeholder','label':value.trim(),'value':value.trim()})
														}
													})
												}else if(new_value_with_space.length > 1){
													new_value_with_space.map((value,index)=>{
														if(value != ''){
															temp.push({'className':'Select-create-option-placeholder','label':value.trim(),'value':value.trim()})
														}
													})
												}else if(!newValue[i].value.includes(" ") && !newValue[i].value.includes(",") ){
															temp.push({'className':'Select-create-option-placeholder','label':newValue[i].value.trim(),'value':newValue[i].value.trim()})
												}

											}
											// input.onChange(temp);
											this.handleSelectCategoryValues(temp)
										}}
										// onBlur={() => input.onBlur(input.value)}
										clearable={true}
										autosize={false}
										multi={true}
									/>
								</div>
							}
							<div className="input-container">
								<label>Discount Type</label>
								<Select
									closeOnSelect={true}
									options={dropdownDiscountType}
									onChange={this.handleSelectDiscountType}
									placeholder="Select Discount Type"
				          			removeSelected={false}
									simpleValue
									value={vouchersFilterData.get('discount_type')}
								/>
							</div>
							{
								['amount','price'].includes(vouchersFilterData.get('discount_type')) &&  
								<div className="input-container">
									<label>Currency</label>
									<Select
										closeOnSelect={true}
										options={dropdownCurrency}
										onChange={this.handleSelectCurrency}
										placeholder="Select Currency"
										removeSelected={false}
										simpleValue
										value={vouchersFilterData.get('currency')}
									/>
								</div>
							}
							{
								['amount','price'].includes(vouchersFilterData.get('discount_type')) &&  
								<div className="input-container">
									<label>Discount Value</label>
									<input 
										className="input-advance-filter"
										type="number"
										placeholder="0"
										pattern="[0-9]*"
										step="1"
										onKeyPress={(event) => {
											if (!/[0-9]/.test(event.key)) {
												event.preventDefault();
											}
										}}
										onChange={this.handleChangeDiscountValue}
										value={vouchersFilterData.get('discount_value')}
									/>
								</div>
							}
							{
								['percent'].includes(vouchersFilterData.get('discount_type')) &&  
								<div className="input-container">
									<label>Discount Value</label>
									<div className="input-group">
										<input 
											className="input-advance-filter"
											type="number"
											placeholder="0"
											pattern="[0-9]*"
											step="0.01"
											onKeyPress={(event) => {
												if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
												}
											}}
											onChange={this.handleChangeDiscountValue}
											value={vouchersFilterData.get('discount_value')}
										/>
										<span class="input-group-addon" id="addon">%</span>
									</div>
								</div>
							}
							<div className="input-container">
								<label>Validity</label>
								<div className="datepicker-container horizontal-item--between">
									<div className="vertical-item--center">
										<span>From</span>
										<DatePicker
											dateFormat="DD/MM/YYYY"
											selected={vouchersFilterData.get('valid_from') == '' ? null : moment(vouchersFilterData.get('valid_from'))}
											onChange={this.handleFromDateChange}
											placeholderText="Select From Date"
											maxDate={vouchersFilterData.get('valid_to') ==''? null : moment(vouchersFilterData.get('valid_to'))}
										/>
									</div>
									<div className="vertical-item--center">
										<span>To</span>
										<DatePicker
											dateFormat="DD/MM/YYYY"
											selected={vouchersFilterData.get('valid_to') == '' ? null : moment(vouchersFilterData.get('valid_to'))}
											onChange={this.handleToDateChange}
											placeholderText="Select To Date" 
											minDate={vouchersFilterData.get('valid_from') == ''? null : moment(vouchersFilterData.get('valid_from'))}
										/>
									</div>
								</div>
							</div>
							<div className="input-container">
								<label>Status</label>
								<Select
									closeOnSelect={true}
									options={dropdownStatus }
									onChange={this.handleSelectStatus}
									placeholder="Select Status"
				          			removeSelected={false}
									simpleValue
									value={vouchersFilterData.get('status')}
								/>
							</div>
						</div>
						<div className="modal-footer">
							<button className="button button-cancel m-r-16" onClick={this.handleCloseModalClick}>Cancel</button>
							<button className="button button-apply" onClick={(e)=>this.handleSubmitFilter(e)}>Apply</button>
						</div>
          			</div>
        		</div>
      		</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		vouchersFilterData: state.vouchersFilterData,
		companiesDropdown: state.companiesDropdown,
		certificates: state.certificates,
		activeTab:ownProps.activeTab
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		vouchersAddFilterPageNumber: (page) => dispatch(vouchersAddFilterPageNumber(page)),
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		vouchersFetchData: (filter) => dispatch(vouchersFetchData(filter)),
		vouchersUsageFetchData: (filter) => dispatch(vouchersUsageFetchData(filter)),
		vouchersAddFilterPlatform: (platform) => dispatch(vouchersAddFilterPlatform(platform)),
		vouchersAddFilterProduct: (product) => dispatch(vouchersAddFilterProduct(product)),
		vouchersAddFilterProductValues: (product_values) => dispatch(vouchersAddFilterProductValues(product_values)),
		vouchersAddFilterCategory: (category_values) => dispatch(vouchersAddFilterCategory(category_values)),
		vouchersAddFilterCategoryValues: (category_values) => dispatch(vouchersAddFilterCategoryValues(category_values)),
		vouchersAddFilterDiscountType: (discount_type) => dispatch(vouchersAddFilterDiscountType(discount_type)),
		vouchersAddFilterDiscountValue: (discount_value) => dispatch(vouchersAddFilterDiscountValue(discount_value)),
		vouchersAddFilterCurrency: (currency) => dispatch(vouchersAddFilterCurrency(currency)),
		vouchersAddFilterMaxUser: (max_user) => dispatch(vouchersAddFilterMaxUser(max_user)),
		vouchersAddFilterValidFrom: (from) => dispatch(vouchersAddFilterValidFrom(from)),
		vouchersAddFilterValidTo: (to) => dispatch(vouchersAddFilterValidTo(to)),
		vouchersAddFilterStatus: (status) => dispatch(vouchersAddFilterStatus(status)),
		vouchersClearFilterData: () => dispatch(vouchersClearFilterData()),
		getDropdownCompanies: (params) => dispatch(getDropdownCompanies(params)),
		voucherGetCertificates: (params) => dispatch(voucherGetCertificates(params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalVoucherAdvancedFilter)
