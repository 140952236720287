// @flow

import React from 'react'
import { Link } from  'react-router-dom'
import bindAll from 'lodash/bindAll'

require('es6-promise').polyfill()

type Props = {
};

class PlanRequestNotificationBaner extends React.Component<Props> {
	constructor(props: Object) {
		super(props)
		bindAll(this, [
		])
	}



	render() {
		const {
			information
		} = this.props
		let total = information.get('plan_request') + information.get('popskul_request') 
		
		if(total){
			return(
				<div className="plan-request-container" id="plan-request-baner">
					<div id="planRequest" className="info">
						<i className="fa fa-info-circle" /><span>You have <b>{total } new request</b> 
						{ information.get('plan_request') > 0 &&
							<Link to={`/support/custom-plan`} className="btn-notif">View Accounts Requiring Actions</Link>
						}
						{ information.get('popskul_request') > 0 &&
							<Link to={`/support/popskul-request`} className="btn-notif">View Popskul Requiring Actions</Link>
						}
						</span>
					</div>
				</div>
			)
			
		}else{
			return null
		}
	}
}

export default PlanRequestNotificationBaner
