// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map, List } from 'immutable'

type Props = {
}

class ModalLeavePage extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleSubmitFilter',
		])
	}

	componentDidMount() {}

	handleCloseModalClick(e: Object) {
		e.preventDefault()

		$('#modalLeavePage').modal('hide')
	}

	handleSubmitFilter(e){
		e.preventDefault()
		
		this.handleCloseModalClick(e)
	}

  	render() {
		const {
		} = this.props
		  
    	return (
      		<div className="modal custom-modal" id="modalLeavePage" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        		<div className="modal-dialog wrapper-modal-download-all">
          			<div className="modal-content animated bounceInRight">
            			<div className="modal-header">
            				<label>Leave Page?</label>
            			</div>
						<div className="modal-body">
							<div className="input-container">
								<p>Your data on Commercial Metric will not be saved once you leave this page without click the save button.</p>
							</div>
						</div>
						<div className="modal-footer">
							<button className="button button-cancel m-r-16" onClick={this.handleCloseModalClick}>Leave</button>
							<button className="button button-save" onClick={this.handleCloseModalClick}>Cancel</button>
						</div>
          			</div>
        		</div>
      		</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLeavePage)
