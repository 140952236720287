import { List, fromJS } from 'immutable'

export function userLevels(state = List([]), action) {
	switch (action.type) {
		case 'USER_LEVELS_FETCH_DATA_SUCCESS':
			return fromJS(action.user_levels)
		default:
			return state
	}
}
