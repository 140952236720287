import axios from 'axios'
import configureStore from '../store/configureStore'
import initializers from './initializers'
import { setHttpHeaders } from '../helpers/global'
import { Map } from 'immutable'
import UAParser from 'ua-parser-js'

const parser = new UAParser()
const device = parser.getDevice()
const os = parser.getOS()
const browser = parser.getBrowser()

export default axios
  .create({
    baseURL: initializers.api_host,
    timeout: 20000,
    headers:{
      device: '',
      os: `${os.name} ${os.version}`,
      browser: `${browser.name} ${browser.version}`,
      screenresolution: `${window.screen.availHeight}x${window.screen.availWidth}`,
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
})


