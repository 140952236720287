import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import { globalHideSweetAlert } from './global'
import moment from 'moment'
import {setIsFetched} from './global'
import history from '../dependencies/history'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function companiesFetchDataSuccess(companies) {
	return {
		type: 'COMPANIES_FETCH_DATA_SUCCESS',
		companies
	}
}

export function companiesFetchDataHiringTeamSuccess(companies) {
	return {
		type: 'COMPANIES_FETCH_DATA_HIRING_TEAM_SUCCESS',
		companies
	}
}

export function companiesGetDetailSuccess(company) {
	return {
		type: 'COMPANIES_GET_DETAIL_SUCCESS',
		company
	}
}

export function companiesGetCreditSuccess(credit) {
	return {
		type: 'COMPANIES_GET_CREDIT_SUCCESS',
		credit
	}
}

export function companiesGetDetailTempSuccess(company) {
	return {
		type: 'COMPANIES_GET_DETAIL_TEMP_SUCCESS',
		company
	}
}

export function companiesHasErrored(bool) {
	return {
		type: 'COMPANIES_HAS_ERRORED',
		hasErrored: bool
	}
}

export function companiesSaveDataError(errors) {
	return {
		type: 'COMPANIES_SAVE_DATA_ERROR',
		errors
	}
}

export function companiesAddFilterPageNumberSuccess(page) {
	return {
		type: 'COMPANIES_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function companiesAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'COMPANIES_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function companiesAddFilterSortSuccess(sort) {
	return {
		type: 'COMPANIES_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function companiesAddFilterNameSuccess(name) {
	return {
		type: 'COMPANIES_ADD_FILTER_NAME_SUCCESS',
		name
	}
}


export function companiesClearFilterDataSuccess() {
	return {
		type: 'COMPANIES_CLEAR_FILTER_DATA_SUCCESS'
	}
}


export function companiesClearFormDataSuccess() {
	return {
		type: 'COMPANIES_CLEAR_FORM_DATA_SUCCESS'
	}
}

export function companiesFetchIdsSuccess(ids) {
	return {
		type: 'COMPANIES_FETCH_IDS_SUCCESS',
		ids
	}
}

export function companiesFetchPaginationSuccess(pagination) {
	return {
		type: 'COMPANIES_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function companiesFetchDataDropdownSuccess(companies) {
	return {
		type: 'COMPANIES_FETCH_DATA_DROPDOWN_SUCCESS',
		companies
	}
}

export function companiesAddNavigationPage(page) {
	return {
		type: 'COMPANIES_ADD_NAVIGATION_PAGE_SUCCESS',
		page
	}
}

export function companiesSaveDataRequest(formName) {
	return {
		type: 'COMPANIES_SAVE_DATA_REQUEST',
		formName
	}
}

export function companiesValidateEmailOnUserRegisterWizardFormRequest(email) {
	return {
		type: 'COMPANIES_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST',
		email
	}
}

export function companiesAddFilterSearchSuccess(search) {
	return {
		type: 'COMPANIES_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}

export function companiesAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterPageNumberSuccess(page)))
		})
	}
}

export function companiesAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function companiesAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterSortSuccess(sort)))
		})
	}
}

export function companiesAddFilterName(name) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterNameSuccess(name)))
		})
	}
}



export function companiesClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesClearFilterDataSuccess()))
		})
	}
}

export function companiesAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterSearchSuccess(search)))
		})
	}
}

export function companiesAddFilterStatus(status) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterStatusSuccess(status)))
		})
	}
}

export function companiesAddFilterStatusSuccess(status) {
	return {
		type: 'COMPANIES_ADD_FILTER_STATUS_SUCCESS',
		status
	}
}

export function companiesAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(companiesAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function companiesAddFilterColPerPageSuccess(per_page){
	return{
		type:'COMPANIES_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

// Support Dashboard
export function companiesFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(companiesFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/companies/filter`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const companyIds = response.data.data.ids
			const companies = response.data.data.data
			const pagination = omit(response.data.data, 'data')
			dispatch(companiesFetchIdsSuccess(companyIds))
			dispatch(companiesFetchPaginationSuccess(pagination))
			dispatch(companiesFetchDataSuccess(companies))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(companiesFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}


// When we need to assign user to temporary data like on edit team member, set temp parameter to true
export function companiesGetDetailTemp(company_id, user_id) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/companies/${company_id}/team-member/${user_id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()

			const user = response.data
			dispatch(companiesGetDetailTempSuccess(user))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user.',
				position: 'bl'
			}))
		})
	}
}

export function companiesCreateData(companyId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/companies/${companyId}/team-member`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data.toJS()
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Team member successfully created.',
				position: 'bl'
			}))

			dispatch(reset('ModalAddMember'))
		})
		.catch((error) => {
			NProgress.done()
			if(typeof error.response.data.message != 'undefined'){
				dispatch(Notifications.error({
					title: 'Error',
					message: 'something wrong with input',
					position: 'bl'
				}))
			}else{
				dispatch(Notifications.error({
					title: 'Error',
					message: 'An error occured when creating team member.',
					position: 'bl'
				}))
			}


			dispatch(companiesSaveDataError(error.response.data.message))
		})
	}
}

export function companiesUpdateData(companyId, userId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: `/companies/${companyId}/team-member/${userId}`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data.toJS()
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Team member successfully updated.',
				position: 'bl'
			}))

			dispatch(reset('ModalAddMember'))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating team member.',
				position: 'bl'
			}))

			dispatch(companiesSaveDataError(error.response.data.message))
		})
	}
}

export function companiesUpdateProfileData(company_id, user_id, data, user) {
	return (dispatch) => {
		NProgress.start()

		axios({
			url: `/companies/${company_id}/companies/${user_id}`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User successfully updated.',
				position: 'bl'
			}))

			const _user = user.set('preferredName', response.data.user.preferred_name)

			dispatch(companiesGetDetailSuccess(_user))
			dispatch(companiesSaveDataError({}))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured.',
			  position: 'bl'
			}))

			dispatch(companiesSaveDataError(error.response.data.message))
		})
	}
}

export function companiesDelete(companyId, filterData, jobId = '') {
	return (dispatch) => {
		NProgress.start()

		let url = `/companies/${companyId}/team-member`

		if (jobId) {
			url = `/companies/${companyId}/jobs/${jobId}/hiring-team`
		}

		return axios({
			url,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Member successfully deleted.',
				position: 'bl'
			}))

			dispatch(companiesAddFilterSelectedIds([]))
			dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting member.',
			  position: 'bl'
			}))
		})
	}
}

// Get detail user without store the value to reducer.
export function companiesGetDetailByParam(company_id, filter_data) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			axios({
				url: `/companies/filter`,
				timeout: 20000,
				method: 'get',
				responseType: 'json',
				params: filter_data
			})
			.then((response) => {
				const user = response.data.user

				resolve(user)
			})
			.catch((error) => {
				dispatch(companiesHasErrored(true))
			})
		})
	}
}

export function companiesUpdateIsNotifiedAndLevel(companyId, userId, data, jobId = '') {
	return (dispatch) => {
		let url = `/companies/${companyId}/team-member/${userId}/job`
		let messageSuccess = 'Team member successfully updated.'
		let messageError = 'An error occured when updating team member.'

		if (jobId) {
			url = `/companies/${companyId}/jobs/${jobId}/hiring-team/${userId}`
			messageSuccess = 'Hiring team successfully updated.'
			messageError = 'An error occured when updating hiring team.'
		}

		return axios({
			url,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			data: data
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: 'Success',
				message: messageSuccess,
				position: 'bl'
			}))
		})
		.catch((error) => {
			dispatch(Notifications.error({
			  title: 'Error',
			  message: messageError,
			  position: 'bl'
			}))
		})
	}
}

// SD
export function getDropdownCompanies(params) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/dropdown`,
			timeout: 20000,
			method: 'POST',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data:params
		})
		.then((response) => {
			dispatch(dropdownCompaniesFetchDataSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function dropdownCompaniesFetchDataSuccess(companies) {
	return {
		type: 'DROPDOWN_COMPANIES_FETCH_DATA_SUCCESS',
		companies
	}
}


export function companiesGetDetail(id) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(companiesGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
		})
	}
}

export function companiesGetCredit(filter) {
	return (dispatch) => {
		dispatch(setIsFetched(false))
		dispatch(companiesGetCreditSuccess([]))
		return axios({
			url: `/support-dashboard/companies/${filter.get('company_id')}/credits-account-statement`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filter.toJS()
		})
		.then((response) => {
			const pagination = omit(response.data.data, 'data')
			dispatch(companiesGetCreditSuccess(response.data.data))
			dispatch(creditsStatementFetchPaginationSuccess(pagination))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(companiesGetCreditSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function companiesSuspend(id) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${id}/suspend`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {'is_suspended': 1}
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function companiesReactivate(id) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${id}/suspend`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {'is_suspended': 0}
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function companiesUpgradeLevel(companyId, level, prevPage = 'company-detail') {
	return (dispatch) => {
		history.push({ 
			pathname: `/support/companies/${companyId}/update-credit`,
			state: {prevPage: prevPage,companyLevel:level}
		})
		// return axios({
		// 	url: `/support-dashboard/companies/${companyId}/update-level`,
		// 	timeout: 20000,
		// 	method: 'post',
		// 	responseType: 'json',
		// 	headers: {'X-Requested-With': 'XMLHttpRequest'},
		// 	data: {level: level}
		// })
		// .then((response) => {
		// 	dispatch(Notifications.success({
		// 		title: response.data.status,
		// 		message: response.data.message,
		// 		position: 'bl'
		// 	}))
		// 	history.push({ 
		// 		pathname: `/support/companies/${companyId}/update-credit`,
		// 		state: {prevPage: prevPage}
		// 	})
		// })
		// .catch((error) => {
		// 	let errorMsg='An error occured'
		// 	if(error.response){
		// 		errorMsg=error.response.data.message
		// 	}
		// 	dispatch(Notifications.error({
		// 	  title: 'Error',
		// 	  message: errorMsg,
		// 	  position: 'bl'
		// 	}))
		// })
	}
}

export function companiesUpdate(companyId, params) {
	const formData = new FormData()
	const fields = [
		'title',
		'website',
		'profileDescription',
		'logoUrl',
		'country_id',
		'company_size',
		'industry',
		'timezone_id',
		'banner_image',
		'city',
		'segment_type'
	]

	fields.forEach((field) => {
		const value = params[field]

		formData.append(field, value)
		// if (value) {
		// }
		
	})

	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/update`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'multipart/form-data'
			},
			data: formData
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function companiesTopup(companyId, params) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/topup`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: params
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

// Credit Statement
export function creditsStatementAddFilterCompanyId(company_id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterCompanyIdSuccess(company_id)))
		})
	}
}

export function creditsStatementAddFilterCompanyIdSuccess(company_id) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_COMPANY_ID_SUCCESS',
		company_id
	}
}

export function creditsStatementAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterPageNumberSuccess(page)))
		})
	}
}

export function creditsStatementAddFilterPageNumberSuccess(page) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function creditsStatementAddFilterSearch(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterSearchSuccess(page)))
		})
	}
}

export function creditsStatementAddFilterSearchSuccess(page) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_SEARCH_SUCCESS',
		page
	}
}

export function creditsStatementAddFilterPerPage(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterPerPageSuccess(page)))
		})
	}
}

export function creditsStatementAddFilterPerPageSuccess(page) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_PER_PAGE_SUCCESS',
		page
	}
}

export function creditsStatementAddFilterFrom(from) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterFromSuccess(from)))
		})
	}
}

export function creditsStatementAddFilterFromSuccess(from) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_FROM_SUCCESS',
		from
	}
}

export function creditsStatementAddFilterTo(to) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterToSuccess(to)))
		})
	}
}

export function creditsStatementAddFilterToSuccess(to) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_TO_SUCCESS',
		to
	}
}

export function creditsStatementAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementAddFilterSortSuccess(sort)))
		})
	}
}

export function creditsStatementAddFilterSortSuccess(sort) {
	return {
		type: 'CREDITS_STATEMENT_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function creditsStatementClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(creditsStatementClearFilterDataSuccess()))
		})
	}
}

export function creditsStatementClearFilterDataSuccess() {
	return {
		type: 'CREDITS_STATEMENT_CLEAR_FILTER_DATA_SUCCESS'
	}
}

export function creditsStatementFetchPaginationSuccess(pagination) {
	return {
		type: 'CREDITS_STATEMENT_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function companiesAddFilterCustomPlan(custom_plan) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterCustomPlanSuccess(custom_plan)))
		})
	}
}

export function companiesAddFilterCustomPlanSuccess(custom_plan) {
	return {
		type: 'COMPANIES_ADD_FILTER_CUSTOM_PLAN_SUCCESS',
		custom_plan
	}
}

export function companiesRejectPlanRequest(companyId, isReject) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/plan-request/reject`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {is_reject: isReject}
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function companiesUpdateAccountManager(companyId, accountManagerId, appointmentId, channel='talk to us') {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/set-account-manager`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {account_manager_id: accountManagerId, appointment_id: appointmentId, channel: channel}
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function downloadReportUsage(companyId, companyName, fromDate, toDate) {
	return () => {
		return axios({
			url: `/support-dashboard/report/generate`,
			timeout: 0,
			method: 'post',
			responseType: 'blob',
			crossDomain: true,
			data: { type: "customer-user-insight", company_id: companyId, date_from: fromDate, date_to: toDate }
		})
		.then(res => {
			const url  = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')
			link.href  = url
			link.setAttribute("download", `AccountInsight_${companyName}_${fromDate}_${toDate}.xlsx`)
			document.body.appendChild(link)
			link.click()

			link.remove()
			return true
		})
		.catch(err => {
			console.log(err)
			return false
		})
	}
}

export function downloadReportUsages(fromDate, toDate, identifier, channel='astrnt') {
	return () => {
		return axios({
			url: `/daily-usage`,
			timeout: 0,
			method: 'post',
			responseType: 'blob',
			crossDomain: true,
			data: { download: 1, date_from: fromDate, date_to: toDate, identifier: identifier, channel: channel }
		})
		.then(() => {
			return identifier
		})
		.catch(err => {
			console.log(err)
			return false
		})
	}
}

export function updateMetric(companyId, data) {
	return () => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/metric`,
			method: 'post',
			responseType: 'json',
			data: {
				start_date: data.startDate,
				end_date: data.endDate,
				credit: data.credit,
				contract: data.contract,
				billing: data.billing,
				billing_other: data.billing_other
			}
		})
		.then(() => {return true})
		.catch(() => {return false})
	}
}

export function getDropdownCompany(limit=10, offset, is_cdc='') {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/lists?limit=${limit}&offset=${offset}&channel=${is_cdc}`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(dropdownCompanyFetchDataSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function dropdownCompanyFetchData(companies) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(dropdownCompanyFetchDataSuccess(companies)))
		})
	}
}

export function dropdownCompanyFetchDataSuccess(companies) {
	return {
		type: 'DROPDOWN_COMPANIES_FETCH_DATA_SUCCESS',
		companies
	}
}

export function clearDropdownCompanyData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(clearDropdownCompanyDataSuccess()))
		})
	}
}

export function clearDropdownCompanyDataSuccess() {
	return {
		type: 'CLEAR_DROPDOWN_COMPANIES_DATA_SUCCESS'
	}
}

export function connectDisconnectPopskul(companyId, isConnect) {
	return () => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/popskul-connection`,
			method: 'post',
			responseType: 'json',
			data: {
				is_connect: isConnect,
			}
		})
		.then(() => {return true})
		.catch(() => {return false})
	}
}

export function updateCompanyAdditionalPlan(companyId, type, additionalPlan) {
	return () => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/additionalPlanFlag`,
			method: 'post',
			responseType: 'json',
			data: {
				flag_type: type,
				flag_value: additionalPlan
			}
		})
		.then(() => {return true})
		.catch(() => {return false})
	}
}

export function companyLocationByCountry(countryId) {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/master-data/locations`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			data:{country_id: countryId},
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(dropdownCompanyLocationFetchDataSuccess(response.data.data))
		})
		.catch((error) => {
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function dropdownCompanyLocationFetchData(locations) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(dropdownCompanyLocationFetchDataSuccess(locations)))
		})
	}
}

export function dropdownCompanyLocationFetchDataSuccess(locations) {
	return {
		type: 'COMPANIES_GET_LOCATIONS_SUCCESS',
		locations
	}
}


export function companiesAddFilterChannel(channel) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(companiesAddFilterChannelSuccess(channel)))
		})
	}
}

export function companiesAddFilterChannelSuccess(channel) {
	return {
		type: 'COMPANIES_ADD_FILTER_CHANNEL_SUCCESS',
		channel
	}
}

export function connectDisconnectCDC(companyId, isConnect) {
	return () => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/career-development-center`,
			method: 'post',
			responseType: 'json',
			data: {
				is_connect: isConnect,
			}
		})
		.then(() => {return true})
		.catch(() => {return false})
	}
}