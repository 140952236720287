import React, { Component, Fragment } from 'react'
import bindAll from 'lodash/bindAll'

class RadioButton  extends Component {
    constructor(props) {
		super(props)
        this.inputRadio = React.createRef();

        bindAll(this, [
			'handleRadioMasking'
		])
    }

    handleRadioMasking(e){
        this.inputRadio.current.click()
    }

	render() {
		const { input , id, label, disabled, className,children, name } = this.props
		return(
            <Fragment>
                <input {...input} name={input.name} ref={this.inputRadio} type="radio" className="hidden" disabled={disabled} />
                <a className={className}>
                    <div  className="the-box">
                    <span  onClick={this.handleRadioMasking}></span>
                    </div>

                    <span onClick={this.handleRadioMasking}>{children}</span>
                </a>
            </Fragment>

		)
	}
}

export default RadioButton
