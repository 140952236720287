// @flow

import React from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { List, Map, fromJS } from 'immutable'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import validateUser from './validateUser'
import RenderInput from '../partials/RenderInput'
import RenderInputEmail from '../partials/RenderInputEmail'
import RenderInputNumber from '../partials/RenderInputNumber'
import RenderSelect from '../partials/RenderSelect'
import RadioButton  from '../partials/RadioButton'
import Autosuggest from 'react-autosuggest'

import {
	usersCreateData,
	usersUpdateData,
	userGetDetail,
	usersValidateEmailOnCreateUserFormRequest,
	getDropdownUserLevel,
	clearFormUser
} from '../../actions/users'

import { 
	getDropdownCompany,
	clearDropdownCompanyData,
	dropdownCompanyFetchData
} from '../../actions/companies'

require('es6-promise').polyfill()

type Props = {
	pathName: string,
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	globalActiveMenuHiringTeam: Function,
	globalActiveMenuTeamMember: Function,
	users: List<Map<string, any>>,
	user: Map<string, any>,
	userTemp: Map<string, any>,
	globalIndicatorData: Map<string, any>,
  	changeFieldValue: Function,
	industries: List<Map<string, any>>,
  	languages: List<Map<string, any>>,
	handleSubmit: Function,
	pristine: boolean,
	submitting: boolean,
	usersCreateData: Function,
	usersUpdateData: Function,
	userGetDetail: Function,
	usersSaveDataError:Map<string, any>,
	usersValidateEmailOnCreateUserFormRequest: Function,
	asyncValidating: boolean,
	getDropdownUserLevel: Function,
	clearFormUser:Function,
	formValues: Object,
	getDropdownCompany: Function,
	clearDropdownCompanyData: Function,
	dropdownCompanyFetchData: Function,
};

type State = {
	isShown: boolean
};

class CustomPlanCreateUser extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selected: '',
			value: '',
			suggestions: [],
			showSuggestion:false,
		}

		bindAll(this, [
			'handleSaveData',
			'handleEmailChange',
			'handleLevelChange',
			'handleSelectChannel',
			'onSuggestionsFetchRequested',
			'onSuggestionSelected',
			'getSuggestionValue',
			'getSuggestions',
			'renderSuggestion',
			'handleChangeCompany',
			'escapeRegexCharacters',
			'onSuggestionsClearRequested'
		])
	}

	componentDidMount() {
		const {
			userId,
			userGetDetail,
			getDropdownUserLevel,
			clearFormUser,
			getDropdownCompany,
			clearDropdownCompanyData,
			dropdownCompanyFetchData,
			user
		} = this.props
		getDropdownUserLevel().then(()=>{
			if(userId!=undefined){
				userGetDetail(userId).then(()=>{
					this.props.usersValidateEmailOnCreateUserFormRequest(this.props.user.get('email'))
					clearDropdownCompanyData().then(()=>{
						dropdownCompanyFetchData([{label:this.props.user.get('company'),value:this.props.user.get('company')}])
						.then(()=>getDropdownCompany(10, 0)
						.then(()=>{
							this.setState({suggestion:this.props.dropdownCompanies,selected: this.props.user.get('company'),value:this.props.user.get('company')})
						}))
					})
				})
			}else{
				clearFormUser()
			}
		})

		$('[data-toggle=tooltip]').tooltip()
	}

	handleSaveData(values) {
		const {
			usersCreateData,
			usersUpdateData,
			userId,
			company			
		} = this.props
		values.appointment_id=userId
		values.prevPage='/support/custom-plan'
		usersCreateData(values)

	}

	handleEmailChange(e) {
		this.props.usersValidateEmailOnCreateUserFormRequest(e.target.value)
	}

	handleLevelChange(elm) {
		const { user } = this.props
		const value = elm.target.value
		if(user.get('user_level_id') == 44 && value != 44) {
			this.setState({isShown: true})
		} else {
			this.setState({isShown: false})
		}
	}
	handleSelectChannel(e){
		this.props.changeFieldValue('channel',e.target.value)
	}

	escapeRegexCharacters(value){
		return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	onSuggestionsFetchRequested({ value }){
		this.setState({
			suggestions: this.getSuggestions(value)
		});
	}
	onSuggestionsClearRequested(){

	}

	onSuggestionSelected(event, { suggestion }){
		this.props.changeFieldValue('company',suggestion.value)
		this.setState({
			selected: suggestion,
			suggestion:[],
			showSuggestion:false
		});
	}

	getSuggestionValue(suggestion){
		return suggestion.label
	}

	getSuggestions(value){
		let companies = this.props.dropdownCompanies.toJS()
		const escapedValue = this.escapeRegexCharacters(value.trim());

		if (escapedValue === '') {
			return companies;
		}

		const regex = new RegExp('^' + escapedValue, 'i');

		return companies.filter(company => regex.test(company.label));
	}

	renderSuggestion(company){
		return (
			<span>{company.label}</span>
	  	)
	}
	
	handleChangeCompany(event, { newValue }){
		this.props.changeFieldValue('company',newValue)
		this.setState({
			value: newValue
		});
	}
	
	renderError({ meta: { touched, error } }) {
		return touched && error ? <div><span className="error">{error}</span></div> : false
	}

  	render() {
		const {
			user,
			globalIndicatorData,
			handleSubmit,
    		pristine,
			submitting,
			userId,
  			asyncValidating,
			dropdownUserLevels,
			userPrivilege,
			formValues,
			dropdownCompanies,
			usersSaveDataError
		} = this.props
		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		const { isShown } = this.state
		const members = (user.get('members') ? user.get('members').toJS().map(({id,name}) => { return fromJS({ value: id, label: name }) }) : [])
		const hideDelegate = (!isShown || members.length<1)
		const disableLevel = (!userId || !user.get('user_level_id') || (user.get('user_level_id') == 44 && members.length<1))
		let dropdownLevels = dropdownUserLevels
		if(user.get('user_level_id') && user.get('user_level_id') != 44 && dropdownUserLevels.size > 0) {
			dropdownLevels = dropdownUserLevels.filter(f => f.get('value') != 44)
		}

		const { selected, value, suggestions, showSuggestion } = this.state;
		
		const inputProps = {
			placeholder: 'Type or Select Company',
			value,
			onChange: this.handleChangeCompany,
			onFocus:(e)=>{
				this.setState({showSuggestion:true})
			},
			onBlur:(e)=>{
				this.setState({showSuggestion:false})
			}
		};

    	return (
			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">Create User</h5>
									</div>

	                				<div className="div-support-create-user-container">
	                					<div><Link to={{pathname: '/support/custom-plan', state: {prevPage: 'create-user'}}} className="back-button"><i className="fa fa-angle-left"></i>Back</Link></div>
	                					<hr />
										<form onSubmit={handleSubmit(this.handleSaveData)} id="job-form_create_new_job" encType="multipart/form-data">
											<div>
												<Field
													name="name"
													type="text"
													component={RenderInput}
													label="Name"
													placeholder="Name"
													id="name"
												/>
											</div>
											{
												userId==undefined ?
												<div>
													<Field
														name="email"
														type="email"
														component={RenderInputEmail}
														label="Email"
														placeholder="Email"
														id="email"
														onChange={this.handleEmailChange}
														props={{
															isValidatingEmail: asyncValidating
														}}
													/>
												
												</div>:
												<div>
													<Field
														name="email"
														type="email"
														component={RenderInputEmail}
														label="Email"
														placeholder="Email"
														id="email"
													/>
												</div>
											}
											<div>
												<Field
													name="mobile_number"
													type="text"
													component={RenderInputNumber}
													label="Mobile Number"
													placeholder="Mobile Number"
													id="mobile_number"
												/>
											</div>
											<div>
												<div className="input-container">
													<div><label>Company</label></div>
													<Autosuggest
														suggestions={this.state.suggestions}
														onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
														onSuggestionsClearRequested={this.onSuggestionsClearRequested}
														onSuggestionSelected={this.onSuggestionSelected}
														getSuggestionValue={this.getSuggestionValue}
														renderSuggestion={this.renderSuggestion}
														inputProps={inputProps}
														alwaysRenderSuggestions={this.state.showSuggestion}
														id="company"
													></Autosuggest>
													<Field name="company" component={this.renderError}/>
												</div>
												
											</div>
											<div>
												<Field
													name="user_level"
													component={RenderSelect}
													label="User Level"
													placeholder="User Level"
													id="user_level"
													dropdownOption={dropdownLevels}
													disabled={disableLevel}
													onChange={this.handleLevelChange}
													disableOptionText ="Select the number of user level"
												/>
											</div>
											<div>
												<Field
													name="delegate_id"
													component={RenderSelect}
													label="Delegate Account Holder Role to"
													placeholder="Select User"
													id="delegate_id"
													dropdownOption={members}
													hidden={hideDelegate}
													hasInfo={true}
													info="The user chosen will be the new Account Holder for this company."
												/>
											</div>
											<div>
												<div>
													<label>Channel</label>
												</div>
												<Field
													component={RadioButton}
													type ="radio"
													value="astrnt"
													name="channel"
													className={`channel as-radio m-r-15 ${formValues.channel=="astrnt" ? "is-on":null}`}
													onChange={this.handleSelectChannel}
												>
													Astrnt.co
												</Field>
												<Field
													component={RadioButton}
													type ="radio"
													value="popskul"
													name="channel"
													className={`channel as-radio m-r-15 ${formValues.channel=="popskul" ? "is-on":null}`}
													onChange={this.handleSelectChannel}
												>
													Popskul.id
												</Field>
												<Field name="channel" component={this.renderError}/>
											</div>
											<div className="center m-t-36">
												{
													(userPrivilege.includes('CREATE_USER') || userPrivilege.includes('UPDATE_USER')) &&
													<button disabled={pristine || submitting || asyncValidating} className="submit-button">Submit</button>
												}
												
											</div>
										</form>
									</div>
	              				</div>
	            			</div>
	          			</div>
	        		</section>
	      		</div>
			</div>
    	)
  	}
}

CustomPlanCreateUser = reduxForm({
  form: 'CustomPlanCreateUser',
  validate: validateUser,
	initialValues: {
		name: '',
		email:'',
		mobile_number: "",
		company: "",
		user_level: 0,
		channel:'',
		delegate_id: null
  	},
	enableReinitialize: true
})(CustomPlanCreateUser)

const selector = formValueSelector('CustomPlanCreateUser')

const mapStateToProps = (state, ownProps) => {
	const user = state.user
	const initialValues = {
		name: user.get('fullname'),
		email:user.get('email'),
		mobile_number: user.get('phone'),
		company: user.get('company'),
		user_level: null,
		channel: 'popskul',
		delegate_id: null
	}
	return {
		formValues: selector(
			state,
			'name',
			'email',
			'mobile_number',
			'company',
			'user_level',
			'channel'
		),
		initialValues,
		pathName: ownProps.location.pathname,
		globalIndicatorData: state.globalIndicatorData,
		user: state.user,
		users: state.users,
		userTemp: state.userTemp,
		usersSaveDataError: state.usersSaveDataError,
		userId: ownProps.match.params.id,
		dropdownUserLevels: state.dropdownUserLevels,
		userPrivilege: state.userPrivilege,
		dropdownCompanies: state.dropdownCompanies,
		company: state.company
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		usersCreateData: (userData) => dispatch(usersCreateData(userData)),
		usersUpdateData: (userId,userData) => dispatch(usersUpdateData(userId,userData)),
		userGetDetail: (userId) => dispatch(userGetDetail(userId,'contact-schedule')),
		usersValidateEmailOnCreateUserFormRequest: (email) => dispatch(usersValidateEmailOnCreateUserFormRequest(email,'CustomPlanCreateUser')),
		getDropdownUserLevel: () => dispatch(getDropdownUserLevel()),
		clearFormUser: () => dispatch(clearFormUser()),
		getDropdownCompany: (limit, offset) => dispatch(getDropdownCompany(limit, offset)),
		dropdownCompanyFetchData: (companies) => dispatch(dropdownCompanyFetchData(companies)),
		clearDropdownCompanyData: () => dispatch(clearDropdownCompanyData()),
		changeFieldValue: (field, value) => dispatch(change('CustomPlanCreateUser', field, value, true, true)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPlanCreateUser)
