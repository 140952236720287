import { Map, List, fromJS } from 'immutable'

const initFilterData = fromJS({
	page: '',
	sort: '',
	company_name: '',
	per_page:10,
	payment_method:'',
	payment_status:'',
	search:''
})

export function transactions(state = List([]), action) {
	switch(action.type) {
		case 'TRANSACTIONS_FETCH_DATA_SUCCESS':
			return fromJS(action.transactions)
		default:
			return state
	}
}

export function transaction(state = Map({}), action) {
	switch(action.type) {
		case 'TRANSACTION_GET_DETAIL_SUCCESS':
			return state.merge(fromJS(action.transaction))
		case 'TRANSACTIONS_UPDATE_ID_SUCCESS':
			return state.merge({id: action.id})
		case 'TRANSACTIONS_UPDATE_PACKAGE_ID_SUCCESS':
			return state.merge({package_id: action.package_id})
		case 'TRANSACTIONS_UPDATE_COUNTRY_ID_SUCCESS':
			return state.merge({country_id: action.country_id})
		case 'TRANSACTIONS_UPDATE_EXCHANGE_CODE_SUCCESS':
			return state.merge({exchange_code: action.exchange_code})
		case 'TRANSACTIONS_UPDATE_TOTAL_PRICE_SUCCESS':
			return state.merge({total_price: action.total_price})
		case 'TRANSACTIONS_UPDATE_INVOICE_URL_SUCCESS':
			return state.merge({invoice_file_url: action.invoice_file_url})
		case 'TRANSACTIONS_UPDATE_EFAKTUR_URL_SUCCESS':
			return state.merge({efaktur_file_url: action.efaktur_file_url})
		default:
			return state
	}
}


export function transactionsFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'TRANSACTIONS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'TRANSACTIONS_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'TRANSACTIONS_ADD_FILTER_COMPANY_NAME_SUCCESS':
			return state.set('company_name', action.company_name)
		case 'TRANSACTIONS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'TRANSACTIONS_ADD_FILTER_PAYMENT_METHOD_SUCCESS':
				return state.set('payment_method', action.payment_method)
		case 'TRANSACTIONS_ADD_FILTER_PAYMENT_STATUS_SUCCESS':
			return state.set('payment_status', action.payment_status)
		case 'TRANSACTIONS_ADD_FILTER_SEARCH_SUCCESS':
			return state.set('search', action.search)
		case 'TRANSACTIONS_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				sort: '',
				company_name: '',
				per_page:10,
				payment_method:'',
				payment_status:'',
				search:''
			})
		default:
			return state
	}
}

export function transactionsPagination(state = Map({}), action) {
	switch(action.type) {
		case 'TRANSACTIONS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}