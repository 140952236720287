// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map, List } from 'immutable'

type Props = {
}

class ModalOtherBillingAgreement extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			val: ''
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleSubmitFilter',
			'changeVal'
		])
	}

	componentDidMount() { this.setState({val:this.props.val}) }

	handleCloseModalClick(e: Object) {
		e.preventDefault()
		$('#modalOtherBillingAgreement').modal('hide')
	}

	handleSubmitFilter(e){
		e.preventDefault()
		this.props.callback(this.state.val)
		this.handleCloseModalClick(e)
	}

	changeVal(e) {
		this.setState({val: e.target.value})
	}

  	render() {
		const {
		} = this.props
		  
    	return (
      		<div className="modal custom-modal" id="modalOtherBillingAgreement" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        		<div className="modal-dialog wrapper-modal-download-all">
          			<div className="modal-content animated bounceInRight">
            			<div className="modal-header">
            				<label>Other Billing Agreement</label>
            			</div>
						<div className="modal-body">
							<div className="input-container">
								<label>Create Billing Agreement Period</label>
								<input placeholder="e.g. 3 months" value={this.state.val} onChange={(e) => this.changeVal(e)} />
							</div>
						</div>
						<div className="modal-footer">
							<button className="button button-cancel m-r-16" onClick={(e)=>this.handleCloseModalClick(e)}>Cancel</button>
							<button className="button button-save" onClick={(e)=>this.handleSubmitFilter(e)}>Save</button>
						</div>
          			</div>
        		</div>
      		</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		val: ownProps.val,
		callback: ownProps.callback
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalOtherBillingAgreement)
