// @flow

import { all, takeLatest } from 'redux-saga/effects'

import {
	transactionsSaveData,
	transactionsBuyPaymentPackage,
	transactionsSaveCreditCardData,
	transactionsSaveBankTransferData
} from './transactions'

import {
	usersSaveData,
	usersValidateEmailOnUserRegisterWizardForm,
	usersValidateEmailOnCreateUser,
	internalUsersValidateEmailOnCreateUser
} from './users'

import {
	companiesGetDetail,
	companiesFetchUsageActivityData,
	companiesIsOnboardingInvitation,
	companiesIsOnboardingManageCandidate,
	companiesHaveWatchedVideo,
	companiesFetchInitialData,
	companiesGetTransactionDetail,
	companiesFetchTransactionsData,
	companiesResendVerificationEmail
} from './companies'

import {
	featureListJobFetchData,
	featureListCompanyFetchData
} from './feature_list'

import {
	globalHideSweetAlert,
	globalDecideIndicator,
	globalShowNotification,
} from './global'

import {
	validateVoucherCode
} from './vouchers'


export default function* root(): Generator<*, *, *> {
  yield all([
  	takeLatest('GLOBAL_HIDE_SWEET_ALERT_REQUEST', globalHideSweetAlert),
  	takeLatest('GLOBAL_DECIDE_INDICATOR_REQUEST', globalDecideIndicator),
  	takeLatest('GLOBAL_SHOW_NOTIFICATION_REQUEST', globalShowNotification),
  	takeLatest('FEATURE_LIST_JOB_FETCH_DATA_REQUEST', featureListJobFetchData),
  	takeLatest('FEATURE_LIST_COMPANY_FETCH_DATA_REQUEST', featureListCompanyFetchData),
  	takeLatest('COMPANIES_GET_DETAIL_REQUEST', companiesGetDetail),
  	takeLatest('COMPANIES_FETCH_USAGE_ACTIVITY_DATA_REQUEST', companiesFetchUsageActivityData),
  	takeLatest('COMPANIES_IS_ONBOARDING_INVITATION_REQUEST', companiesIsOnboardingInvitation),
  	takeLatest('COMPANIES_IS_ONBOARDING_MANAGE_CANDIDATE_REQUEST', companiesIsOnboardingManageCandidate),
  	takeLatest('COMPANIES_HAVE_WATCHED_VIDEO_REQUEST', companiesHaveWatchedVideo),
  	takeLatest('COMPANIES_FETCH_INITIAL_DATA_REQUEST', companiesFetchInitialData),
  	takeLatest('COMPANIES_GET_TRANSACTION_DETAIL_REQUEST', companiesGetTransactionDetail),
  	takeLatest('COMPANIES_FETCH_TRANSACTIONS_DATA_REQUEST', companiesFetchTransactionsData),
  	takeLatest('COMPANIES_RESEND_VERIFICATION_EMAIL_REQUEST', companiesResendVerificationEmail),
  	takeLatest('USERS_SAVE_DATA_REQUEST', usersSaveData),
  	takeLatest('USERS_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST', usersValidateEmailOnUserRegisterWizardForm),
  	takeLatest('TRANSACTIONS_SAVE_DATA_REQUEST', transactionsSaveData),
  	takeLatest('TRANSACTIONS_SAVE_CREDIT_CARD_DATA_REQUEST', transactionsSaveCreditCardData),
  	takeLatest('TRANSACTIONS_SAVE_BANK_TRANSFER_DATA_REQUEST', transactionsSaveBankTransferData),
  	takeLatest('TRANSACTIONS_BUY_PAYMENT_PACKAGE_REQUEST', transactionsBuyPaymentPackage),
  	takeLatest('USERS_VALIDATE_EMAIL_ON_CREATE_USER_FORM_REQUEST', usersValidateEmailOnCreateUser),
  	takeLatest('INTERNAL_USERS_VALIDATE_EMAIL_ON_CREATE_USER_FORM_REQUEST', internalUsersValidateEmailOnCreateUser),
  	takeLatest('VALIDATE_VOUCHER_CODE', validateVoucherCode),
  ])
}
