// @flow

import { put, all, call, select } from 'redux-saga/effects'
import { Map } from 'immutable'
import pluralize from 'pluralize'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

import {
	companiesGetDetailApi,
	companiesHaveWatchedVideoApi,
	companiesFetchInitialDataApi,
	companiesGetTransactionDetailApi,
	companiesFetchTransactionsDataApi,
	companiesIsOnboardingInvitationApi,
	companiesFetchUsageActivityDataApi,
	companiesResendVerificationEmailApi,
	companiesIsOnboardingManageCandidateApi
} from '../apis/companies'

import {
	getCompany,
	getCompaniesTransactionsFilterData
} from '../selectors'

export function* companiesFetchInitialData({ companyId, jobId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			companiesFetchInitialDataApi,
			companyId
		)

		if (response.status >= 200 && response.status < 300) {
			yield all([
				put({
					type: 'COMPANIES_FETCH_DATA_SUCCESS',
					companies: response.data.companies
				}),
				put({
					type: 'INDUSTRIES_FETCH_DATA_SUCCESS',
					industries: response.data.industries
				}),
				put({
					type: 'JOBS_FETCH_DATA_DROPDOWN_SUCCESS',
					jobs: response.data.jobs
				}),
				put({
					type: 'USER_LEVELS_FETCH_DATA_SUCCESS',
					user_levels: response.data.user_levels
				}),
				put({
					type: 'USERS_FETCH_DATA_DROPDOWN_SUCCESS',
					users: response.data.users
				}),
				put({
					type: 'USERS_GET_DETAIL_SUCCESS',
					user: response.data.user
				}),
				put({
					type: 'LANGUAGES_FETCH_DATA_SUCCESS',
					languages: response.data.languages
				}),
				put({
					type: 'JOBS_TYPE_FETCH_DATA_DROPDOWN_SUCCESS',
					jobTypes: response.data.job_list_references
				}),
				put({
					type: 'COUNTRIES_FETCH_DATA_SUCCESS',
					countries: response.data.countries
				}),
				put({
					type: 'PAYMENT_PACKAGES_FETCH_DATA_SUCCESS',
					payment_packages: response.data.payment_packages
				}),
				put({
					type: 'GLOBAL_ADD_UI_WELCOME_VIDEO_LINK_SUCCESS',
					welcome_video_link: response.data.ui_data.welcome_video_link
				}),
				put({
					type: 'GLOBAL_ADD_UI_IS_ONBOARDING_INVITATION_SUCCESS',
					is_onboarding_invitation: response.data.ui_data.is_onboarding_invitation
				}),
				put({
					type: 'GLOBAL_ADD_UI_IS_ONBOARDING_MANAGE_CANDIDATE_SUCCESS',
					is_onboarding_manage_candidate: response.data.ui_data.is_onboarding_manage_candidate
				}),
				put({
					type: 'GLOBAL_ADD_UI_IS_WATCHED_VIDEO_SUCCESS',
					is_watched_video: response.data.ui_data.have_watched_video
				}),
				put({
					type: 'GLOBAL_ADD_UI_IS_CURRENT_COMPANY_ACTIVE_SUCCESS',
					is_current_company_active: response.data.company_status === 'active'
				}),
				put({
					type: 'TIMEZONES_FETCH_DATA_SUCCESS',
					timezones: response.data.timezones
				})
			])

			yield companiesGetDetail({ companyId })
			yield showFreeTrialIndicator()
			yield showCreditIndicator()

			if (jobId) {
				yield put({
					type: 'FEATURE_LIST_JOB_FETCH_DATA_REQUEST',
					companyId,
					jobId
				})
			}

			yield put({
				type: 'FEATURE_LIST_COMPANY_FETCH_DATA_REQUEST',
				companyId
			})

			yield put({
				type: 'GLOBAL_ADD_UI_IS_DISPLAY_MAIN_LAYOUT_SUCCESS',
				is_display_main_layout: true
			})
		} else {
			throw response
		}
	} catch(error) {
		yield put({
			type: 'GLOBAL_SHOW_NOTIFICATION_REQUEST',
			level: 'error',
			title: 'Error',
			message: 'An error occured when loading initial data, please refresh the page.',
			position: 'bl'
		})
	}
}

export function* companiesGetDetail({ companyId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			companiesGetDetailApi,
			companyId
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'COMPANIES_GET_DETAIL_SUCCESS',
				company: response.data
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}

export function* companiesIsOnboardingInvitation({ companyId, userId }: Object): Generator<*, *, *> {
	try {
		yield put({
			type: 'GLOBAL_ADD_UI_IS_ONBOARDING_INVITATION_SUCCESS',
			is_onboarding_invitation: true
		})

		const response: Object = yield call(
			companiesIsOnboardingInvitationApi,
			companyId,
			userId
		)
	} catch(error) {
		
	}
}

export function* companiesIsOnboardingManageCandidate({ companyId, userId }: Object): Generator<*, *, *> {
	try {
		yield put({
			type: 'GLOBAL_ADD_UI_IS_ONBOARDING_MANAGE_CANDIDATE_SUCCESS',
			is_onboarding_manage_candidate: true
		})

		const response: Object = yield call(
			companiesIsOnboardingManageCandidateApi,
			companyId,
			userId
		)
	} catch(error) {
		
	}
}

export function* companiesHaveWatchedVideo({ companyId, userId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			companiesHaveWatchedVideoApi,
			companyId,
			userId
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'GLOBAL_ADD_UI_IS_WATCHED_VIDEO_SUCCESS',
				is_watched_video: true
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}

export function* companiesResendVerificationEmail({ companyId, userId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			companiesResendVerificationEmailApi,
			companyId,
			userId
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'GLOBAL_SHOW_NOTIFICATION_REQUEST',
				title: 'Success',
				message: 'Resend email verification success!',
				position: 'bl',
				level: 'success'
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}

export function* showFreeTrialIndicator(): Generator<*, *, *> {
	try {
		const company: Map<string, any> = yield select(getCompany)

		if (company.get('company_status') === 'free_trial') {
			yield put({
				type: 'GLOBAL_DECIDE_INDICATOR_REQUEST',
				data: {
					text: `Your Astronaut trial account has <b>${pluralize('day', company.get('free_trial_days_left'), true)}</b> and <b>${pluralize('credit', company.get('credits_left'), true)}</b> remaining. <a href="/companies/${company.get('id')}/pricing"><b>See Pricing</b></a>. Please contact <a href="mailto:help@astrnt.co?subject=Upgrade Plan" target="_blank"><b>help@astrnt.co</b></a> to upgrade.`,
					type: 'info',
					isShow: true
				}
			})
		}
	} catch(error) {
		
	}
}

export function* showCreditIndicator(): Generator<*, *, *> {
	try {
		const company: Map<string, any> = yield select(getCompany)

		if (company.get('company_status') === 'paid') {
			if (company.get('credits_left') <= 0) {
				yield put({
					type: 'GLOBAL_DECIDE_INDICATOR_REQUEST',
					data: {
						text: `You are using extra candidate credits. <a href="mailto:help@astrnt.co?subject=[Attention, billing] Request for top-up">Top up your credits</a> to keep using Astronaut.`,
						type: 'danger',
						isShow: true
					}
				})
			} else if (company.get('credits_days_left') <= 5) {
				yield put({
					type: 'GLOBAL_DECIDE_INDICATOR_REQUEST',
					data: {
						text: `You have ${company.get('credits_left')} candidate credits expiring in ${pluralize('day', company.get('credits_days_left'), true)}. <a href="mailto:help@astrnt.co?subject=[Attention, billing] Request for top-up">Top up your credits</a> to keep using Astronaut.`,
						type: 'warning',
						isShow: true
					}
				})
			}
		}
	} catch(error) {
		
	}
}

export function* companiesFetchUsageActivityData({ companyId, dateCounter }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			companiesFetchUsageActivityDataApi,
			companyId,
			dateCounter
		)

		if (response.status >= 200 && response.status < 300) {
			yield all([
				put({
					type: 'COMPANIES_FETCH_USAGE_ACTIVITY_DATA_SUCCESS',
					usageActivity: response.data.data
				}),
				put({
					type: 'COMPANIES_ADD_UI_USAGE_ACTIVITY_PERIOD_SUCCESS',
					usage_activity_period: response.data.period
				})
			])
		} else {
			throw response
		}
	} catch(error) {
		
	}
}

export function* companiesGetTransactionDetail({ companyId, transactionId }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			companiesGetTransactionDetailApi,
			companyId,
			transactionId
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'COMPANIES_GET_TRANSACTION_DETAIL_SUCCESS',
				transaction: response.data
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}

export function* companiesFetchTransactionsData({ companyId, params }: Object = {companyId: '', params: {}}): Generator<*, *, *> {
	try {
		yield put({
			type: 'COMPANIES_IS_LOADING',
			isLoading: true
		})

		const companiesTransactionsFilterData: Map<string, any> = yield select(getCompaniesTransactionsFilterData)
		let _companiesTransactionsFilterData: Map<string, any> = Map({})

		if (!isEmpty(params)) {
			_companiesTransactionsFilterData = companiesTransactionsFilterData.merge(params)
		}

		yield put({
			type: 'COMPANIES_TRANSACTIONS_ADD_FILTER_ALL_SUCCESS',
			all: _companiesTransactionsFilterData
		})

		const response: Object = yield call(
			companiesFetchTransactionsDataApi,
			companyId,
			_companiesTransactionsFilterData.toJS()
		)

		if (response.status >= 200 && response.status < 300) {
			if (!isEmpty(response.data)) {
				yield put({
					type: 'COMPANIES_FETCH_TRANSACTIONS_DATA_SUCCESS',
					transactions: response.data.data
				})

				yield put({
					type: 'COMPANIES_TRANSACTIONS_FETCH_PAGINATION_SUCCESS',
					pagination: omit(response.data, 'data')
				})
			}

			yield put({
				type: 'COMPANIES_IS_LOADING',
				isLoading: false
			})
		} else {
			throw response
		}
	} catch(error) {
		
	}
}
