import configurations from '../../misc/configurations'

const validate = values => {
	const errors = {}
	if (!values.email) {
		errors.email = `Email can't be blank`
	} else {
		if (!configurations.emailRegex.test(values.email)) {
			errors.email = `Invalid email`
		}
	}

	if (!values.name) {
		errors.name = `Name can't be blank`
	}else{
		if(values.name.length < 3){
			errors.name = `Name cannot less than 3 characters`
		}
	}

	if (!values.admin_userlevel_id) {
		errors.admin_userlevel_id = `Admin Level can't be blank`
	}

	return errors
}

export default validate

