// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import validateInternalUser from './validateInternalUser'
import RenderInput from '../partials/RenderInput'
import RenderInputEmail from '../partials/RenderInputEmail'
import RenderSelect from '../partials/RenderSelect'

import {
	internalUserSaveData,
	userGetDetail,
	getDropdownUserLevel,
	clearFormUser,
	internalUsersValidateEmailOnCreateUserFormRequest,
	getDropdownAdminLevel
} from '../../actions/users'

require('es6-promise').polyfill()

type Props = {
	user: Map<string, any>,
	globalIndicatorData: Map<string, any>,
  	changeFieldValue: Function,
	handleSubmit: Function,
	pristine: boolean,
	submitting: boolean,
	internalUserSaveData: Function,
	userGetDetail: Function,
	asyncValidating: boolean,
	getDropdownUserLevel: Function,
	clearFormUser:Function,
	internalUsersValidateEmailOnCreateUserFormRequest: Function,
	getDropdownAdminLevel: Function,
	invalid: boolean
};

type State = {
	isShown: boolean
};

class UserInternalForm extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false
		}

		bindAll(this, [
			'handleSaveData',
			'handleEmailChange'
		])
	}

	componentDidMount() {
		const {
			userId,
			userGetDetail,
			getDropdownAdminLevel,
			clearFormUser
		} = this.props
		getDropdownAdminLevel().then(()=>{
			if(userId!=undefined){
				userGetDetail(userId)
			}else{
				clearFormUser()
			}
		})

	}

	handleSaveData(values) {
		const {
			internalUserSaveData,
			userId
		} = this.props
		if(userId!=undefined){
			values.is_edit = true
		}
		internalUserSaveData(values)
	}

	handleEmailChange(e) {
		this.props.internalUsersValidateEmailOnCreateUserFormRequest(e.target.value)
	}

  	render() {
		const {
			user,
			globalIndicatorData,
			handleSubmit,
    		pristine,
			submitting,
			userId,
  			asyncValidating,
			dropdownAdminLevels,
			invalid,
			userAuth
		} = this.props

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		let adminRole=userAuth.get('role')!=undefined ? userAuth.get('role').get('id'):0
		let restrictDropdown=dropdownAdminLevels;
		if(adminRole == 2){
			restrictDropdown=dropdownAdminLevels.filter(item => item.get('value') > 2);
		}else if (adminRole > 2){
			restrictDropdown = []
		}
    	return (

			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
					<section className="wrapper">
						<div className="main-content">
							<div className="container-fluid">
								<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">{userId==undefined ? 'Create Internal User':'Edit Internal User'}</h5>
									</div>

									<div className="div-support-create-user-container">
										<div><Link to={{pathname: '/support/internal-user', state: {prevPage: 'internal-user-detail'}}} className="back-button"><i className="fa fa-angle-left"></i>Back</Link></div>
										<hr />
										<form onSubmit={handleSubmit(this.handleSaveData)} id="job-form_create_new_job" encType="multipart/form-data">
										{
												userId==undefined ?
												<div>
													<Field
														name="email"
														type="email"
														component={RenderInputEmail}
														label="Email"
														placeholder="Email"
														id="email"
														onChange={this.handleEmailChange}
														props={{
															isValidatingEmail: asyncValidating
														}}
													/>
												</div>:
												<div>
													<Field
														name="email"
														type="email"
														component={RenderInputEmail}
														label="Email"
														placeholder="Email"
														id="email"
														readOnly={1}
														disabled
													/>
												</div>
											}

											<div>
												<Field
													name="name"
													type="text"
													component={RenderInput}
													label="Name"
													placeholder="Name"
													id="name"
													readOnly={1}
													disabled
												/>
											</div>
											
											<div>
												<Field
													name="admin_userlevel_id"
													component={RenderSelect}
													label="Admin Level"
													placeholder="Admin Level"
													id="admin_userlevel_id"
													defaultValue={user.get('admin_userlevel_id') ? user.get('admin_userlevel_id') : '' }
													dropdownOption={restrictDropdown}
												/>
											</div>
											<div className="center m-t-36"><button disabled={invalid || pristine || submitting || asyncValidating} className="submit-button">Submit</button></div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
   		)
  	}
}

UserInternalForm = reduxForm({
  form: 'UserInternalForm',
  validate: validateInternalUser,
	initialValues: {
		name: '',
		email:'',
		admin_userlevel_id: ''
  },
	enableReinitialize: true
})(UserInternalForm)

const selector = formValueSelector('UserInternalForm')

const mapStateToProps = (state, ownProps) => {
	const user = state.user
	const initialValues = {
		name: user.get('name'),
		email:user.get('email'),
		admin_userlevel_id: user.get('admin_userlevel_id')
	}
	return {
		formValues: selector(
			state,
			'name',
			'email',
			'admin_userlevel_id'
		),
		initialValues,
		globalIndicatorData: state.globalIndicatorData,
		user: state.user,
		userId: ownProps.match.params.userId,
		dropdownUserLevels: state.dropdownUserLevels,
		dropdownAdminLevels: state.dropdownAdminLevels,
		userAuth:state.userAuth
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		internalUserSaveData: (userData) => dispatch(internalUserSaveData(userData)),
		userGetDetail: (userId) => dispatch(userGetDetail(userId)),
		getDropdownUserLevel: () => dispatch(getDropdownUserLevel()),
		clearFormUser: () => dispatch(clearFormUser()),
		internalUsersValidateEmailOnCreateUserFormRequest: (email) => dispatch(internalUsersValidateEmailOnCreateUserFormRequest(email)),
		getDropdownAdminLevel: () => dispatch(getDropdownAdminLevel()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInternalForm)
