// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map, List } from 'immutable'

type Props = {
}

class ModalUpdateMetricData extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleSubmitFilter',
		])
	}

	componentDidMount() {}

	handleCloseModalClick(e: Object) {
		e.preventDefault()

		$('#modalUpdateMetricData').modal('hide')
	}

	handleSubmitFilter(e){
		e.preventDefault()
		const { companyId, data } = this.props
		let _data = data
		_data.startDate = data.startDate.format("y-MM-DD")
		_data.endDate = data.endDate.format("y-MM-DD")
		this.props.callback(companyId, data)
		.then(() => {
			this.handleCloseModalClick(e)
		})
	}

  	render() {
		const {
		} = this.props
		  
    	return (
      		<div className="modal custom-modal" id="modalUpdateMetricData" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        		<div className="modal-dialog wrapper-modal-download-all">
          			<div className="modal-content animated bounceInRight">
            			<div className="modal-header">
            				<label>Update Commercial Metric data?</label>
            			</div>
						<div className="modal-body">
							<div className="input-container">
								<p>Are you sure want to update the Commercial Metric data?</p>
							</div>
						</div>
						<div className="modal-footer">
							<button className="button button-cancel m-r-16" onClick={this.handleCloseModalClick}>Cancel</button>
							<button className="button button-save" onClick={(e)=>this.handleSubmitFilter(e)}>Save</button>
						</div>
          			</div>
        		</div>
      		</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		companyId: ownProps.companyId,
		data: ownProps.data,
		callback: ownProps.callback,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateMetricData)
