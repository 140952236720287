// @flow

import axios from "../dependencies/_axios";
import { objectToFormData } from '../helpers/global'

export function companiesFetchInitialDataApi(companyId: string) {
	return axios({
		url: `/companies/${companyId}/initial-data`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'}
	})
}

export function companiesGetDetailApi(companyId: string) {
	return axios({
		url: `/companies/${companyId}`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'}
	})
}

export function companiesUpdateDataApi(companyId: string, data) {
	return axios({
		url: `/companies/${companyId}`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: objectToFormData(data)
	})
}

export function companiesIsOnboardingInvitationApi(companyId: string, userId: string) {
	return axios({
		url: `/companies/${companyId}/is_onboarding_invitation`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			user_id: userId
		}
	})
}

export function companiesIsOnboardingManageCandidateApi(companyId: string, userId: string) {
	return axios({
		url: `/companies/${companyId}/is_onboarding_manage_candidate`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			user_id: userId
		}
	})
}

export function companiesHaveWatchedVideoApi(companyId: string, userId: string) {
	return axios({
		url: `/companies/${companyId}/have_watched_video`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			user_id: userId
		}
	})
}

export function companiesResendVerificationEmailApi(companyId: string, userId: string) {
	return axios({
		url: `/companies/${companyId}/resend_verification_email`,
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			user_id: userId
		}
	})
}

export function companiesFetchUsageActivityDataApi(companyId: string, dateCounter: string) {
	return axios({
		url: `/companies/${companyId}/usage-activity/${dateCounter}`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'}
	})
}

export function companiesFetchTransactionsDataApi(companyId: string, params: Object = {}) {
	return axios({
		url: `/companies/${companyId}/transactions/filter`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		params
	})
}

export function companiesGetTransactionDetailApi(companyId: string, transactionId: string) {
	return axios({
		url: `/companies/${companyId}/transactions/${transactionId}`,
		timeout: 20000,
		method: 'get',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'}
	})
}
