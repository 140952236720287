// @flow

import axios from "../dependencies/_axios";

export function usersCheckVoucherCode(code) {
	return axios({
		url: '/support-dashboard/vouchers/check',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {code: code}
	})
}