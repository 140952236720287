const validate = values => {
	const errors = {}

	if (!values.name) {
		errors.name = `Name can't be blank`
	}else{
		if(values.name.length < 3){
			errors.name = `Name cannot less than 3 characters`
		}
	}

	if (!values.feature_list) {
		errors.feature_list = `Feature List can't be blank`
	}

	return errors
}

export default validate

