// @flow

import { put } from 'redux-saga/effects'
import { delay, eventChannel, END } from 'redux-saga'
import axios from 'axios'


export function* globalShowNotification({ title, message, position, level }: Object): Generator<*, *, *> {
	try {
		let notificationId = 0

		if (message === 'Question is added to selected question list.') {
			notificationId = 1000
		}

		yield put({
			type: 'RNS_SHOW_NOTIFICATION',
			title,
			message,
			position,
			level,
			autoDismiss: 0,
			uid: notificationId
		})

		yield delay(1000)
		yield put({ type: 'RNS_HIDE_NOTIFICATION', uid: notificationId})
	} catch(error) {
		yield put({type: 'GLOBAL_HAS_ERRORED', hasErrored: true})
	}
}

export function* globalDecideIndicator({ data }: Object): Generator<*, *, *> {
	try {
		yield put({
			type: 'GLOBAL_ADD_INDICATOR_DATA_SUCCESS',
			data
		})
	} catch(error) {
		yield put({type: 'GLOBAL_HAS_ERRORED', hasErrored: true})
	}
}

export function* globalHideSweetAlert(): Generator<*, *, *> {
	try {
		yield put({type: 'GLOBAL_HIDE_SWEET_ALERT_SUCCESS'})
	} catch(error) {
		yield put({type: 'GLOBAL_HAS_ERRORED', hasErrored: true})
	}
}


export function countdown(seconds) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;

      if (seconds >= 0) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });
}

export function countdownRecord(seconds) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;

      if (seconds > 0) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });
}

export function countdownFinish(seconds) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;

      if (seconds >= 0) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  });
}

export function countdownPractice(seconds, is_record) {
  return eventChannel(emitter => {
    const interval: IntervalID = setInterval(() => {
      seconds -= 1;
      if (seconds >= 0 && is_record) {
        emitter(seconds);
      } else {
        emitter(END);

        clearInterval(seconds);
      }
      
    }, 1000);

    return () => clearInterval(interval);
  });
}
