// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setSessionsToken, userGetAuth, setToken } from '../actions/sessions'

import 'sweetalert/dist/sweetalert.css'

require('es6-promise').polyfill()

type Props = {
	children: Object,
	globalSweetAlertData: Object,
	notifications: Array<Object>,
	userGetAuth: Function,
	setSessionsToken: Function,
	setToken: Function
};

class SessionIndex extends React.Component<Props, State> {
	constructor(props) {
		super(props)

	}

	componentWillReceiveProps(){
		if(this.props.isAuthorize==true){
			this.props.history.push("/support/users")
		}
	}


	render() {
		return(
	    	<div></div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		userAuth: state.userAuth,
		notifications: state.notifications,
		globalSweetAlertData: state.globalSweetAlertData,
		sessionsToken : state.sessionsToken,
		isAuthorize: state.isAuthorize
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userGetAuth: () => dispatch(userGetAuth()),
		setSessionsToken: (token) => dispatch(setSessionsToken(token)),
		setToken: (token) => dispatch(setToken(token))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionIndex))
