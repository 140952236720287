import React, { Component } from 'react'
import Select from 'react-select'

type Props = {
  classNameLabel: string,
  classNameDiv: string,
};

class RenderSelectMultiCreatable  extends Component {

	static defaultProps() {
		return {
			classNameLabel: '',
			classNameDiv: '',
		}
  }

	render() {
		const {
			input,
			label,
			placeholder,
			options,
			disabled,
			classNameLabel,
			classNameDiv,
			meta: { touched, error },
			defaultValue
		} = this.props
		
		return(
			<div className='input-container'>
			  <label className={classNameLabel}>{label}</label>
		  	<Select.Creatable
					className="select2 select-white"
		  	  value={input.value}
		  	  onChange={(newValue, delta, source,text) => {
		  	  	var temp = [] ;
		  	  		for (var i = 0; i < newValue.length; i++) {
		  	  			var new_value_with_space = newValue[i].value.split(" ");
		  	  			var new_value_with_comma = newValue[i].value.split(",");
		  	  			if(new_value_with_comma.length > 1){
							new_value_with_comma.map((value,index)=>{
								if(value != ''){
									temp.push({'className':'Select-create-option-placeholder','label':value.trim(),'value':value.trim()})
								}
							})
		  	  			}else if(new_value_with_space.length > 1){
							new_value_with_space.map((value,index)=>{
								if(value != ''){
									temp.push({'className':'Select-create-option-placeholder','label':value.trim(),'value':value.trim()})
								}
							})
		  	  			}else if(!newValue[i].value.includes(" ") && !newValue[i].value.includes(",") ){
									temp.push({'className':'Select-create-option-placeholder','label':newValue[i].value.trim(),'value':newValue[i].value.trim()})
		  	  			}

		  	  		}
            input.onChange(temp);
          }}
		  	  onBlur={() => input.onBlur(input.value)}
		  	  options={options}
		  	  placeholder={placeholder}
		  	  clearable={false}
		  	  autosize={false}
		  	  disabled={disabled}
		  	  multi={true}
					name="receiver"
					/>
					{touched && error && <div><span className="error js-err-email">{error}</span></div>}
			</div>
		)
	}
}

export default RenderSelectMultiCreatable
