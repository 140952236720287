import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import { List, fromJS } from 'immutable'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import { globalHideSweetAlert } from './global'

import { globalConcatImmutableData } from '../helpers/global'
import {setIsFetched} from './global'
import history from '../dependencies/history'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function publishJobsFetchDataSuccess(companies) {
	return {
		type: 'PUBLISH_JOB_FETCH_DATA_SUCCESS',
		companies
	}
}


export function publishJobsHasErrored(bool) {
	return {
		type: 'PUBLISH_JOB_HAS_ERRORED',
		hasErrored: bool
	}
}

export function publishJobsAddFilterPageNumberSuccess(page) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function publishJobsAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function publishJobsAddFilterSortSuccess(sort) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function publishJobsAddFilterNameSuccess(name) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_NAME_SUCCESS',
		name
	}
}


export function publishJobsClearFilterDataSuccess() {
	return {
		type: 'PUBLISH_JOB_CLEAR_FILTER_DATA_SUCCESS'
	}
}


export function publishJobsClearFormDataSuccess() {
	return {
		type: 'PUBLISH_JOB_CLEAR_FORM_DATA_SUCCESS'
	}
}

export function publishJobsFetchIdsSuccess(ids) {
	return {
		type: 'PUBLISH_JOB_FETCH_IDS_SUCCESS',
		ids
	}
}

export function publishJobsFetchPaginationSuccess(pagination) {
	return {
		type: 'PUBLISH_JOB_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function publishJobsFetchDataDropdownSuccess(companies) {
	return {
		type: 'PUBLISH_JOB_FETCH_DATA_DROPDOWN_SUCCESS',
		companies
	}
}

export function publishJobsAddNavigationPage(page) {
	return {
		type: 'PUBLISH_JOB_ADD_NAVIGATION_PAGE_SUCCESS',
		page
	}
}

export function publishJobsSaveDataRequest(formName) {
	return {
		type: 'PUBLISH_JOB_SAVE_DATA_REQUEST',
		formName
	}
}

export function publishJobsValidateEmailOnUserRegisterWizardFormRequest(email) {
	return {
		type: 'PUBLISH_JOB_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST',
		email
	}
}

export function publishJobsAddFilterSearchSuccess(search) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}

export function publishJobsAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterPageNumberSuccess(page)))
		})
	}
}

export function publishJobsAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function publishJobsAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterSortSuccess(sort)))
		})
	}
}

export function publishJobsAddFilterName(name) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterNameSuccess(name)))
		})
	}
}



export function publishJobsClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsClearFilterDataSuccess()))
		})
	}
}

export function publishJobsAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterSearchSuccess(search)))
		})
	}
}

export function publishJobsAddFilterStatus(status) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterStatusSuccess(status)))
		})
	}
}

export function publishJobsAddFilterStatusSuccess(status) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_STATUS_SUCCESS',
		status
	}
}

export function publishJobsAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(publishJobsAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function publishJobsAddFilterColPerPageSuccess(per_page){
	return{
		type:'PUBLISH_JOB_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

// Support Dashboard
export function publishJobsFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(publishJobsFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/popskul/publish-job-request`,
			timeout: 120000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			// const companyIds = response.data.data.ids
			const companies = response.data.data
			console.log('cc', companies);
			const pagination = omit(response.data, 'data')
			// dispatch(publishJobsFetchIdsSuccess(companyIds))
			dispatch(publishJobsFetchPaginationSuccess(pagination))
			dispatch(publishJobsFetchDataSuccess(companies))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			console.log('error', error);
			dispatch(publishJobsFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function handleUpdateStatus(companyId, status, appointmentId, channel='talk to us') {
	return (dispatch) => {
		return axios({
			url: `/support-dashboard/companies/${companyId}/set-plan-status`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {appointment_id: appointmentId, status: status, channel: channel}
		})
		.then((response) => {
			//update total notofocation when status = 3 (Done)
			if(status==3){
				dispatch(updateTotalAccountRequiringAction())
			}
			dispatch(Notifications.success({
				title: response.data.status,
				message: response.data.message,
				position: 'bl'
			}))
		})
		.catch((error) => {
			let errorMsg='An error occured'
			if(error.response){
				errorMsg=error.response.data.message
			}
			dispatch(Notifications.error({
			  title: 'Error',
			  message: errorMsg,
			  position: 'bl'
			}))
		})
	}
}

export function publishJobsAddFilterChannel(status) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(publishJobsAddFilterChannelSuccess(status)))
		})
	}
}

export function publishJobsAddFilterChannelSuccess(status) {
	return {
		type: 'PUBLISH_JOB_ADD_FILTER_CHANNEL_SUCCESS',
		status
	}
}

export function updateTotalAccountRequiringAction(total=1) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(updateTotalAccountRequiringActionSuccess(total)))
		})
	}
}

export function updateTotalAccountRequiringActionSuccess(total) {
	return {
		type: 'UPDATE_TOTAL_PUBLISH_JOB_REQUEST',
		total
	}
}

export function approveRejectPublishRequest(publish_id, is_approve) {
	return () => {
		return axios({
			url: `/support-dashboard/popskul/publish-job-request/approve`,
			method: 'post',
			responseType: 'json',
			data: {
				id: publish_id,
				status: is_approve
			}
		})
		.then(() => {return true})
		.catch(() => {return false})
	}
}






