
import { Map, List, fromJS } from 'immutable'

export function sessionsToken(state = Map({}), action) {
    switch (action.type) {
      case "SESSIONS_ADD_TOKEN_SUCCESS":
        return state.set("token", action.token);
      default:
        return state;
    }
}

export function isAuthorize(state = Map({}), action) {
  switch (action.type) {
    case "IS_LOGIN_SUCCESS":
      return action.isAuthorize;
    default:
      return state;
  }
}

export function userPrivilege(state = List([]), action) {
  switch (action.type) {
    case "SESSIONS_ADD_PRIVILEGE_SUCCESS":
      return action.privilege;
    default:
      return state;
  }
}