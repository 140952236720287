// @flow

import React from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server';
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import $ from 'jquery'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from "../../dependencies/_axios"
import PaginationSupport from '../../components/partials/PaginationSupport'
import defaultLogo from '../../assets/img/default-company-image.png'


import capitalize from 'voca/capitalize'

import ModalDownloadAccounts from '../../components/partials/ModalDownloadAccounts'
import ModalOtherBillingAgreement from '../../components/partials/ModalOtherBillingAgreement'
import ModalUpdateMetricData from '../../components/partials/ModalUpdateMetricData'
import ModalLeavePage from '../../components/partials/ModalLeavePage'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import {
	
	companiesGetCredit,
	companiesReactivate,
	companiesUpgradeLevel,
	creditsStatementAddFilterCompanyId,
	creditsStatementAddFilterPerPage,
	creditsStatementAddFilterPageNumber,
	creditsStatementAddFilterSort,
	creditsStatementAddFilterSearch,
	creditsStatementClearFilterData,
	companiesUpdateAccountManager,
	updateMetric,
	updateCompanyAdditionalPlan
} from '../../actions/companies'

import {
  voucherGetDetail,
	vouchersDelete,
	vouchersAddFilterSelectedIds,
	vouchersUsageFetchData,
	vouchersAddFilterSort,
	vouchersAddFilterTo,
	vouchersAddFilterFrom,
	vouchersAddFilterPageNumber
} from '../../actions/vouchers'

import TableLoader from '../partials/TableLoader'
import ModalConnectPopskul from '../../components/partials/ModalConnectPopskul'

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	voucherGetDetail: Function,
	companiesGetCredit: Function,
	vouchersDelete: Function,
	companiesReactivate: Function,
	companiesUpgradeLevel: Function,
	voucherId: string,
	creditsStatementAddFilterCompanyId: Function,
	creditsStatementAddFilterPerPage: Function,
	creditsStatementAddFilterPageNumber: Function,
	creditsStatementAddFilterSort: Function,
	creditsStatementAddFilterSearch: Function,
	vouchersAddFilterFrom: Function,
	vouchersAddFilterTo: Function,
	creditsStatementClearFilterData: Function,
};

let _changeAdditionalPlan;

const getClientTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
class VoucherDetail extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
            isDownloading: false,
			from: null,
			to: null,
			minDate: moment(),
			maxDate: moment(),
			accountManagerId: null,
			metric: { startDate:'', endDate:'', credit:'', contract:'', billing:'', billing_other:'' },
			flag_controlled:'',
			flag_pilot:'',
		}

		bindAll(this, [
			'handleDeleteVoucher',
			'handleUpgradeCompany',
			'handleReactiveCompany',
			'handleDownloadCreditStatement',
			'handleFilterFromDate',
			'handleFilterToDate',
			'handleSubmitFilter',
			'handleSortChange',
			'handleModalDownload',
			'handleSaveMetrics',
			'creditProgramFormater',
			'changeStartDate',
			'changeEndDate',
			'changeCredit',
			'changeContract',
			'changeBilling',
			'billingOther',
			'handleConnectDisconnectPopskul',
			'onCloseModal',
			'changeAdditionalPlan',


			'voucherRedeemerFormatter',
			'IssuerFormatter',
			'dateFormatter'
		])
	}

	componentDidMount() {
		const {
			voucherGetDetail,
			vouchersAddFilterSelectedIds,
            voucherId,
			vouchersUsageFetchData
		} = this.props
		voucherGetDetail(voucherId)
		vouchersAddFilterSelectedIds([voucherId])
		.then(()=>{
			vouchersUsageFetchData(this.props.vouchersFilterData)
			
		})
		
	}

	voucherRedeemerFormatter( row:Object):Object{
		console.log(row.account_name)
		if (row.platform == 'popskul') {
			
			return `${row.account_name}`
		}
		if (row.platform == 'astrnt') {
				return `${row.company_id} - ${row.company_name}`
		}

		
	}

	IssuerFormatter(row){
		if (row.platform == 'popskul') {
			return row.account_email
		}else{
			return row.email
		}
	}

	dateFormatter(row):Object{
		if (row) {
			const utcDate = moment.utc(row);
    
			// Convert to local timezone
			const localDate = utcDate.local();
			
			// Format the date as "MMM DD, YYYY"
			return localDate.format("MMM D, YYYY hh:mm A");


			
		}



	}

	purifyData(data){
		const sanitize_data = data.map((value,index)=>{
			 value.company_name = renderToString(value.company_name)
			 value.name = renderToString(value.name)
			 value.preferred_name = renderToString(value.preferred_name)
			 value.email = renderToString(value.email)
			 return value
		})

		return sanitize_data;
	}

	handleModalDownload(e: Object){
		e.preventDefault()

		$('#modalDownloadAccounts').modal('show')
	}

	handleSaveMetrics(e: Object){
		e.preventDefault()

		$('#modalUpdateMetricData').modal('show')
	}

	handleUpgradeCompany(e, levelId, levelTitle){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesUpgradeLevel,
			voucherId,
			voucherGetDetail
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Upgrade',
			cancelButtonText: 'Cancel',
			title: `Upgrade to ${levelTitle}?`,
			html: true,
			text: `This account level will be adjusted to “<strong>${levelTitle}</strong>” once you upgrade it. The remaining credits and active periods from the previous level will be added to your new level.`,
			onConfirm: () => {
				globalHideSweetAlert()
				companiesUpgradeLevel(voucherId, levelId)
				// .then(() => {
				// 	voucherGetDetail(voucherId)
				// 	voucherGetDetail(voucherId)
				// })
				
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleReactiveCompany(e){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			voucherGetDetail,
			companiesReactivate,
			voucherId,
			company
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Reactivate',
			cancelButtonText: 'Cancel',
			title: `Reactivate company’s account?`,
			text: "This account is currently suspended. You will halt the suspension process and reactivate this company’s account.",
			onConfirm: () => {
				companiesReactivate(voucherId)
				.then(()=>{
					voucherGetDetail(voucherId)
					globalHideSweetAlert()
				})
				
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleDeleteVoucher(e){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			voucherGetDetail,
			vouchersDelete,
			voucherId,
			
		} = this.props

		
		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Delete Voucher?`,
			text: "You will delete this voucher and you're not able to restore it.",
			onConfirm: () => {
				vouchersDelete(voucherId).then(()=>{
					// voucherGetDetail(voucherId)
					globalHideSweetAlert()
					this.props.history.push(`/support/voucher`)
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})

		
	}

	updateAccountManager(val) {
		const { voucherId, companiesUpdateAccountManager } = this.props
		companiesUpdateAccountManager(voucherId, val, 0)
		this.setState({accountManagerId: val})
	}

	handleDownloadCreditStatement(e){
		e.preventDefault()
		const {
			vouchersFilterData,
            voucherId
		} = this.props
		this.setState({isDownloading:true})
		const _data = vouchersFilterData.toJS()
		_data.timezone = getClientTimezone();
		axios({
			url: `/support-dashboard/vouchers/export-usage`,
			method: 'POST',
			responseType: 'blob',
			crossDomain: true,
			data: _data
		}).then((response) => {
			setTimeout(()=>{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a')
				link.href = url
				const formattedDate = moment().format('DD-MM-YYYY');
				link.setAttribute('download', `${'Voucher Usage '}${formattedDate}.xlsx`)
				document.body.appendChild(link);
				link.click()
				this.setState({isDownloading:false})
			}, 3000)
		})
		.catch((error)=>{
			this.setState({isDownloading:false})
		})
	}

	handleFilterFromDate(dateVal){
		const { vouchersAddFilterFrom } = this.props
		this.setState({from:moment(dateVal),minDate:dateVal, to:''})
		let maxDate = moment(dateVal).add(1,"year")
		if(maxDate<moment()) {this.setState({maxDate:maxDate})}
		else this.setState({maxDate:moment()})
		vouchersAddFilterFrom(dateVal.format('DD-MM-YYYY'))
	}

	handleFilterToDate(dateVal){
		const {
			vouchersAddFilterTo
		} = this.props
		this.setState({to:moment(dateVal)})
		vouchersAddFilterTo(dateVal.format('DD-MM-YYYY'))
	}

	handleSubmitFilter(e){
		e.preventDefault()
		this.props.vouchersUsageFetchData(this.props.vouchersFilterData);
	}

	handleSortChange(name, order){
		const {
			vouchersAddFilterSort,
			vouchersUsageFetchData,
		} = this.props

		let sort={
			name: name,
			value: order
		}

		vouchersAddFilterSort(sort).then(()=>
		{
		
			vouchersUsageFetchData(this.props.vouchersFilterData)
			
		}
	)

	}

	creditProgramFormater(cell: Object, row: Object): Object{
		if(['Top Up Credits','Free Credits for Free Trial'].includes(row.title)){
			return '-'
		}
		return row.title
	}

	changeStartDate(val) {
		let metric = this.state.metric
		metric.startDate = val
		this.setState({metric: metric})
	}

	changeEndDate(val) {
		let metric = this.state.metric
		metric.endDate = val
		this.setState({metric: metric})
	}

	changeCredit(val) {
		let metric = this.state.metric
		metric.credit = val
		this.setState({metric: metric})
	}
	
	changeContract(val) {
		let metric = this.state.metric
		metric.contract = val
		this.setState({metric: metric})
	}

	changeBilling(val) {
		let metric = this.state.metric
		metric.billing = val
		if(val=="Other")
			$("#modalOtherBillingAgreement").modal("show")
		else 
			metric.billing_other = null
		this.setState({metric: metric})
	}

	billingOther(val) {
		let metric = this.state.metric
		metric.billing_other = val
		this.setState({metric: metric})
	}

	handleConnectDisconnectPopskul(e, company){
		e.preventDefault()
		$('#modalConnectPopskul').modal('show')
	}

	onCloseModal(){
		this.props.voucherGetDetail(this.props.company.get('id'))
	}

	changeAdditionalPlan(e, type) { // [controlled,pilot]
		e.preventDefault()

		const { company, updateCompanyAdditionalPlan } = this.props
		const checked = e.target.checked

		clearTimeout(_changeAdditionalPlan);
		_changeAdditionalPlan = setTimeout(() => {
			updateCompanyAdditionalPlan(company.get('id'), type, checked)
			if(type == 'controlled') {
				this.setState({flag_controlled:checked})
			} else {
				this.setState({flag_pilot:checked})
			}
		}, 1000)
	}

  voucherValidFunction(validFrom,validTo):Object{
    if (validFrom && validTo) {
    
        let utcDateFrom = moment(validFrom);
    
        // Convert to local timezone
        let localDateFrom = utcDateFrom.local();
        
        // Format the date as "MMM DD, YYYY"
        let from =  utcDateFrom.format("MMM DD, YYYY");

        let utcDateTo = moment(validTo);
    
        // Convert to local timezone
        let localDateTo = utcDateTo.local();

        let to = utcDateTo.format("MMM DD, YYYY");

        return `${utcDateFrom.format("MMM DD, YYYY")} - ${utcDateTo.format("MMM DD, YYYY")}`

    } 
    return '';
	}

  statusFormatter(status): Object {
    if (status) {
      switch (status) {
        case 'active':
          return <p className={'text-green'}>{capitalize(status)}</p>
          
        case 'expired':
          return <p className={'text-red'}>{capitalize(status)}</p>
      
        default:
          return <p className={'text-blue'}>{capitalize(status)}</p>
        
  
      }
    }else{
      return ''
    }
  }

  valueFormatter(row:Object):Object{
		if (row.discount_type == 'percent') {
				return `${parseInt(row.discount_value).toFixed()} %`
		}else {
			if (row.currency) {
				if(row.currency.toLowerCase() == 'idr'){
					const currency = new Intl.NumberFormat('de-DE').format(parseInt(row.discount_value).toFixed()); 
					return `Rp ${currency}`
				}else{
					return `${row.currency.toUpperCase()} ${parseInt(row.discount_value).toFixed()}`
				}
			}
			
		}

	}

	typeFormatter(row):Object{
		switch (row.discount_type) {
			case 'percent':
				return 'Percentage'
				
			case 'price':
				return 'Fixed Price'
			default:
				return 'Amount'
		}


	}

  	render() {
		const {
			voucher,
			vouchers,
			credit,
		
			vouchersUsageFetchData,
			vouchersFilterData,
			vouchersPagination,
			vouchersAddFilterPageNumber,
			userPrivilege,
		} = this.props

		let detailVoucher = voucher.toJS();
    console.log(detailVoucher,'detailVoucher')
    let voucherCode = detailVoucher.code || ''
    let voucherDesc = detailVoucher.description || ''
    let voucherProduct = detailVoucher.product || ''
    let voucherType = this.typeFormatter(detailVoucher) || ''
    let voucherValue = this.valueFormatter(detailVoucher) || ''
    let voucherValid = this.voucherValidFunction(detailVoucher.valid_from,detailVoucher.valid_to) || ''
    let maxRedemption = detailVoucher.max_user || ''
    let status = this.statusFormatter(detailVoucher.status) 


		let companyAdmin = detailVoucher.administrator  || {}
		let companyCredit = detailVoucher.credit || {}
		let companyLevel = detailVoucher.levels || []
		companyLevel = companyLevel.filter(level=> ![detailVoucher.company_level,9].includes(level.id))
		let userLevelLabel;
		let userLevelBadgeStatus;
	

    let creditList = credit.toJS();

		let creditPagination: ?Object
		
		if (!vouchers.isEmpty()) {
			creditPagination =
			<PaginationSupport
					pagination={vouchersPagination}
					fetchData={vouchersUsageFetchData}
					filterData={vouchersFilterData}
					addPageNumber={vouchersAddFilterPageNumber} />
		}

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		}

		let prevState = this.props.location.state
		let backPath = '/support/voucher'
		let upgradeTo = ''
		if(prevState !== undefined){
			if(prevState.prevPage === 'company-index'){
				prevState = 'company-detail'
			}else if( prevState.prevPage === 'custom-plan'){
				upgradeTo = prevState.upgradeTo
				prevState = 'custom-plan'
				backPath='/support/custom-plan'	
			}
		}

		let disabled_apply = (this.state.to ? false : true)
		let disabled_to = (this.state.from ? false : true)
		let accountManagerId = (this.state.accountManagerId ? this.state.accountManagerId : detailVoucher.account_manager_id)

		let buttonLabelConnect = 'Connect'
		let buttonClassConnect = 'button__primary'
		let colorLabelConnect  = 'tag-default'
		let labelConnect       = 'Not Connected to POPSkul'
		if(voucher.get('popskul_connection')==1){
			labelConnect       = 'Connected to POPSkul'
			colorLabelConnect  = 'tag-blue'
			buttonLabelConnect = 'Disconnect'
			buttonClassConnect = 'button__secondary'
		}

		let checkboxFlagControlled = <label className='checkbox-add-plan'>Controlled by ASTRNT
			<input type='checkbox' onChange={(e) => this.changeAdditionalPlan(e, 'controlled')} checked={this.state.flag_controlled}
			/>
			<span class="checkmark"></span>
		</label>
		let checkboxFlagPilot = <label className='checkbox-add-plan'>Pilot Plan
			<input type='checkbox' onChange={(e) => this.changeAdditionalPlan(e, 'pilot')} checked={this.state.flag_pilot}
			/>
			<span class="checkmark"></span>
		</label>

		return (
			<div className="div-support-container">
				<section className="wrapper">
				<div className="main-content">
					<div className="container-fluid">
					<div className="big-panel">
						<div className="flex-container flex-between">
							<Link to={{pathname : backPath, state:{prevPage:prevState}}} className="btn-support"><i className="fa fa-angle-left" style={{fontSize: '18px'}}></i> Back to Voucher List</Link>
							<div className={`${userPrivilege.includes('UPDATE_USER')?'':'hide'}`}>
								<Link to={`/support/voucher/${detailVoucher.id}/edit`} className="btn-support-action secondary m-r-12">Edit </Link>
                      <button onClick={(e)=>this.handleDeleteVoucher(e)} className={`btn-support-action danger  ${detailVoucher.suspended ? 'hide' : ''}`}>Delete</button>
							</div>
						</div>
						<hr style={{margin:'8px 0 0',borderTop: '1px solid #E8E8E8'}} />
						<div className="flex-container flex-between m-t-40">
							<div className="col-md-12">
								<div className="company-detail-card detail-card p-24">
									<div className="voucher-detail-info">
										<label>Voucher Code</label>
										<p>{voucherCode}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Description</label>
										<p>{voucherDesc}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Applicable Product</label>
										<p>{voucherProduct}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Discount Type</label>
										<p>{voucherType}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Discount Value</label>
										<p>{voucherValue}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Validity Date</label>
										<p>{voucherValid}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Max Redemption</label>
										<p>{maxRedemption}</p>
									</div>
									<div className="voucher-detail-info">
										<label>Status</label>
                    {status}
										{/* <p className={`${is_active ? 'text-green' : 'text-red'}`}>Active</p> */}
										{/* <p className={`text-red`}>Expired</p> */}
										{/* <p className={`text-blue`}>Upcoming</p> */}
										{/* <p className={`text-green`}>Active</p> */}
									</div>
								</div>
							</div>
						</div>

						<div className="table-credit-container ">
							<label>Voucher Usage</label>
							<div className="div-download-csv flex-container flex-between">
								<div className="div-date-range flex-container vertical-item--center">
									<label className="m-r-16">From</label>
									<div className="datepick-container-new horizontal-item--between">
										<DatePicker
											onChange = {this.handleFilterFromDate}
											value={this.state.from ? this.state.from.format("DD/MM/YYYY") : ''}
											dateFormat="DD/MM/YYYY"
											placeholderText="DD/MM/YYYY" 
											maxDate={moment()}
											// selected={this.state.from}
											/>
										<i className="icon-i-calendar" />
									</div>
									<label className="m-l-important-16 m-r-16">To</label>
									<div className="datepick-container-new horizontal-item--between">
										<DatePicker
											onChange= {this.handleFilterToDate}
											value={this.state.to ? this.state.to.format("DD/MM/YYYY") : ''}
											dateFormat="DD/MM/YYYY"
											placeholderText="DD/MM/YYYY" 
											minDate={this.state.minDate}
											maxDate={this.state.maxDate} 
											// selected={this.state.to}
											disabled={disabled_to}
											/>
										<i className="icon-i-calendar" />
									</div>
									<button onClick={this.handleSubmitFilter} className="btn-support-action primary m-l-12 p-l-16 p-r-16 p-t-12 p-b-12" disabled={disabled_apply}>Apply</button>
								</div>
								<div className="div-button flex-container vertical-item--center">
									<button disabled={this.state.isDownloading} className="btn-support-action transparent" onClick={this.handleDownloadCreditStatement}><i className={`${this.state.isDownloading ? 'hide':'icon-i-download-purple'}`}></i><i className={`${this.state.isDownloading ? 'fa fa-spinner fa-pulse fa-fw m-r-5':'hide'}`}></i>Download Voucher Usage</button>
								</div>
							</div>
							<div className="report-list-container form-custom">
								<BootstrapTable
									data={this.purifyData(vouchers.toJS())}
									striped={true}
									options = {options}
								>
									<TableHeaderColumn dataField="id" isKey={true} dataSort={false} hidden>ID</TableHeaderColumn>
									<TableHeaderColumn dataFormat={(cell,row)=>this.voucherRedeemerFormatter(row)} dataField="combined_name" dataSort={true}>Voucher Redeemer</TableHeaderColumn>
									<TableHeaderColumn dataFormat={(cell,row)=>this.IssuerFormatter(row)} dataField="combined_email"  dataSort={true}>Issuer</TableHeaderColumn>
									<TableHeaderColumn dataFormat={(cell,row)=>this.dateFormatter(row.created_at)} dataField="created_at"  dataSort={true}>Issue Date</TableHeaderColumn>
								
								</BootstrapTable>
								{creditPagination}
							</div>
						</div>
					</div>
					</div>
				</div>
				</section>
			
			</div>
		)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		voucherId: ownProps.match.params.voucherId,
		voucher: state.voucher,
		credit: state.credit,
		creditsStatementFilterData: state.creditsStatementFilterData,
		creditsStatementPagination: state.creditsStatementPagination,
		isFetched: state.isFetched,
		accountManagers: state.accountManagers,
		userPrivilege: state.userPrivilege,
		vouchers: state.vouchers,
		vouchersFilterData: state.vouchersFilterData,
		vouchersPagination: state.vouchersPagination
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		voucherGetDetail: (filter_data) => dispatch(voucherGetDetail(filter_data)),
		companiesGetCredit: (companyId, filter_data) => dispatch(companiesGetCredit(companyId, filter_data)),
		vouchersDelete: (filter_data) => dispatch(vouchersDelete(filter_data)),
		companiesReactivate: (filter_data) => dispatch(companiesReactivate(filter_data)),
		companiesUpgradeLevel: (companyId, levelId) => dispatch(companiesUpgradeLevel(companyId, levelId)),
		creditsStatementAddFilterCompanyId: company_id => dispatch(creditsStatementAddFilterCompanyId(company_id)),
		creditsStatementAddFilterPerPage: per_page => dispatch(creditsStatementAddFilterPerPage(per_page)),
		creditsStatementAddFilterPageNumber: page => dispatch(creditsStatementAddFilterPageNumber(page)),
		creditsStatementAddFilterSort: sort => dispatch(creditsStatementAddFilterSort(sort)),
		creditsStatementAddFilterSearch: search => dispatch(creditsStatementAddFilterSearch(search)),
		vouchersAddFilterFrom: from => dispatch(vouchersAddFilterFrom(from)),
		vouchersAddFilterTo: to => dispatch(vouchersAddFilterTo(to)),
		creditsStatementClearFilterData : () => dispatch(creditsStatementClearFilterData()),
		companiesUpdateAccountManager: (companyId, accountManagerId, appointmentId) => dispatch(companiesUpdateAccountManager(companyId, accountManagerId, appointmentId)),
		updateMetric: (companyId, data) => dispatch(updateMetric(companyId, data)),
		updateCompanyAdditionalPlan: (companyId, type, additional_plan) => dispatch(updateCompanyAdditionalPlan(companyId, type, additional_plan)),
		
		vouchersAddFilterSelectedIds: (ids) => dispatch(vouchersAddFilterSelectedIds(ids)),
		vouchersUsageFetchData: (filter_data) => dispatch(vouchersUsageFetchData(filter_data)),
		vouchersAddFilterSort: (sort) => dispatch(vouchersAddFilterSort(sort)),
		vouchersAddFilterFrom:(from)=>dispatch(vouchersAddFilterFrom(from)),
		vouchersAddFilterTo:(from)=>dispatch(vouchersAddFilterTo(from)),
		vouchersAddFilterPageNumber: (page) => dispatch(vouchersAddFilterPageNumber(page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherDetail)
