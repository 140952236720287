import isNil from 'lodash/isNil'

import isEmpty from 'lodash/isEmpty'
import configurations from '../../misc/configurations'

const validate = values => {
	const errors = {}

	if (!values.email) {
		errors.email = `Email can't be blank`
	} else {
		if (!configurations.emailRegex.test(values.email)) {
			errors.email = `Invalid email`
		}
	}

	if (!values.name) {
		errors.name = `Name can't be blank`
	}else{
		if(values.name.length < 3){
			errors.name = `Name cannot less than 3 characters`
		}
	}

	if (!values.preferred_name) {
		errors.preferred_name = `Preferred Name can't be blank`
	}else{
		if(values.preferred_name.length < 3){
			errors.preferred_name = `Preferred Name cannot less than 3 characters`
		}
	}

	if (!values.company ) {
		let msg = `Company Name can't be blank`
		if(values.channel){
			if(values.channel=='career_development_center'){
				msg = `University Name can't be blank`
			}
		}
		errors.company = msg
	} else{
		if(values.company.length > 65){
			let msg = `Company Name cannot more than 65 characters`
			if(values.channel){
				if(values.channel == 'career_development_center'){
					msg = `University Name cannot more than 65 characters`
				}
			}
			errors.company = msg
		}
	}


	if (!values.mobile_number) {
		errors.mobile_number = `Mobile Number can't be blank`
	} else{
		if(values.mobile_number.length > 13){
			errors.mobile_number = `Mobile Number cannot more than 13 characters`
		}

		if(values.mobile_number.length < 10){
			errors.mobile_number = `Mobile Number cannot less than 10 characters`
		}
	}

	if (!values.user_level) {
		errors.user_level = `Please select user level`
	}

	if (!values.body) {
		errors.body = `Body Message field can't be empty`
	}
	if (!values.subject) {
		errors.subject = `Subject Message field can't be empty`
	}

	if(values.current_user_level == 44 && values.current_user_level != values.user_level && !values.delegate_id) {
		errors.delegate_id = `Please select user to delegate`
	}

	if (!isEmpty(values.receiver)) {
		values.receiver.forEach((email) => {
			if (!configurations.emailRegex.test(email.value.trim())) {
			  errors.receiver = 'Invalid email address'
			}
		})
	}

	if (!values.channel) {
		errors.channel = `Please Select channel`
	} 

	return errors
}

export default validate

