// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map } from 'immutable'

import {
	connectDisconnectCDC
} from '../../actions/companies'

type Props = {
	isSuccess: Boolean,
	company: Map<string, any>,
	onCloseModal:Function
}

class ModalConnectCDC extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			isSuccess: false
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handlePopskulConnection'
		])
	}

	handleCloseModalClick(e: Object) {
		e.preventDefault()
		$('#ModalConnectCDC').modal('hide')
	}

	handlePopskulConnection(e){
		const { company, connectDisconnectCDC } = this.props
		let isConnect = true
		if(company.channel=='career_development_center'){
			isConnect = false
		}
		
		connectDisconnectCDC(company.id, isConnect).then(()=>{
			this.props.onCloseModal()
			this.handleCloseModalClick(e)
		})
	}

	
  	render() {
  		const {
			company
		} = this.props;
		let title = 'Disconnect the company from Career Development Center?'
		let description = 'The company and jobs will disappear from Career Development Center.'
		let buttonLabel = 'Disconnect'
		if(company.channel != 'career_development_center'){
			title = 'Connect the company to Career Development Center?'
			description = 'The company and jobs will appear at Career Development Center.'
			buttonLabel = 'Connect'
		}

		return (
			<div className="modal custom-modal" id="ModalConnectCDC" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
			  <div className="modal-dialog wrapper-modal-download-all">
					<div className="modal-content animated bounceInRight text-center">
					  <div className="modal-header">
						  <label>{title}</label>
					  </div>
					  <div className="modal-body">
						  <div className="input-container text-center">
						  <p>{description}</p>
						  </div>
					  </div>
					  <div className="modal-footer text-center m-r-25 m-l-25">
						  <button className="button button-cancel m-r-16 pull-left" onClick={this.handleCloseModalClick}>Cancel</button>
						  <button className="button button-download" onClick={(e)=>this.handlePopskulConnection(e)}>
							  { buttonLabel }
						  </button>
					  </div>
					</div>
			  </div>
			</div>
	  	)
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		connectDisconnectCDC: (companyId, isConnect) => dispatch(connectDisconnectCDC(companyId, isConnect))
	}
}

export default connect(null, mapDispatchToProps)(ModalConnectCDC)
