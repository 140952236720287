import axios from "../dependencies/_axios";
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import { List, fromJS } from 'immutable'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import history from '../dependencies/history'
import {setIsFetched} from './global'

require('es6-promise').polyfill()

axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		
		return config;
	},
	error => {
		Promise.reject(error)
	}
);
  
axios.interceptors.response.use(
	function(successRes) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		return successRes;
	}, 
	function(error) {
		if(error.response.status === 401){
			window.location = `${initializers.api_host}/auth/login`
		}else{
			if(error.response.data.error=='token_invalid' || error.response.data.error == 'token_not_provided'){
				window.location = `${initializers.api_host}/auth/login`
			}
		}
		return Promise.reject(error);
	}
)

export function transactionsFetchData(filterData) {
	return (dispatch) => {
		NProgress.remove()
		dispatch(setIsFetched(false))
		dispatch(transactionsFetchDataSuccess([]))
		return axios({
			url:`/support-dashboard/transactions/filter`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			const transactions = response.data.data.data
			const pagination = omit(response.data.data, 'data')
			dispatch(transactionsFetchPaginationSuccess(pagination))
			dispatch(transactionsFetchDataSuccess(transactions))
			dispatch(setIsFetched(true))
		})
		.catch((error) => {
			dispatch(transactionsFetchDataSuccess([]))
			dispatch(setIsFetched(true))
			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data',
				position: 'bl'
			}))
		})
	}
}

export function transactionGetDetail(id) {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url: `/support-dashboard/transactions/${id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()
			dispatch(transactionGetDetailSuccess(response.data.data))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data.',
				position: 'bl'
			}))
		})
	}
}

export function transactionGetDetailSuccess(transaction) {
	return {
		type: 'TRANSACTION_GET_DETAIL_SUCCESS',
		transaction
	}
}

export function transactionUpdateInvoiceUrl(invoice_file_url) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionUpdateInvoiceUrlSuccess(invoice_file_url)))
		})
	}
}

export function transactionUpdateEfakturUrl(efaktur_file_url) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionUpdateEfakturUrlSuccess(efaktur_file_url)))
		})
	}
}


export function transactionUpdateInvoiceUrlSuccess(invoice_file_url) {
	return {
		type: 'TRANSACTIONS_UPDATE_INVOICE_URL_SUCCESS',
		invoice_file_url
	}
}

export function transactionUpdateEfakturUrlSuccess(efaktur_file_url) {
	return {
		type: 'TRANSACTIONS_UPDATE_EFAKTUR_URL_SUCCESS',
		efaktur_file_url
	}
}

export function transactionsFetchPaginationSuccess(pagination) {
	return {
		type: 'TRANSACTIONS_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function transactionsFetchDataSuccess(transactions) {
	return {
		type: 'TRANSACTIONS_FETCH_DATA_SUCCESS',
		transactions
	}
}

export function transactionsAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsAddFilterPageNumberSuccess(page)))
		})
	}
}

export function transactionsAddFilterPageNumberSuccess(page) {
	return {
		type: 'TRANSACTIONS_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function transactionsAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsAddFilterSortSuccess(sort)))
		})
	}
}

export function transactionsAddFilterSortSuccess(sort) {
	return {
		type: 'TRANSACTIONS_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function transactionsAddFilterCompanyName(company_name) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsAddFilterCompanyNameSuccess(company_name)))
		})
	}
}

export function transactionsAddFilterCompanyNameSuccess(company_name) {
	return {
		type: 'TRANSACTIONS_ADD_FILTER_COMPANY_NAME_SUCCESS',
		company_name
	}
}

export function transactionsAddFilterColPerPage(per_page){
	return (dispatch)=>{
		return new Promise((res,rej)=>{
			res(dispatch(transactionsAddFilterColPerPageSuccess(per_page)))
		})
	}
}

export function transactionsAddFilterColPerPageSuccess(per_page){
	return{
		type:'TRANSACTIONS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS',
		per_page
	}
}

export function transactionsAddFilterPaymentMethod(payment_method) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsAddFilterPaymentMethodSuccess(payment_method)))
		})
	}
}

export function transactionsAddFilterPaymentMethodSuccess(payment_method) {
	return {
		type: 'TRANSACTIONS_ADD_FILTER_PAYMENT_METHOD_SUCCESS',
		payment_method
	}
}

export function transactionsAddFilterPaymentStatus(payment_status) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsAddFilterPaymentStatusSuccess(payment_status)))
		})
	}
}

export function transactionsAddFilterPaymentStatusSuccess(payment_status) {
	return {
		type: 'TRANSACTIONS_ADD_FILTER_PAYMENT_STATUS_SUCCESS',
		payment_status
	}
}

export function transactionsClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsClearFilterDataSuccess()))
		})
	}
}

export function transactionsClearFilterDataSuccess() {
	return {
		type: 'TRANSACTIONS_CLEAR_FILTER_DATA_SUCCESS'
	}
}

export function transactionsUpdatePaymentStatus(transactionId, status) {
	return (dispatch) => {
		NProgress.start()
		return axios({
			url:`/support-dashboard/transactions/${transactionId}/status`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {payment_status: status}
		})
		.then((response) => {
			NProgress.done()
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading data.',
				position: 'bl'
			}))
		})
	}
}

export function transactionsAddFilterSearch(search) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(transactionsAddFilterSearchSuccess(search)))
		})
	}
}

export function transactionsAddFilterSearchSuccess(search) {
	return {
		type: 'TRANSACTIONS_ADD_FILTER_SEARCH_SUCCESS',
		search
	}
}