import { List, Map, fromJS } from 'immutable'

const initFilterData = fromJS({
	page: '',
	selected_ids: [],
	sort: '',
	per_page:10,
	platform:'',
	product:'',
	product_values:'',
	category:'',
	category_values:'',
	discount_type:'',
	currency:'',
	discount_value:'',
	status:'',
	valid_from:'',
	valid_to:'',
	max_user:'',
	search:'',
	from:'',
	to:'',
})

export function vouchers(state = List([]), action) {
	switch(action.type) {
		case 'VOUCHERS_FETCH_DATA_SUCCESS':
			return fromJS(action.vouchers)
		default:
			return state
	}
}

export function vouchersIds(state = List([]), action) {
  switch(action.type) {
    case 'VOUCHERS_FETCH_IDS_SUCCESS':
    	return List(action.ids)
    default:
    	return state
  }
}


export function vouchersPagination(state = Map({}), action) {
	switch(action.type) {
		case 'VOUCHERS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

export function vouchersFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'VOUCHERS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'VOUCHERS_ADD_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('selected_ids', List(action.selected_ids))
		case 'VOUCHERS_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'VOUCHERS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'VOUCHERS_ADD_FILTER_STATUS_SUCCESS':
			return state.set('status',action.status)
		case 'VOUCHERS_ADD_FILTER_VALID_FROM_SUCCESS':
			return state.set('valid_from',action.valid_from)
		case 'VOUCHERS_ADD_FILTER_VALID_TO_SUCCESS':
			return state.set('valid_to',action.valid_to)
		case 'VOUCHERS_ADD_FILTER_FROM_SUCCESS':
			return state.set('from',action.from)
		case 'VOUCHERS_ADD_FILTER_TO_SUCCESS':
			return state.set('to',action.to)
		case 'VOUCHERS_ADD_FILTER_SEARCH_SUCCESS':
			return state.set('search', action.search)
		case 'VOUCHERS_ADD_FILTER_PLATFORM_SUCCESS':
			return state.set('platform', action.platform)
		case 'VOUCHERS_ADD_FILTER_PRODUCT_SUCCESS':
			return state.set('product', action.product)
		case 'VOUCHERS_ADD_FILTER_PRODUCT_VALUES_SUCCESS':
			return state.set('product_values', action.product_values)
		case 'VOUCHERS_ADD_FILTER_CATEGORY_SUCCESS':
			return state.set('category', action.category)
		case 'VOUCHERS_ADD_FILTER_CATEGORY_VALUES_SUCCESS':
			return state.set('category_values', action.category_values)
		case 'VOUCHERS_ADD_FILTER_DISCOUNT_TYPE_SUCCESS':
			return state.set('discount_type', action.discount_type)
		case 'VOUCHERS_ADD_FILTER_CURRENCY_SUCCESS':
			return state.set('currency', action.currency)
		case 'VOUCHERS_ADD_FILTER_DISCOUNT_VALUE_SUCCESS':
			return state.set('discount_value', action.discount_value)
		case 'VOUCHERS_ADD_FILTER_MAX_USER_SUCCESS':
			return state.set('max_user', action.max_user)
		case 'VOUCHERS_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				selected_ids: [],
				sort: '',
				per_page:10,
				platform:'',
				product:'',
				product_values:'',
				category:'',
				category_values:'',
				discount_type:'',
				currency:'',
				discount_value:'',
				status:'',
				valid_from:'',
				valid_to:'',
				max_user:'',
				from:'',
				to:'',
				search:''
			})
		default:
			return state
	}
}


export function voucher(state = Map({}), action) {
	switch(action.type) {
		case 'VOUCHER_GET_DETAIL_SUCCESS':
			return fromJS(action.voucher)
		case 'CLEAR_FORM_DATA_VOUCHER_SUCCESS':
			return fromJS({
				code: '',
				description: '',    
				platform: '',       
				discount_type: '',  
				currency: '',       
				discount_value: '', 
				start_date: '',     
				end_date: '',       
				max_user: '',       
				product: '',        
				product_values: '', 
				category: '',       
				category_values: ''
			})
		default:
			return state
	}
}


export function certificates(state = List([]), action) {
	switch(action.type) {
		case 'VOUCHER_GET_CERTIFICATES_SUCCESS':
			return fromJS(action.certificates)
		default:
			return state
	}
}

