// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'

import {
	transactionGetDetail,
	transactionsUpdatePaymentStatus,
	transactionUpdateInvoiceUrl,
	transactionUpdateEfakturUrl
} from '../../actions/transactions'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert
} from '../../actions/global'

import {
	formatBytes
} from '../../helpers/global'
import axios from "../../dependencies/_axios";
import moment from 'moment'

require('es6-promise').polyfill()

type Props = {
    transactionId: string,
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	transactionGetDetail: Function,
	globalIndicatorData: Map<string, any>,
	transactionsUpdatePaymentStatus: Function,
	transactionUpdateInvoiceUrl: Function,
	transactionUpdateEfakturUrl: Function,
};

type State = {
	isShown: boolean
};
const documentFileType=[
	'application/pdf'
]

var ajaxCancel = null;
class TransactionDetail extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			efaktur_is_error_upload:false,
			efaktur_upload_status:'',
			efaktur_progress_upload:0,
			efaktur_upload_error_message:'',
			efaktur_upload_progress_message:'',
			efaktur_temp_file:'',
			invoice_is_error_upload:false,
			invoice_upload_status:'',
			invoice_progress_upload:0,
			invoice_upload_error_message:'',
			invoice_upload_progress_message:'',
			invoice_temp_file:'',
			invoice_url:'',
			efaktur_url:''
		}

		bindAll(this, [
			'handleUpdatePaymentStatus',
			'handleUpdatePaymentStatus',
			'handleBackPage',
			'handleUploadInvoiceInput',
			'handleUploadInvoiceClick',
			'handleUploadEfakturInput',
			'handleUploadEfakturClick'
		])
	}

	componentDidMount() {
		const {
			transactionGetDetail,
            transactionId
		} = this.props
		transactionGetDetail(transactionId)
	}

	

	handleUpdatePaymentStatus(e): void{
		let paymentStatus = e.target.value
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			transactionsUpdatePaymentStatus,
			transactionGetDetail,
			transactionId
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: `Update`,
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Update Payment`,
			text: `Are you sure to Update payment status?`,
			onConfirm: () => {
				transactionsUpdatePaymentStatus(transactionId, paymentStatus).then(()=>{
					transactionGetDetail(transactionId)
					globalAddSweetAlertData({
						show: true,
						title: 'Success!',
						text: `Success Update payment statys`,
						confirmButtonText: 'OK',
						confirmButtonColor: '#564FDB',
						showLoaderOnConfirm: true,
						onConfirm: () => {
							globalHideSweetAlert()
						}
					})
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleBackPage(){
		this.props.history.push({ 
			pathname: '/support/transactions',
			state: {prevPage: 'transaction-detail'}
		})
	}

	handleUploadInvoiceClick(){
		const input = document.getElementById('invoice_file');
		input.click();
	}

	handleUploadInvoiceInput(e) {
		const {
			transactionId,
			transactionUpdateInvoiceUrl
		} = this.props

		var $this = this
		let file = e.target.files
		$this.setState({
			invoice_upload_error_message:'',
			invoice_upload_progress_message:'',
		})
		
		if (documentFileType.includes(file[0].type)) {
			const formData = new FormData();
			if(file[0].size>10000000){
				$this.setState({
					invoice_is_error_upload: true,
					invoice_upload_status: 2
				})
				
				$this.setState({invoice_upload_error_message:'The file may not be greater than 10 Mb.'})
			}else{
				$this.setState({
					invoice_is_error_upload: false,
					invoice_progress_upload: 0,
					invoice_upload_status: 0,
					invoice_temp_file: file[0].name
				})

				formData.append('file', file[0])

				// ajaxCancel = axios.CancelToken.source()
				axios({
					url: `/support-dashboard/transactions/${transactionId}/invoice/upload`,
					method: "post",
					timeout: 500000,
					data: formData,
					onUploadProgress: progressEvent => {
						const uploadAudioPercentage = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						let progressSize= (file[0].size * uploadAudioPercentage)/100
						$this.setState({
							invoice_progress_upload: uploadAudioPercentage,
							invoice_upload_progress_message: formatBytes(progressSize) +' of '+ formatBytes(file[0].size)
						})
					},
				})
				.then((response) => {
					if (response.data.status <= 200) {
						let res = response.data
						$this.setState({
							invoice_upload_status: 1
						})
						
						$this.fileInput.value = "";
						transactionUpdateInvoiceUrl(res.file)
						$this.setState({
							invoice_upload_progress_message: '',
						})
						// NProgress.done()
					} else {
						throw response
					}

				})
				.catch((error) => {
					$this.fileInput.value = "";
					if (error.response != undefined) {
						let res = error.response.data

						$this.setState({
							invoice_upload_status: 2
						})
						$this.setState({
							invoice_upload_error_message:res.message,
							invoice_upload_progress_message:''
						})
					} else {

						if (error == 'canceled') {} else {
							$this.setState({
								invoice_upload_status: 2,
								invoice_upload_error_message:'Check your connection and upload again',
								invoice_upload_progress_message:''
							})
						}
					}
					// NProgress.done()
				})
			}
		}

	}

	handleUploadEfakturClick(){
		const input = document.getElementById('efaktur_file');
		input.click();
	}

	handleUploadEfakturInput(e) {
		const {
			transactionId,
			transactionUpdateEfakturUrl
		} = this.props

		var $this = this
		let file = e.target.files
		$this.setState({
			efaktur_upload_error_message:'',
			efaktur_upload_progress_message:''
		})
		
		if (documentFileType.includes(file[0].type)) {
			const formData = new FormData();
			if(file[0].size>10000000){
				$this.setState({
					efaktur_is_error_upload: true,
					efaktur_upload_status: 2
				})
				
				$this.setState({efaktur_upload_error_message:'The file may not be greater than 10 Mb.'})
			}else{
				$this.setState({
					efaktur_is_error_upload: false,
					efaktur_progress_upload: 0,
					efaktur_upload_status: 0,
					efaktur_temp_file: file[0].name
				})

				formData.append('file', file[0])

				// ajaxCancel = axios.CancelToken.source()
				axios({
					url: `/support-dashboard/transactions/${transactionId}/efaktur/upload`,
					method: "post",
					timeout: 500000,
					data: formData,
					onUploadProgress: progressEvent => {
						const uploadAudioPercentage = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						let progressSize= (file[0].size * uploadAudioPercentage)/100
						$this.setState({
							efaktur_progress_upload: uploadAudioPercentage,
							efaktur_upload_progress_message: formatBytes(progressSize) +' of '+ formatBytes(file[0].size)
						})
					},
				})
				.then((response) => {
					if (response.data.status <= 200) {
						let res = response.data
						
						$this.setState({
							efaktur_upload_status: 1
						})

						$this.fileInput.value = "";
						transactionUpdateEfakturUrl(res.file)
						$this.setState({
							efaktur_upload_progress_message: ''
						})
						// NProgress.done()
					} else {
						throw response
					}

				})
				.catch((error) => {
					$this.fileInput.value = "";
					if (error.response != undefined) {
						let res = error.response.data

						$this.setState({
							efaktur_upload_status: 2
						})
						$this.setState({
							efaktur_upload_error_message:res.message,
							efaktur_upload_progress_message:''
						})
					} else {

						if (error == 'canceled') {} else {
							$this.setState({
								efaktur_upload_status: 2,
								efaktur_upload_error_message:'Check your connection and upload again',
								efaktur_upload_progress_message:''
							})
						}
					}
					// NProgress.done()
				})
			}
		}

	}

  	render() {
		const {
            transaction,
			globalIndicatorData,
			userPrivilege
		} = this.props
		
		let userTransaction = transaction.toJS();
		let company = userTransaction.company  || {}
		let user = userTransaction.user || {}
		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		
    	return (

			<div className={isIndicatorClass}>
	      		<div className="div-support-container" style={{"backgroundColor": "transparent"}}>
	        		<section className="wrapper">
	          			<div className="main-content" style={{"backgroundColor": "transparent"}}>
							<div className="row">
								<div className="col-md-12">
									<div className="flex-container flex-between">
										<h5 className="title-heading" style={{marginBottom: '20px'}}>{transaction.get('invoice_number')}</h5>
									</div>
								</div>
							</div>
							
							<div className="row">
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-12">
											<div className="big-panel">
												<a onClick={this.handleBackPage}><i className="fa fa-arrow-left"></i> Back</a>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="big-panel">
												<table className="table-transaction m-0">
													<tbody>
														<tr>
															<td ><b className="uppercase">Payment</b></td>
															<td></td>
														</tr>
														<tr>
															<td><h1 className="title-heading m-0"><b>IDR {transaction.get('total_price')},-</b></h1></td>
														</tr>
														<tr>
															<td >{transaction.get('candidate_credit')} candidate credits</td>
															<td align="right" valign="top" rowSpan="2" width="200">
																<div className="pull-right">
																	<select
																		className="select-status-payment m-r-10"
																		name="status"
																		id="status"
																		value={transaction.get('status')}
																		disabled={!this.props.userPrivilege.includes('EDIT_TRANSACTION_STATUS')}
																		onChange={(e)=>this.handleUpdatePaymentStatus(e)}>
																		<option value="1" >Fully Paid</option>
																		<option value="2" >Outstanding</option>
																	</select>
																</div>
															</td>
														</tr>
														<tr>
															<td>{moment(transaction.get('transaction_date')).format('DD/MM/YYYY')}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className="col-md-6">
											<div className="big-panel">
												<table className="table-transaction m-0">
													<tbody>
													<tr>
														<td ><b className="uppercase">Company</b></td>
													</tr>
													<tr>
														<td><b><u>{company.title}</u></b></td>
													</tr>
													<tr>
														<td>Contact:</td>
														<td></td>
													</tr>
													<tr>
														<td>{user.name}</td>
													</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="big-panel">
												<h3>Document</h3>
												<ul className="nav nav-tabs" id="myTab" role="tablist">
													<li className="nav-item active">
														<a className="nav-link nav-link-document" id="invoice-tab" data-toggle="tab" href="#invoice" role="tab" aria-controls="invoice" aria-selected="true" aria-expanded="true">INVOICE</a>
													</li>
													<li className="nav-item">
														<a className="nav-link nav-link-document" id="invoice-tab" data-toggle="tab" href="#efaktur" role="tab" aria-controls="efaktur" aria-selected="false" aria-expanded="false">E-FAKTUR</a>
													</li>
												</ul>
												<div className="tab-content" id="myTabContent">
													<div className="tab-pane fade active in" id="invoice" role="tabpanel" aria-labelledby="home-tab">
														{
															userPrivilege.includes('UPLOAD_INVOICE') &&
															<div className="vertical-item--center" style={{marginTop:"20px"}}>
																<button type="button" onClick={this.handleUploadInvoiceClick} className="btn btn-default">
																	Upload File
																</button>
																<span> &nbsp; {this.state.invoice_temp_file}</span>
																<input type="file"
																	ref={ref=> this.fileInput = ref}
																	accept=".pdf"
																	id="invoice_file" onChange={this.handleUploadInvoiceInput} name="invoice_file"
																	className='hidden'/>
															</div>
														}
														
														<div style={{marginTop:"20px"}}>
															{
																transaction.get('invoice_file_url') != '' ? 
																<div dangerouslySetInnerHTML={{__html: `<object
																	type="application/pdf"
																	data="`+transaction.get('invoice_file_url')+`#toolbar=0&navpanes=0&scrollbar=0"
																	width="100%"
																	height="500"
																	>
																</object>`}} /> 
																:
																<div className="empty-cv">
																	<div className="placehold flex-container flex-center align-items-center">
																		<div>
																			<h4>No Data.</h4>
																			<p>Please Upload Document</p>
																		</div>
																	</div>
																</div>
															}
														</div>
													</div>
													<div className="tab-pane fade" id="efaktur" role="tabpanel" aria-labelledby="profile-tab">
														{
															userPrivilege.includes('UPLOAD_EFAKTUR') &&
															<div className="vertical-item--center" style={{marginTop:"20px"}}>
																<button type="button" onClick={this.handleUploadEfakturClick} className="btn btn-default">
																	Upload File
																</button>
																<span> &nbsp; {this.state.efaktur_temp_file}</span>
																<input type="file"
																	ref={ref=> this.fileInput = ref}
																	accept=".pdf"
																	id="efaktur_file" onChange={this.handleUploadEfakturInput} name="efaktur_file"
																	className='hidden'/>
															</div>
														}
														
														<div style={{marginTop:"20px"}}>
															{
																transaction.get('efaktur_file_url') != '' ? 
																<div dangerouslySetInnerHTML={{__html: `<object
																	type="application/pdf"
																	data="`+transaction.get('efaktur_file_url')+`#toolbar=0&navpanes=0&scrollbar=0"
																	width="100%"
																	height="500"
																	>
																</object>`}} /> 
																:
																<div className="empty-cv">
																	<div className="placehold flex-container flex-center align-items-center">
																		<div>
																			<h4>No Data.</h4>
																			<p>Please Upload Document</p>
																		</div>
																	</div>
																</div>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="big-panel">
										<div className="row">
											<div className="col-md-12 transaction-documents">
												<h4 className="heading4 bold m-b-30">TRANSACTION DETAILS</h4>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 transaction-documents">
												<table className="table-transaction">
													<tbody>
													<tr>
														<td colSpan="1"><b>Date</b></td>
														<td>{moment(transaction.get('transaction_date')).format('DD/MM/YYYY')}</td>
													</tr>
													<tr>
														<td><b>Number</b></td>
														<td>{transaction.get('invoice_number')}</td>
													</tr>
													<tr>
														<td><b>Ordered by</b></td>
														<td>{user.name}</td>
													</tr>
													<tr>
														<td><b>Company</b></td>
														<td>{company.name}</td>
													</tr>
													<tr>
														<td><b>Address</b></td>
														<td></td>
													</tr>
													<tr>
														<td><b>NPWP</b></td>
														<td>N/A</td>
													</tr>
													<tr>
														<td><b>Non-PKP Doc</b></td>
														<td>N/A</td>
													</tr>
													<tr>
														<td >&nbsp;</td>
														<td>&nbsp;</td>
													</tr>
													<tr>
														<td ><b>Charges</b></td>
														<td></td>
													</tr>
													<tr>
														<td >Candidate Credit x {transaction.get('candidate_credit')}</td>
														<td className="center">IDR {transaction.get('total_price')},-</td>
													</tr>
													<tr>
														<td>Tax</td>
														<td className="center">IDR 0,-</td>
													</tr>
													<tr>
														<td><b>Total</b></td>
														<td className="center">IDR {transaction.get('total_price')},-</td>
													</tr>
													<tr>
														<td >&nbsp;</td>
														<td>&nbsp;</td>
													</tr>
													<tr>
														<td ><b>Payment</b></td>
														<td></td>
													</tr>
													<tr>
														<td>Paid with</td>
														<td>IDR {transaction.get('total_price')},-</td>
													</tr>
													<tr>
														<td >&nbsp;</td>
														<td>&nbsp;</td>
													</tr>
													<tr>
														<td>{moment(transaction.get('transaction_date')).format('MMMM DD, YYYY')}</td>
														<td></td>
													</tr>
													<tr>
														<td><b>Total Paid</b></td>
														<td><b>IDR {transaction.get('total_price')},-</b></td>
													</tr>
													</tbody>
												</table>
												
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		pathName: ownProps.location.pathname,
		globalIndicatorData: state.globalIndicatorData,
		transaction: state.transaction,
        transactionId: ownProps.match.params.transactionId,
		userPrivilege: state.userPrivilege
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		transactionGetDetail: (filter_data) => dispatch(transactionGetDetail(filter_data)),
		transactionsUpdatePaymentStatus: (transactionId, status) => dispatch(transactionsUpdatePaymentStatus(transactionId, status)),
		transactionUpdateInvoiceUrl: (invoice_file_url) => dispatch(transactionUpdateInvoiceUrl(invoice_file_url)),
		transactionUpdateEfakturUrl: (efaktur_file_url) => dispatch(transactionUpdateEfakturUrl(efaktur_file_url)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetail)
