import isEmpty from 'lodash/isEmpty'
import configurations from '../../misc/configurations'

const validate = values => {
	const errors = {}

	if (!values.email) {
		errors.email = `Email can't be blank`
	} else {
		if (!configurations.emailRegex.test(values.email)) {
			errors.email = `Invalid email`
		}
	}

	if (!values.name) {
		errors.name = `Name can't be blank`
	}else{
		if(values.name.length < 3){
			errors.name = `Name cannot less than 3 characters`
		}
	}

	if (!values.company) {
		errors.company = `Company Name can't be blank`
	} else{
		if(values.company.length > 65){
			errors.company = `Company Name cannot more than 65 characters`
		}
	}


	if (!values.mobile_number) {
		errors.mobile_number = `Mobile Number can't be blank`
	} else{
		if(values.mobile_number.length > 13){
			errors.mobile_number = `Mobile Number cannot more than 13 characters`
		}

		if(values.mobile_number.length < 10){
			errors.mobile_number = `Mobile Number cannot less than 10 characters`
		}
	}

	if (!values.user_level) {
		errors.user_level = `Please select user level`
	}

	if (!values.channel) {
		errors.channel = `Please select Channel`
	}

	return errors
}

export default validate

