// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map } from 'immutable'

import {
	approveRejectPublishRequest
} from '../../actions/job'

type Props = {
	job: Map<string, any>,
	onUpdated: Function
}

class ModalPreviewJob extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			isSuccess: false
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleApprove'
		])
	}

	handleCloseModalClick(e: Object) {
		e.preventDefault()
		$('#modalPreviewJob').modal('hide')
	}

	handleApprove(e, isApprove){
		const {approveRejectPublishRequest} = this.props
		approveRejectPublishRequest(this.props.job.publish_job_id, isApprove).then(()=>{
			this.props.onUpdated()
			$('#modalPreviewJob').modal('hide')
		})
	}

  	render() {
  		const {
			job
		} = this.props

		let statusLabels = {
			null:'Need Review',
			0:'Need Review',
			1:'Published',
			2:'Rejected',
			3:'Canceled'
		}

		let colorChannel='tag-blue'
		if(job.status == 0 || job.status==null){
			colorChannel='tag-yellow'
		}else if(job.status == 1){
			colorChannel='tag-green'
		} else if(job.status == 2){
			colorChannel='tag-pink'
		} else if(job.status == 3){
			colorChannel='tag-pink'
		} else {
			colorChannel='tag-green'
		}

		let previewUrl = `https://dev.popskul.id/jobs/${job.id}/job/preview`

		return (
			<div className="modal custom-modal" id="modalPreviewJob" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
			  	<div className="modal-dialog modal-preview_job">
					<div className="modal-content animated bounceInRight text-center">
						<div className="modal-header">
							<div className={`pull-left`}>
								 <a><h2 onClick={this.handleCloseModalClick}><i className="fa fa-angle-left"></i> &nbsp; Job Details</h2></a>
							</div>
							<div className={`tag-channel pull-left m-t-15 m-l-30 ${colorChannel}`}>
								<span>{statusLabels[job.status]}</span>
							</div>
							<div className={`m-t-15 pull-right ${job.status==0 ? '':'hide'}`}>
								<button className="button button__secondary m-r-30" onClick={(e)=>this.handleApprove(e, 2)}>Reject</button>
								<button className="button button__primary m-r-15" onClick={(e)=>this.handleApprove(e,1)}>Approve</button>
							</div>
							<div className={`m-t-15 pull-right ${job.status==1 ? '':'hide'}`}>
								<button className="button button__secondary m-r-30" onClick={(e)=>this.handleApprove(e, 2)}>Change to Rejected</button>
							</div>
							<div className={`m-t-15 pull-right ${job.status==2 ? '':'hide'}`}>
								<button className="button button__secondary m-r-15" onClick={(e)=>this.handleApprove(e,1)}>Change to Published</button>
							</div>
						</div>
						<div className="modal-body">
							<iframe id="frame_view" name="frame_view" src={previewUrl}></iframe>
						</div>
					</div>
			  	</div>
			</div>
	  	)
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		approveRejectPublishRequest: (publish_id, isApprove) => dispatch(approveRejectPublishRequest(publish_id, isApprove))
	}
}

export default connect(null, mapDispatchToProps)(ModalPreviewJob)
