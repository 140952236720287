// @flow

import { put, select, call, all } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { Map, List } from 'immutable'

import {
	change,
	startSubmit,
	stopAsyncValidation,
	startAsyncValidation
} from 'redux-form'

import {
	transactionsSaveDataApi,
	transactionsSaveCreditCardDataApi,
	transactionsSaveBankTransferDataApi
} from '../apis/transactions'

import {
	getForm,
	getCountries,
	getPaymentPackages
} from '../selectors'

export function* transactionsSaveData({companyId}: Object): Generator<*, *, *> {
	try {
		const form: Object = yield select(getForm)
		const values: Object = form.PaymentBillingInformationsForm.values
		const response: Object = yield call(
			transactionsSaveDataApi,
			companyId,
			values
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'TRANSACTIONS_GET_DETAIL_SUCCESS',
				transaction: values
			})

			yield put(push(`/companies/${companyId}/top-up/payment-methods/bank-transfer`))
		} else {
			throw response
		}
	} catch (error) {
		
	}
}

export function* transactionsSaveCreditCardData({companyId}: Object): Generator<*, *, *> {
	try {
		const form: Object = yield select(getForm)
		const response: Object = yield call(
			transactionsSaveCreditCardDataApi,
			companyId,
			form.PaymentCreditCardForm.values
		)

		if (response.status >= 200 && response.status < 300) {
			yield put(push(`/companies/${companyId}/top-up/payment-methods/credit-card`))
		} else {
			throw response
		}
	} catch (error) {
		
	}
}

export function* transactionsSaveBankTransferData({companyId}: Object): Generator<*, *, *> {
	try {
		yield put(startSubmit('PaymentBankTransferForm'))
		yield put(startAsyncValidation('PaymentBankTransferForm'))

		const form: Object = yield select(getForm)
		const response: Object = yield call(
			transactionsSaveBankTransferDataApi,
			companyId,
			form.PaymentBankTransferForm.values
		)

		if (response.status >= 200 && response.status < 300) {
			yield all([
				put({
					type: 'TRANSACTIONS_UPDATE_ID_SUCCESS',
					id: response.data.transaction_id
				}),
				put(push(`/companies/${companyId}/top-up/payment-outstanding`))
			])
		} else {
			throw response
		}
	} catch (error) {
		if (error.response.status === 422) {
			yield put(stopAsyncValidation('PaymentBankTransferForm', error.response.data.message))
		}
	}
}

export function* transactionsBuyPaymentPackage({companyId, packageId}: Object): Generator<*, *, *> {
	try {
		const countries: List<Map<string, any>> = yield select(getCountries)
		const country: Map<string, any> = countries
			.filter((country) => country.get('label').toLowerCase() === 'singapore')
			.first()

		yield all([
			put({
				type: 'TRANSACTIONS_UPDATE_PACKAGE_ID_SUCCESS',
				package_id: packageId
			}),
			put({
				type: 'TRANSACTIONS_UPDATE_COUNTRY_ID_SUCCESS',
				country_id: country.get('id')
			}),
			put({
				type: 'TRANSACTIONS_UPDATE_EXCHANGE_CODE_SUCCESS',
				exchange_code: country.get('exchange_code')
			})
		])

		yield put(push(`/companies/${companyId}/top-up/billing-informations`))
	} catch (error) {
	}
}
