// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from  'react-router'
import { Link } from  'react-router-dom'
import bindAll from 'lodash/bindAll'
import { Map, List } from 'immutable'
import $ from 'jquery'
import NotificationSystem from 'react-notification-system';
import Notifications from 'react-notification-system-redux'

require('es6-promise').polyfill()

type Props = {
};

class SplashMessage extends React.Component<Props> {
	constructor(props: Object) {
		super(props)
		// this.notificationSystem = React.createRef();
		bindAll(this, [
		])
	}


	componentDidUpdate(prevProps){
		const {
			notifications
		} = this.props

		let prevNotifications = prevProps.notifications
		if(notifications.length>0){
			if(prevNotifications.length > 0){
				if(notifications[notifications.length-1].uid != prevNotifications[prevNotifications.length-1].uid ){
					if($('#'+notifications[notifications.length-1].uid).length > 0){
						$('#splashMessage').addClass('show-splash')
						setTimeout(() => { 
							$('#splashMessage').removeClass('show-splash'); 
						}, 5000);
					}
				}
			}else{
				if($('#'+notifications[notifications.length-1].uid).length > 0){
					$('#splashMessage').addClass('show-splash')
					setTimeout(() => { 
						$('#splashMessage').removeClass('show-splash'); 
					}, 5000);
				}
			}			
		}
	}

	render() {
		const {
			notifications
		} = this.props
		
		let icon={
			'success'	:'fa fa-check-circle-o',
			'info'		:'fa fa-info-circle',
			'error'		:'fa fa-times-circle',
			'warning'		:'fa fa-exclamation-circle',
		}
		if(notifications.length>0){
			return(
				<div className="splash-container" id={`${notifications[notifications.length-1].uid}`}>
					<div id="splashMessage" className={`${notifications[notifications.length-1].level}`}>
						<i className={`${icon[notifications[notifications.length-1].level]}`} />{notifications[notifications.length-1].message}
					</div>
				</div>
			)
			
		}else{
			return null
		}
	}
}

export default SplashMessage
