// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import SweetAlert from 'sweetalert-react'
import Notifications from 'react-notification-system-redux'
import {
	getUrlParams,
} from '../../helpers/global'

import { setSessionsToken, userGetAuth, setToken, saveToken, setIsAuthorize } from '../../actions/sessions'
import HeaderSupportDashboard from '../../components/partials/HeaderSupportDashboard'
import SplashMessage from '../../components/partials/SplashMessage'

import 'sweetalert/dist/sweetalert.css'
import { isAuthorize } from '../../reducers/sessions'
import PlanRequestNotificationBaner from '../partials/PlanRequestNotificationBaner'

require('es6-promise').polyfill()

type Props = {
	children: Object,
	globalSweetAlertData: Object,
	notifications: Array<Object>,
	userGetAuth: Function,
	setSessionsToken: Function,
	setToken: Function,
	setIsAuthorize: Function
};

type State = {
	displayMainLayout: boolean
};

class SupportDashboardIndex extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			displayMainLayout: false
		}
	}

	componentWillMount(){
		const {
			setToken,
			userGetAuth,
			setIsAuthorize
		} = this.props
		
		setIsAuthorize(false)
		let token = getUrlParams('_token');
		if(token && token !== '') {
			saveToken(token)
			.then(()=>{
				userGetAuth()
			})
		}else{
			userGetAuth()
		}
	}


	render() {
		const {
			children,
			notifications,
			globalSweetAlertData,
			isAuthorize,
			information
		} = this.props

		let showMessages = true
		if(this.props.location.pathname.includes('popskul')){
			showMessages = false
		}

		if(['/support/custom-plan','/support/popskul-request'].includes(this.props.location.pathname)){
			showMessages = false
		}
		
		return(
			
	    	<Fragment>
				<HeaderSupportDashboard/>

				<SplashMessage notifications={notifications}/>
				{ this.props.information.get('plan_request') > 0 && showMessages  &&
					<PlanRequestNotificationBaner information={information}/>
				}
				{
					this.props.isAuthorize===true &&
					children
				}
				{/* <Notifications notifications={notifications} /> */}
				<SweetAlert {...globalSweetAlertData.toJS()} />
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		userAuth: state.userAuth,
		notifications: state.notifications,
		globalSweetAlertData: state.globalSweetAlertData,
		isAuthorize: state.isAuthorize,
		information: state.information
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userGetAuth: () => dispatch(userGetAuth()),
		setSessionsToken: (token) => dispatch(setSessionsToken(token)),
		setToken: (token) => dispatch(setToken(token)),
		setIsAuthorize: (isAuthorize) => dispatch(setIsAuthorize(isAuthorize))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportDashboardIndex))
