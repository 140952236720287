// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import capitalize from 'voca/capitalize'
import Promise from 'bluebird'
import bindAll from 'lodash/bindAll'
import io from 'socket.io-client'
import { List, Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import imgMoneyCoinSort from '../../assets/img/money-coin-sort.svg'
import imgMoneyCoin from '../../assets/img/money-coin.svg'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import validate from './validate'
import RenderInput from '../partials/RenderInput'
import RenderTextArea from '../partials/RenderTextArea'
import moment from 'moment'
import configurations from '../../misc/configurations'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import {
	companiesGetDetail,
	companiesGetCredit,
	companiesTopup
} from '../../actions/companies'

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	companiesGetDetail: Function,
	companiesGetCredit: Function,
	companiesId: string,
	companiesTopup: Function,
};

type State = {
	creditsTemp: number,
	totalCredits: number,
	expiryDateTemp: ?Object
};

class CompanyUpdateCredit extends React.Component<Props, State> {
	state: State;

	constructor(props) {
		super(props)

		this.state = {
			creditsTemp: 0,
			expiryDateTemp: '',
			totalCredits: 0
		}

		bindAll(this, [
			'handleTopUpClick',
			'handleCreditClick',
			'handleCreditChange',
			'handleExpiryDateChange',
			'handleResetCredit',
			'handleResetCreditClick'
		])
	}

	componentDidMount() {
		const {
			companiesGetDetail,
            companiesId,
			company
		} = this.props

		let detailcompany = company.toJS();

		let prevState = this.props.location.state
		let expiredExtendMinimum = {'days': 0}
		console.log('prev', prevState);
		if(prevState !== undefined){
			if(prevState.companyLevel){
				if(prevState.companyLevel!= detailcompany.company_level){
					if([8,11].includes(prevState.companyLevel)) {
						expiredExtendMinimum = {'months': 1}
						this.setState({expiryDateTemp:moment().add(expiredExtendMinimum)})
					} else if([2,4].includes(prevState.companyLevel)) {
						expiredExtendMinimum = {'years': 1, 'days': -1}
						this.setState({expiryDateTemp:moment().add(expiredExtendMinimum)})
					}
				}
			}
				
		}

		companiesGetDetail(companiesId).then(()=>{
			this.setState({
				totalCredits: parseInt(this.props.company.get('credit').credits_left, 10) || 0
			})
		})
		
	}

	removeUniqueAndAlphabetCharacters(str: string): string {
		return str.replace(configurations.exceptNumberRegex, '')
	}

	handleCreditChange(e) {
		e.preventDefault()

		const creditsTemp: number = this.removeUniqueAndAlphabetCharacters(e.target.value)
		this.setState({
			creditsTemp,
			totalCredits: parseInt(this.props.company.get('credit').credits_left, 10) + parseInt(creditsTemp, 10) || 0
		})
	}

	handleCreditClick(e, isIncrease = true) {
		e.preventDefault()

		const { creditsTemp } = this.state

		let _creditsTemp: number = creditsTemp ? creditsTemp : 0

		if (isIncrease) {
			_creditsTemp = parseInt(_creditsTemp, 10) + 1
		} else {
			_creditsTemp = parseInt(_creditsTemp, 10) - 1
		}

		// if(_creditsTemp <= 0){
		// 	_creditsTemp = 0
		// }

		this.setState({
			creditsTemp: _creditsTemp,
			totalCredits: parseInt(this.props.company.get('credit').credits_left, 10) + parseInt(_creditsTemp, 10) || 0
		})
	}

	handleExpiryDateChange(expiryDate) {
		this.setState({
			expiryDateTemp: expiryDate
		})
	}

	handleTopUpClick(e) {
		e.preventDefault()

		const {
			companiesTopup,
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesId,
			companiesGetDetail,
			company
		} = this.props

		const {
			expiryDateTemp,
			creditsTemp
		} = this.state

		let prevState = this.props.location.state
		let companyLevel = ''
		if(prevState !== undefined){
			if(prevState.companyLevel){
				companyLevel = prevState.companyLevel
			}
		}

		let newExpiredDate = expiryDateTemp
		if(newExpiredDate === ''){
			newExpiredDate = company.get('valid_date')
		}

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Continue',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Apply Credit Adjustment`,
			html: true,
			text: "Are you sure to update/topup credit for this company?",
			onConfirm: () => {
				companiesTopup(
					companiesId,
					{
						expiry_date: newExpiredDate,
						candidate_credit: creditsTemp,
						level:companyLevel
					}
				)
				.then(()=>{
					companiesGetDetail(companiesId).then(()=>{
						this.setState({
							creditsTemp: 0,
							expiryDateTemp: '',
							totalCredits: parseInt(this.props.company.get('credit').credits_left, 10) || 0
						})
						this.handleResetCredit()
						globalHideSweetAlert()
					})
				})
				
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleResetCredit() {
		const creditsTemp: number = 0
		this.setState({
			creditsTemp,
			expiryDateTemp:'',
			totalCredits: parseInt(this.props.company.get('credit').credits_left, 10) + parseInt(creditsTemp, 10) || 0
		})
	}

	handleResetCreditClick(e){
		e.preventDefault()

		const {
			globalAddSweetAlertData,
			globalHideSweetAlert
		} = this.props


		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Reset',
			cancelButtonText: 'Cancel',
			showLoaderOnConfirm: true,
			title: `Reset Credit Simulation?`,
			html: true,
			text: "After you reset the simulation, the credit will be restored to its initial value and the credit adjustment becomes zero.",
			onConfirm: () => {
				this.handleResetCredit()
				globalHideSweetAlert()
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

  	render() {
		const {
			company,
			credit,
			companiesId
		} = this.props
		const {
			creditsTemp,
			totalCredits,
			expiryDateTemp
		} = this.state
		let detailcompany = company.toJS();
		let companyCredit = detailcompany.credit || {}
		let disableAction = true
		let disableApply = true
		let disableDatePicker= false
		if(creditsTemp != 0 && expiryDateTemp!=''){
			disableApply = false
		}

		let prevState = this.props.location.state
		let backPath = `/support/companies/${companiesId}`
		let isUpgrade = false;
		let expiredExtendMinimum = {'days': 0}

		// month
		if([8,11].includes(detailcompany.company_level)) {
			expiredExtendMinimum = {'months': 1}
		} else if([2,4].includes(detailcompany.company_level)) {
			// year
			expiredExtendMinimum = {'years': 1, 'days': -1}
		}

		if(prevState !== undefined){
			if(prevState.companyLevel){
				if(prevState.companyLevel!= detailcompany.company_level){
					isUpgrade=true
				}
				// month
				if([8,11].includes(prevState.companyLevel)) {
					expiredExtendMinimum = {'months': 1}
					disableDatePicker=true
				} else if([2,4].includes(prevState.companyLevel)) {
					// year
					expiredExtendMinimum = {'years': 1, 'days': -1}
					disableDatePicker=true
				}
			}
		}

		if(![1,2,8,9].includes(parseInt(detailcompany.company_level)) && !isUpgrade){
			disableAction = true
		}

		if(prevState !== undefined){
			if(prevState.prevPage === 'company-detail'){
				prevState = 'company-update-credit'
			}else if (prevState.prevPage === 'custom-plan'){
				prevState = 'custom-plan'
				backPath='/support/custom-plan'	
			}
		}

		if(isUpgrade){
			disableApply = false
		}else{
			if(creditsTemp != 0 || expiryDateTemp!=''){
				disableApply = false
			}
		}
		
		if(creditsTemp != 0){
			disableAction = false
		}


		return (
			<div className="div-support-container">
				<section className="wrapper">
				<div className="main-content">
					<div className="container-fluid">
					<div className="big-panel">
						<div className="flex-container">
							<Link to={{pathname : backPath, state:{prevPage:prevState}}} className="btn-support"><i className="fa fa-angle-left" style={{fontSize: '18px'}}></i> Back to main page</Link>
							{ 
								prevState === 'custom-plan' && detailcompany.level &&
								<div className='badge-upgrade-plan-request'>Upgrade to Enterprise Plan</div>
							}
							
						</div>
						<div className="flex-container m-t-40">
							<h5 className="title-heading m-b-0">Update Credit</h5>
						</div>
						<div className="flex-container flex-between m-t-20">
							<div className="col-md-12">
								<div className="company-update-credit-card edit-card">
									<div>
										<label className="sub-heading">{detailcompany.title}</label>
										<p className="sub-title">Candidate Credits</p>
									</div>
									<div className="div-credit-container horizontal-item--between">
										<div className="credit-container">
											<div className="horizontal-item--between simulation-container">
												<div className="coin-div"><img src={imgMoneyCoinSort} /></div>
												<div className="credit-div">
													<label>Credit Simulation</label>
													<br />
													<label>{totalCredits}</label>
												</div>
											</div>
											<hr className="hr-credit-purple" />
											<div className="horizontal-item--between m-t-16 action-container simulation">
												<div><label>Credit Adjustment</label></div>
												<div className="horizontal-item--between">
													<button className={`horizontal-item--center`}  onClick={(e) => this.handleCreditClick(e, false)}><i className="fa fa-minus" /></button>
													<input type="number" placeholder="0" value={creditsTemp} onChange={this.handleCreditChange} ></input>
													<button className="horizontal-item--center" onClick={(e) => this.handleCreditClick(e)}><i className="fa fa-plus" /></button>
												</div>
											</div>
										</div>
										<div className="credit-container">
											<div className="horizontal-item--between simulation-container">
												<div className="coin-div"><img src={imgMoneyCoin} /></div>
												<div className="credit-div">
													<label>Current Credits</label>
													<br />
													<label>{companyCredit.credits_left}</label>
												</div>
											</div>
											<hr className="hr-credit-purple" />
											<div className="horizontal-item--between m-t-16 action-container current">
												<div><label>New Expiry Date</label></div>
												<div className="datepick-container horizontal-item--between">
													<DatePicker
														dateFormat="DD/MM/YYYY"
														placeholderText="DD/MM/YYYY"
														// minDate={companyCredit.credits_expired_date ? moment(companyCredit.credits_expired_date).add(1, expiredExtendMinimum) : moment().add(1, expiredExtendMinimum)}
														minDate={moment().add(expiredExtendMinimum)}
														selected={expiryDateTemp!='' ? moment(expiryDateTemp) : ''}
														onChange={this.handleExpiryDateChange}
														onKeyDown= {(e)=>{e.preventDefault()}}
														disabled={disableDatePicker}
													/>
													<i className="fa fa-calendar" />
												</div>
											</div>
										</div>
									</div>
									<div className="m-t-40 text-right">
										<button disabled={disableAction} className="btn-support-action secondary-border long m-r-12" onClick={this.handleResetCreditClick}>Reset Credit Simulation</button>
										<button disabled={disableApply} className="btn-support-action primary m-r-12" onClick={this.handleTopUpClick}>Apply Credit Adjustment</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
				</section>
			</div>
		)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		companiesId: ownProps.match.params.companiesId,
		company: state.company,
		credit: state.credit,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		companiesGetDetail: (filter_data) => dispatch(companiesGetDetail(filter_data)),
		companiesGetCredit: (filter_data) => dispatch(companiesGetCredit(filter_data)),
		companiesTopup: (companyId, params) => dispatch(companiesTopup(companyId, params)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUpdateCredit)
